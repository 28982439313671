.h-100per {
  height: 100%;
}
.max-h-80 {
  max-height: 80px;
}
.h-80 {
  height: 80px;
}
.h-8 {
  height: 8px;
}
.min-h-80 {
  min-height: 80px;
}
.h-700 {
  height: 700px;
}
.h-90per {
  height: 90%;
}
.h-152 {
  height: 152px;
}
.h-162 {
  height: 162px;
}
.h-700 {
  height: 700px;
}
.h-22 {
  height: 22px;
}
.h-75per {
  height: 75%;
}
.h-20 {
  height: 20px;
}
.min-h-160 {
  min-height: 160px;
}
.h-24 {
  height: 24px;
}
.h-82 {
  height: 82px;
}
.h-18 {
  height: 18px;
}
.h-772 {
  height: 772px;
}
.h-279 {
  height: 279px;
}
.h-314 {
  height: 314px;
}
.h-180 {
  height: 180px;
}
.h-130 {
  height: 130px;
}
.h-165 {
  height: 165px;
}

.h-140 {
  height: 140px;
}

.h-31 {
  height: 31px;
}

.h-40 {
  height: 40px;
}

.h-48 {
  height: 48px;
}
.h-70 {
  height: 70px;
}
.h-72 {
  height: 72px;
}
.h-302 {
  height: 302px;
}
.h-335 {
  height: 335px;
}
.h-32 {
  height: 32px;
}
.h-371 {
  height: 371px;
}
.h-564 {
  height: 564px;
}
.h-388 {
  height: 388px;
}
.h-auto {
  height: auto;
}
.max-h-90 {
  max-height: 90px;
}
.max-h-524 {
  max-height: 524px;
}
.h-185 {
  height: 185px;
}
.h-50 {
  height: 50px;
}

.h-56 {
  height: 56px;
}

.h-60 {
  height: 60px;
}
.h-304 {
  height: 304px;
}
.max-h-304 {
  height: 304px;
}
.h-540 {
  height: 540px;
}
.h-85 {
  height: 85px;
}
.h-345 {
  height: 345px;
}

.h-100 {
  height: 100px;
}
.h-10 {
  height: 10px;
}
.h-106 {
  height: 106px;
}
.h-110 {
  height: 110px;
}
.h-116 {
  height: 116px;
}
.h-120 {
  height: 120px;
}
.h-166 {
  height: 166px;
}
.h-246 {
  height: 246px;
}
.h-1080 {
  height: 1080px;
}
.h-164 {
  height: 164px;
}
.h-100vh {
  height: 100vh;
}
.h-38 {
  height: 38px;
}
.min-h-106 {
  min-height: 106px;
}

.min-h-267 {
  min-height: 267px;
}
@media (min-width: 768px) {
  .tablet-h-448 {
    height: 448px;
  }
}
@media (min-width: 1024px) {
  .browser-h-100per {
    height: 100%;
  }
  .w-1024-h-953 {
    height: 953px;
  }
  .w-1024-h-404 {
    height: 404px;
  }
  .browser-h-433 {
    height: 433px;
  }
  .browser-h-8 {
    height: 8px;
  }
  .w-1024-h-165 {
    height: 165px;
  }
  .browser-h-22 {
    height: 22px;
  }

  .browser-h-24 {
    height: 24px;
  }

  .browser-h-130 {
    height: 130px;
  }

  .browser-h-140 {
    height: 140px;
  }
  .browser-h-290 {
    height: 290px;
  }
  .browser-h-420 {
    height: 420px;
  }

  .browser-min-h-468 {
    min-height: 468px;
  }

  .browser-min-h-500 {
    min-height: 500px;
  }

  .browser-min-h-600 {
    min-height: 600px;
  }
  .browser-h-448 {
    height: 448px;
  }
  .browser-h-212 {
    height: 212px;
  }
  .browser-h-50per {
    height: 50%;
  }
  .browser-min-h-650 {
    min-height: 650px;
  }

  .browser-h-50 {
    height: 50px;
  }

  .browser-h-56 {
    height: 56px;
  }
  .browser-h-350 {
    height: 350px;
  }

  .browser-h-60 {
    height: 60px;
  }
  .browser-h-955 {
    height: 955px;
  }

  .browser-h-100 {
    height: 100px;
  }

  .browser-h-100vh {
    height: 100vh;
  }
  .browser-h-1080 {
    height: 1080px;
  }
  .browser-h-460 {
    height: 460px;
  }
}
@media (min-width: 1920px) {
  .browser-h-433 {
    height: 433px;
  }
}
