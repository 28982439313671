@font-face {
  font-family: 'NanumSquareNeo-Light';
  font-weight: '350';
  src: url('../../../asset/fonts/NanumSquareNeoOTF-Lt.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Regular';
  font-weight: '400';
  src: url('../../../asset/fonts/NanumSquareNeoOTF-Rg.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Bold';
  font-weight: '700';
  src: url('../../../asset/fonts/NanumSquareNeoOTF-Bd.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-ExtraBold';
  font-weight: '800';
  src: url('../../../asset/fonts/NanumSquareNeoOTF-Eb.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Heavy';
  font-weight: '900';
  src: url('../../../asset/fonts/NanumSquareNeoOTF-Hv.otf') format('OpenType');
}
