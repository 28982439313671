@import url('./css/font.css');
@import url('./css/height.css');
@import url('./css/margin.css');
@import url('./css/padding.css');
@import url('./css/width.css');
@import url('./css/bg.css');
@import url('./css/gap.css');
@import url('./css/datePicker.css');

.scroll-item::selection {
  background: transparent;
}

.of-scroll::-webkit-scrollbar {
  display: none;
}
.browser-of-scroll-horizontal::-webkit-scrollbar {
  display: none;
}
input[type='month']::-webkit-calendar-picker-indicator,
input[type='month']::-webkit-inner-spin-button {
  display: none;
  appearance: none;
}
input[type='month']::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url('/src/asset/images/report/calendar.svg') no-repeat 50% 50%;
  width: 30px;
  height: 30px;
  border-width: thin;
}
.policy-item {
  border: 2px solid #e5eaf0;
  cursor: pointer;
}
.policy-item:active {
  border: 2px solid #09ce7d;
}
.bottom-m-16 {
  bottom: -16px;
}

.hover {
  cursor: pointer;
  user-select: none;
}

.opacity-80per {
  opacity: 80%;
}

.hover-strong {
  cursor: pointer;
  user-select: none;
}

/* .hidden_policy_dropdown_item:hover {
  background: #eaf8f2;
  color: #09ce7d;
} */

.hidden_policy_dropdown_item:active {
  background: #eaf8f2;
  color: #09ce7d;
}

.hover:hover {
  opacity: 70%;
}
.hover:active {
  opacity: 100%;
  filter: brightness(80%);
}
.hover-strong:hover {
  opacity: 50%;
}
.hover-strong:active {
  opacity: 100%;
  filter: brightness(80%);
}

.report_result_modify_button {
  right: 24px;
  bottom: 24px;
  background-color: #3d5066;
  z-index: 20;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
}
.z-9999 {
  z-index: 9999;
}
.z-1000 {
  z-index: 1000;
}
.report_result_green_middle_line {
  width: 1px;
  height: 40px;
  background: #171e261a;
  opacity: 1;
}
textarea::placeholder {
  color: #171e2666;
  font-weight: 500;
}
input::placeholder {
  color: #171e2640;
  font-family: Pretendard-Medium;
}
.border-radius-16-t-r {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.ballon55:after {
  border-top: 9px solid #3d5066;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 30px;
  right: 10px;
}

.ballon56:after {
  border-top: 9px solid #3d5066;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 30px;
  left: 10px;
}

.border-1-171E2610 {
  border: 1px solid #171e2610;
}
.border-1-171E2640 {
  border: 1px solid #171e2640;
}
.border-E5EAF0 {
  border: 1px solid #e5eaf0;
}
.border-increase {
  border: 1px solid #ec4f4f;
}
.border-2-increase {
  border: 2px solid #ec4f4f;
}

.left-90per {
  left: 90%;
}
.top-90per {
  top: 90%;
}

.border-b-1-171E2610 {
  border-bottom: 1px solid #171e2610;
}

.border-b-1-171E2640 {
  border-bottom: 1px solid #171e2640;
}

.report_result_box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 10px
    rgba(
      0.16470588743686676,
      0.21568627655506134,
      0.27450981736183167,
      0.07999999821186066
    );
}
.border-radius-999 {
  border-radius: 999px;
}
.box-shadow-all-side {
  box-shadow: 0 0 5px #333;
}
.radius_border {
  border: 1px solid #919191;
  border-radius: 5px;
}
.custom_typecontrol {
  position: absolute;
  top: 50px;
  right: 10px;
  overflow: hidden;
  width: 130px;
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 10;
  font-size: 12px;
  font-family: 'Malgun Gothic', '맑은 고딕', sans-serif;
}
.custom_typecontrol2 {
  position: absolute;
  top: 100px;
  right: 10px;
  overflow: hidden;
  width: 65px;
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 10;
  font-size: 12px;
  font-family: 'Malgun Gothic', '맑은 고딕', sans-serif;
}
.custom_typecontrol span {
  display: block;
  width: 65px;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background: #fff;
  background: linear-gradient(#fff, #e6e6e6);
}
.overflow-y-auto {
  overflow-y: auto;
}
.custom_typecontrol2 span {
  display: block;
  width: 65px;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background: #fff;
  background: linear-gradient(#fff, #e6e6e6);
}
.custom_typecontrol .btn {
  background: #fff;
  background: linear-gradient(#fff, #e6e6e6);
}
.custom_typecontrol .btn:hover {
  background: #f5f5f5;
  background: linear-gradient(#f5f5f5, #e3e3e3);
}
.custom_typecontrol .btn:active {
  background: #e6e6e6;
  background: linear-gradient(#e6e6e6, #fff);
}
.custom_typecontrol .selected_btn {
  color: #fff;
  background: #425470;
  background: linear-gradient(#425470, #5b6d8a);
}
.custom_typecontrol2 .selected_btn {
  color: #fff;
  background: #425470;
  background: linear-gradient(#425470, #5b6d8a);
}

#wrapper {
  /* 글자 드래그 막기 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#carousel-list {
  display: flex;
  width: fit-content;
}
.fit-content {
  width: fit-content;
}
.carousel-list-transition {
  transition: 0.6s ease-out;
}
.border-right-F0F0F0 {
  border-right: 2px solid #f0f0f0;
}
.carousel-item {
  width: 220px;
  text-align: center;
}

.carousel-item:nth-child(3n) {
  background-color: skyblue;
}

.carousel-item:nth-child(3n + 1) {
  background-color: greenyellow;
}

.carousel-item:nth-child(3n + 2) {
  background-color: tomato;
}
.border-dotted-171E2610 {
  border: 1px dotted #171e2610;
}
.border-disabled {
  border: 1px solid rgba(23, 30, 38, 0.1);
}

.border-b-171E26 {
  border-bottom: 1px solid #171e2610;
}
.border-t-171E26 {
  border-top: 1px solid #171e2610;
}
.border-t-171E2640 {
  border-top: 1px solid #171e2640;
}
.border-b {
  border-bottom: 1px solid #3d5066;
}

.background {
  background: url('./asset/images/new/mainBackground.png') no-repeat left top;
  background-size: cover;
  background-position: right;
  background-color: #1e9b6b;
}

.background2 {
  background: url('./asset/images/new/mainBackground2.png') no-repeat left top;
  background-size: contain;
  background-position: right;
  background-color: #def8eb;
}
.myCustomInput:focus {
  caret-color: #1e9b6b;
}
.background3 {
  background: url('./asset/images/new/mainBackground3.png') no-repeat left top;
  background-size: contain;
  background-position: right;
  background-color: #ddf4ff;
}

.background-phone {
  background: url('./asset//images//main/phone.png') no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #e5eaf0;
  background-position: 50%;
}
.background-house {
  background: url('./asset/images/house/ad_bg.png');
  height: 250px;
  background-size: cover;
}
.background-banner1 {
  background:
    linear-gradient(
      to right,
      rgba(5, 47, 1, 0.7) 10%,
      rgba(255, 255, 255, 0) 100%
    ),
    url('./asset/images/main/banner1_mob.png') no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 165px;
}
.background-banner2 {
  background:
    linear-gradient(to right, rgb(68, 39, 1), rgba(255, 255, 255, 0)),
    url('./asset/images/main/banner2_mob.png') no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 165px;
}
.background-banner3 {
  background:
    linear-gradient(to right, rgb(22, 57, 1), rgba(255, 255, 255, 0)),
    url('./asset/images/main/banner3_mob.png') no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 165px;
}
.z-1 {
  z-index: 1;
}
.centering {
  position: fixed;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.z-20 {
  z-index: 20;
}
.linear-gradient-E5EAF0-C6D0DD {
  background: linear-gradient(#ffffff, #c6d0dd);
}

.tooltip {
  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 1s,
    transform 1s;
}

.tooltip.visible {
  opacity: 1;
  transform: translateY(0);
}
.speech-bubble {
  position: relative;
}
.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #2a3746;
  border-bottom: 0;
  margin-left: -9px;
  margin-bottom: -9px;
}
.speech-bubble5 {
  position: relative;
}
.speech-bubble5:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #3d5066;
  border-bottom: 0;
  margin-left: -9px;
  margin-bottom: -9px;
}

.speech-bubble-pension {
  position: relative;
}

.speech-bubble-pension:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #3d5066;
  border-top: 0;
  margin-left: 40px;
  margin-bottom: 40px;
}
.speech-bubble5 {
  position: relative;
}

.speech-bubble9 {
  position: relative;
}

.speech-bubble9:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 60%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #3d5066;
  border-top: 0;
  margin-left: 40px;
  margin-bottom: 40px;
}
.tool-tip {
  position: relative;
}

/* .tool-tip:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: #3d5066;
  border-bottom: 0;
  margin-left: -7px;
  margin-bottom: -7px;
} */
.speech-bubble2 {
  position: relative;
}
.speech-bubble2::after {
  bottom: 100%; /*말풍선꼭지위치 위아래*/
  left: 50%; /*말풍선위치 좌우*/
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent #5e7188 transparent; /*말풍선 꼭지방향*/
  border-width: 5px; /*말풍선 꼭지 사이즈*/
  margin-left: -5px; /*말풍선 상세위치 조정*/
}
.speech-bubble3 {
  position: relative;
  z-index: 100;
}
.speech-bubble3::after {
  bottom: 100%; /*말풍선꼭지위치 위아래*/
  left: 80%; /*말풍선위치 좌우*/
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent #5e7188 transparent; /*말풍선 꼭지방향*/
  border-width: 5px; /*말풍선 꼭지 사이즈*/
  margin-left: -5px; /*말풍선 상세위치 조정*/
  z-index: 100;
}
.speech-bubble3 {
  position: relative;
  z-index: 100;
}
.speech-bubble3::after {
  bottom: 100%; /*말풍선꼭지위치 위아래*/
  left: 80%; /*말풍선위치 좌우*/
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent #5e7188 transparent; /*말풍선 꼭지방향*/
  border-width: 5px; /*말풍선 꼭지 사이즈*/
  margin-left: -5px; /*말풍선 상세위치 조정*/
  z-index: 100;
}
.speech-bubble4 {
  position: relative;
}
.speech-bubble4::after {
  bottom: 35px; /*말풍선꼭지위치 위아래*/
  left: 50%; /*말풍선위치 좌우*/
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent #5e7188 transparent; /*말풍선 꼭지방향*/
  border-width: 5px; /*말풍선 꼭지 사이즈*/
  margin-left: -5px; /*말풍선 상세위치 조정*/
}
.speech-bubble7 {
  position: relative;
}

.speech-bubble7:after {
  content: '';
  position: absolute;
  bottom: -247%;
  left: 12%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #3d5066;
  border-top: 0;
  margin-left: 100px;
  margin-bottom: 100px;
  transform: rotate(90deg);
}
.transparent {
  background: transparent;
}
.sidemenu-open {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sidemenu {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.z-10 {
  z-index: 10;
}
.z-19 {
  z-index: 19;
}
.z-25 {
  z-index: 25;
}
.z-999 {
  z-index: 999;
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}

.top-100per {
  top: 100%;
}
.top-0 {
  top: 0;
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline-flex {
  display: inline-flex;
}
.border-b-E2E2E2 {
  border-bottom: 1px solid #e2e2e2;
}
.bottom-0 {
  bottom: 0;
}
.bottom-25 {
  bottom: 25px;
}
.background-banner4 {
  background: url('./asset/images/report/banner.png') no-repeat;
  background-size: cover;
}
.border-b-a0a0a0 {
  border-bottom: 1px solid #a0a0a0;
}
.border-b-2-white {
  border-bottom: 2px solid white;
}
.border-b-2-171E2610 {
  border-bottom: 2px solid #171e2610;
}
.border-b-2-171E26 {
  border-bottom: 2px solid #171e26;
}
.border-b-f0f0f095 {
  border-bottom: 1px solid #f0f0f095;
}

.border-t-f0f0f0 {
  border-top: 1px solid #f0f0f0;
}
.border-r-171E26 {
  border-right: 1px solid #171e26;
}
.border-r-171E2640 {
  border-right: 1px solid #171e2640;
}
.border-r-171E2610 {
  border-right: 1px solid #171e2610;
}
.border-r-e2e2e2 {
  border-right: 1px solid #e2e2e2;
}
.l-0 {
  left: 0;
}
.t-0 {
  top: 0;
}
.border-r-radius-100 {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.border-l-171E26 {
  border-left: 1px solid #171e2610;
}
.border-l-radius-100 {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.of-scroll {
  overflow: scroll;
  scrollbar-width: thin;
}

.of-visible {
  overflow: visible;
}

.of-hidden {
  overflow: hidden;
}

.of-x-auto {
  overflow-x: auto;
}

.of-x-scroll {
  overflow-x: scroll;
}

.of-y-auto {
  overflow-y: auto;
}

.top-56 {
  top: 56px;
}
.top-176 {
  top: 176px;
}
.border-b-disabled {
  border-bottom: 1px solid #ffffff50;
}
.border-radius-18 {
  border-radius: 18px;
}
.border-radius-16 {
  border-radius: 16px;
}
.border-radius-14 {
  border-radius: 14px;
}
.border-radius-t-l-16 {
  border-top-left-radius: 16px;
}
.border-radius-t-r-24 {
  border-top-right-radius: 24px;
}
.border-radius-t-l-24 {
  border-top-left-radius: 24px;
}
.border-radius-b-r-24 {
  border-bottom-right-radius: 24px;
}
.border-radius-b-l-24 {
  border-bottom-left-radius: 24px;
}
.border-radius-t-r-16 {
  border-top-right-radius: 16px;
}
.border-radius-t-l-8 {
  border-top-left-radius: 8px;
}
.border-radius-b-l-8 {
  border-bottom-left-radius: 8px;
}
.border-radius-t-r-6 {
  border-top-right-radius: 6px;
}
.border-radius-b-r-6 {
  border-bottom-right-radius: 6px;
}
.border-radius-t-r-8 {
  border-top-right-radius: 8px;
}
.border-radius-11 {
  border-radius: 11px;
}
.border-radius-b-r-8 {
  border-bottom-right-radius: 8px;
}
.border-b-171E2610-2px {
  border-bottom: 2px solid #171e2610;
}
.border-2-primary-400 {
  border: 2px solid #09ce7d;
}

.top-50 {
  top: 50px;
}
.top-52 {
  top: 52px;
}
.top-53 {
  top: 53px;
}
.top-69 {
  top: 69px;
}
.left-m-18per {
  left: -18%;
}
.top-69-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: fixed;
  top: 69px;
  z-index: 10;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.overflow-auto {
  overflow: auto;
}

.margin-bottom-71 {
  margin-bottom: 71px;
}

.transition {
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
}
.line-through {
  text-decoration: line-through;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.no-wrap {
  white-space: nowrap;
}

.greybox {
  background-color: #f2f5f5;
  border-radius: 17px;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.center-y {
  align-items: center;
}

.center-x {
  justify-content: center;
}

.start-y {
  align-items: flex-start;
}

.start-x {
  justify-content: flex-start;
}

.end-y {
  align-items: flex-end;
}

.end-x {
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-half {
  flex: 0.5;
}

.area-tag {
  padding: 8px 15px;
  border: 1px solid #7e7e7e;
  border-radius: 7px;
  gap: 5px;
}
.border-none {
  border: none;
}

input:focus {
  outline: none;
}
.border-1 {
  border: 1px solid #7e7e7e;
}
.border-1-1E9B6B {
  border: 1px solid #1e9b6b;
}
.border-171E26 {
  border: 1px solid #171e2610;
}
.border-09CE7D {
  border: 1px solid #09ce7d;
}
.border-f0f0f0 {
  border: 1px solid #f0f0f0;
}
.border-166F4D {
  border: 1px solid #166f4d;
}

.border-166F4D-2px {
  border: 2px solid #166f4d;
}
.border-EFF2F5 {
  border: 1px solid #eff2f5;
}

.border-radius {
  border-radius: 12px;
}

.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-8 {
  border-radius: 8px;
}

.border-radius-10 {
  border-radius: 10px;
}

.browser-top-122 {
  top: 122px;
}
.browser-text-end {
  text-align: end;
}
.browser-end-y {
  align-items: flex-end;
}

.border-radius-50 {
  border-radius: 50px;
}
.border-radius-80 {
  border-radius: 80px;
}

.border-radius-100 {
  border-radius: 100px;
}

.border-radius-8-th {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border-collapse: separate;
}
.border-radius-t-8 {
  border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}
.border-radius-b-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.border-radius-25per {
  border-radius: 25%;
}

.border-radius-50per {
  border-radius: 50%;
}
.border-radius-100per {
  border-radius: 100%;
}

.border-radius-13 {
  border-radius: 13px;
}

.border-radius-17 {
  border-radius: 17px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-22 {
  border-radius: 22px;
}

.border-radius-27 {
  border-radius: 27px;
}

.border-top-radius-27 {
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
}

.border-top-radius-16 {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.z-100 {
  z-index: 100;
}

.border-radius-38 {
  border-radius: 38px;
}
.trans-center {
  transform: translate(-50%, -50%);
}

.overflow-hidden {
  overflow: hidden;
}

.border-1-0 {
  border-top: 1px #e6e6e6 solid;
  border-bottom: 1px #e6e6e6 solid;
}

.border-1-e2e2e2 {
  border: 1px solid #e2e2e2;
}

.slider {
  position: absolute;
  bottom: 0;
  height: 2px;
  border-radius: 4px;
  background: #009673;
  transition: left 0.3s ease;
  width: 30px;
}

.slider-black {
  position: absolute;
  bottom: 0;
  height: 2px;
  border-radius: 4px;
  background: black;
  transition: left 0.3s ease;
  /* width: 30px; */
}
.slider-black2 {
  position: absolute;
  bottom: 0;
  height: 2px;
  border-radius: 4px;
  background: black;
  transition: left 0.3s ease;
  /* width: 30px; */
}
.max-1024-slider {
  position: absolute;
  /* bottom: 0; */
  height: 2px;
  border-radius: 4px;
  background: #000000;
  transition: left 0.3s ease;
  width: 30px;
}
.relative {
  position: relative;
}

.w-55 {
  width: 55px;
}
.h-55 {
  height: 55px;
}

.circle-dummy {
  background-color: #e6e6e6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

#diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: pink;
  position: relative;
  top: -50px;
}

#diamond:after {
  content: '';
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: pink;
}

.green-box-1 {
  background-color: #00967331;
}

.green-box-2 {
  background-color: #00967362;
}

.green-box-3 {
  background-color: #0096739a;
}

.green-box-4 {
  background-color: #009673e1;
}
.grid {
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
}

.grid-row-4 {
  max-width: 300px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  /* grid-template-rows: repeat(3, 1fr); */
  gap: 10px;
}

.dollor {
  background-color: gold;
  padding: 20px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.start-date {
  background-color: red; /* Style for the starting date */
}

.end-date {
  background-color: green; /* Style for the ending date */
}

.range-date {
  background-color: yellow; /* Style for the range */
}
.main-background {
  background: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.9)),
    url('./asset/images/main/background.png');
  display: flex;
  width: 100%;
  height: 960px;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
}
.background-card {
  background: linear-gradient(
    to bottom,
    rgba(247, 248, 249, 1),
    rgba(221, 239, 233, 1)
  );

  background-size: contain;
  background-position: 0 50%;
  width: 100%;
  height: 180px;
}
.sidebar-bg {
  background: linear-gradient(to right, #1e9b6b, #166f4d);
}
.sidebar-box-shadow {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
/* .background-card {
  background: linear-gradient(
      to bottom,
      rgba(247, 248, 249, 1),
      rgba(221, 239, 233, 1)
    ),
    url("./asset/images/findLand/bg.png");
  background-size: contain;
  background-position: center center;
  height: 180px;

  width: 100%;
} */
.border-0d8-b {
  border-bottom: 0.8px solid #efefef;
}
.border-t-00000010 {
  border-top: 1px solid #00000010;
}
.border-r-00000010 {
  border-right: 1px solid #00000010;
}

.border-1-171E26 {
  border: 1px solid #171e2610;
}
.border-1-09CE7D {
  border: 1px solid #09ce7d;
}
.border-2-09CE7D {
  border: 2px solid #09ce7d;
}
.border-171E26-2px {
  border: 2px solid #171e2610;
}
.text-start {
  text-align: start;
}
.border-1E9B6B {
  border: 1px solid #1e9b6b;
}
.border-1E9B6B-2px {
  border: 2px solid #1e9b6b;
}
.border-radius-6 {
  border-radius: 6px;
}
.main-color-box {
  border-radius: 20px;
  padding: 36px 36px 36px 36px;
}

.circle {
  width: 103px;
  height: 103px;
  border-radius: 112px;
  background-color: #f2f5f5;
  border: 1px solid #eaeded;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px;
}

.square {
  min-width: 110px;
  min-height: 110px;
  border-radius: 20px;
  background-color: #f2f5f5;
  border: 1px solid #eaeded;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: nowrap;
}

.facility-box {
  box-shadow: 0px 0px 5px #18181b6e;
  border-radius: 20px;
}
.profile-shadow {
  box-shadow: -10px 10px 23px 0px #1418131a;
}
.shadow-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px #00000020;
  padding: 20px 20px;
  border-radius: 12px;
}
.white-shadow-box {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 2px 10px #00000020;
  padding: 20px 20px;
  border-radius: 12px;
}

.bluegrey100-shadow-box {
  display: flex;
  flex-direction: column;
  background-color: rgba(239, 242, 245, 1);
  box-shadow: 0 2px 10px #00000020;
  padding: 20px 20px;
  border-radius: 12px;
}
.scrollbar {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  overflow-x: scroll;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.scrollbar::-webkit-scrollbar {
  /* display: inline; */
  padding-top: 1px;
}

.facility-box-devide {
  width: 1px;
  height: 44px;
  background-color: #f2f2f2;
}

.analyze-box {
  width: 100%;
  padding: 36px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
  box-shadow: 0px 2px 5px #18181b6e;
  background-color: #f0f8fa;
  cursor: pointer;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100px;
  }
}

.animate-tooltip {
  animation: fade-in 0.3s ease-in-out;
}
.fade-in-up {
  animation: fadeInUp 1s ease-out;
}
.fade-in-down {
  animation: fadeInDown 1s ease-out;
}
.house-big {
  width: 121px;
  height: 149px;
}
.slick-slide {
  margin: 0 20px;
}
.house {
  display: none;
}

.box-shadow-0-2-10-00000020 {
  box-shadow: 0 2px 10px #00000020;
}

.place-center {
  place-content: center;
}

.top-132 {
  top: 132px;
}
.top-59per {
  top: 59%;
}

.gap-48 {
  gap: 48px;
}
.border-radius-38 {
  border-radius: 38px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50vh; */
}

.spinner-dot {
  width: 10px;
  /* height: 10px; */
  background-color: #333;
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1s infinite ease-in-out;
}
.alert-font {
  font-family: 'Pretendard-Bold';
  font-size: 16px;
}
.alert-btn-font {
  font-family: 'Pretendard-Bold';
  font-size: 16px;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.fadeout {
  /* animation : 효과(@keyframes 이름) 동작시간 */
  animation: fadeout 3s;
}

@keyframes fadeout {
  /* 효과를 동작시간 동안 0 ~ 1까지 */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.ticket-box {
  width: 100%;
  padding: 36px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  box-shadow: 0px 2px 5px #18181b6e;
  background-color: #e3f6eb;
}

.border-t-1-f0f0f0 {
  border-top: 1px solid #f0f0f0;
}
.border-t-2-000 {
  border-top: 2px solid #000;
}

.pointer {
  cursor: pointer;
}

.auction-list-item:hover {
  background-color: #eaf8f2;
}

.auction-list-item:visited {
  background-color: #eaf8f2;
}

.min-w-35 {
  min-width: 35px;
  justify-content: center;
}
.pointer-e-none {
  pointer-events: none;
  /* 글자 드래그 막기 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  cursor: pointer;
}

.hover-marker:hover > .hover-marker1 {
  background: #ffffff;
  color: #000000;
}
.hover-marker2 {
  color: #ffffff;
}
.hover-marker:hover .hover-marker2 {
  color: #09ce7d;
}
.hover-marker:hover .hover-marker4 {
  color: #09ce7d;
}

.border-1-e6e6e6 {
  border: 1px solid #e6e6e6;
}

.border-r-1-e2e2e2 {
  border-right: 1px solid #e2e2e2;
}

.border-1-f0f0f0 {
  border: #f0f0f0 1px solid;
}

.border-radius-39 {
  border-radius: 39px;
}
.green-marker {
  width: 32px;
  height: 42px;
}
.hover-marker3 {
  visibility: hidden;
}
.green-marker:hover > .hover-marker3 {
  visibility: visible;
}
.banner {
  width: 100%;
  padding: 64px;
  background-color: #f2f5f5;
  margin-bottom: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chapter-container {
  display: flex;
  flex-direction: column;
  border-bottom: 10px solid rgba(242, 243, 246, 1);
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.container-w90per {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
}
.title-wrapper {
  display: flex;
  border-bottom: 1px solid #8a897b;
  padding-bottom: 10px;
}
.title-wrapper-no-border {
  display: flex;
  padding-bottom: 10px;
}
.banner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px 10px 20px 10px;
  box-shadow: 0 2px 10px #00000020;
}
.button {
  display: flex;
  padding: 20px 20px;
  background-color: #3ea605;
  border-radius: 10px;
}
.banner-inner {
  max-width: 1224px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.introduce {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(0, 175, 135, 1) 50%,
    rgba(56, 239, 125, 1)
  );
  /* background-color: rgba(56, 239, 125, 1); */
  height: 293px;
  padding: 70px 0px 0px 0px;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  height: 267px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #171e2610;
  z-index: 3;
}

.mobile-footer-line {
  width: 10px;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  border-bottom: solid 1px #e2e2e2;
  margin-bottom: -1px;
  transform: matrix(0, 1, -1, 0, 0, 0);
}
.underline {
  text-decoration: underline;
}
.decoration-none {
  text-decoration: none;
}

.main-icon {
  width: 74px;
  height: 74px;
}

/*font*/

.bold {
  font-family: SpoQaHanSansNeoBold;
}

.regular {
  font-family: SpoQaHanSansNeoRegular;
}

.medium {
  font-family: SpoqaHanSansNeoMedium;
}

.ai-farming-section-title {
  font-weight: 700;
  font-size: 40px;
  color: #000000;
  line-height: 40px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.ai-farming-facility-tablet-big {
  font-weight: 700;
  font-size: 30px;
  color: #18181b;
  line-height: 30px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.ai-farming-facility-tablet-small {
  font-weight: 400;
  font-size: 22px;
  color: #71717a;
  line-height: 22px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
}

.ai-farming-support-tablet-bold {
  font-size: 32px;
  color: #18181b;
  line-height: 42px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.ai-farming-support-tablet {
  font-size: 32px;
  color: #18181b;
  line-height: 42px;
  font-family: SpoQaHanSansNeoMedium;
  white-space: normal;
}

.ai-farming-crop-title {
  font-size: 17px;
  color: #18181b;
  line-height: 17px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.ai-farming-crop-sub-title {
  font-size: 14px;
  color: #71717a;
  line-height: 14px;
  font-family: SpoQaHanSansNeoMedium;
  white-space: normal;
  text-align: left;
}

.ai-farming-crop-item {
  font-size: 14px;
  color: #18181b;
  line-height: 14px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.ai-farming-crop-button {
  font-size: 16px;
  color: #18181b;
  line-height: 22px;
  font-family: SpoQaHanSansNeoMedium;
  white-space: normal;
}

.main-description-regular {
  font-size: 28px;
  color: #ffffff;
  line-height: 40px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
  text-align: center;
}

.main-description-bold {
  font-size: 34px;
  color: #ffffff;
  line-height: 50px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.main-count-regular {
  font-size: 20px;
  color: #ffffff;
  line-height: 20px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
  text-align: center;
}

.main-count-bold {
  font-size: 68px;
  color: #ffffff;
  line-height: 68px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.main-description2-regular {
  font-size: 22px;
  color: #ffffff70;
  line-height: 32px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
}

.main2-title-bold {
  font-size: 48px;
  color: #18181b;
  line-height: 62px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.main2-description-regular {
  font-size: 18px;
  color: #71717a;
  line-height: 24px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
}

.main2-part-description-medium-green {
  font-size: 15px;
  color: #009673;
  line-height: 19px;
  font-family: SpoQaHanSansNeoMedium;
  white-space: normal;
}

.main2-part-description-medium-blue {
  font-size: 15px;
  color: #008aa9;
  line-height: 19px;
  font-family: SpoQaHanSansNeoMedium;
  white-space: normal;
}

.main2-part-description-bold {
  font-size: 24px;
  color: #18181b;
  line-height: 28px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
}

.main2-part-description-regular {
  font-size: 16px;
  color: #18181b;
  line-height: 22px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
}

.main-button-regular {
  font-size: 14px;
  color: #ffffffe5;
  line-height: 19px;
  font-family: SpoQaHanSansNeoRegular;
  white-space: normal;
  text-align: center;
}

.main-button-bold {
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  font-family: SpoQaHanSansNeoBold;
  white-space: normal;
  text-align: center;
}

.map-pretendard-bold {
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
}

.map-pretendard-regular {
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
}

.map-montserrat-bold {
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
  color: #0982fe;
}
/*font*/

.browser2 {
  display: flex;
}

.browser {
  display: flex;
}

.tablet {
  display: none;
}

.none {
  display: none;
}

.tablet-flex {
  display: none;
}

.tablet-flex-row {
  flex-direction: column;
}
.keyword-search {
  padding: 40px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tablet-new-flex-row {
  display: flex;
  flex-direction: column;
}
.mobile-footer-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px 80px 16px;
  gap: 20px;
  background-color: #fff;
  border-top: 1px solid #171e2610;
}
.footer-nav-text-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.empty-message-box {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 112px 0;
}
.empty-message-box img {
  width: 48px;
  height: 48px;
}
.empty-message {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
  text-align: center;
}

@media (min-width: 768px) {
  .mobile-footer-wrap {
    padding: 32px 40px 80px 40px;
  }
  .footer-nav-text-box {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  .mobile-flex {
    display: none;
  }
  .tablet-center-x {
    align-items: center;
  }
  .tablet-new-flex-row {
    flex-direction: row;
  }

  .w-768-gap-16 {
    gap: 16px;
  }

  .tablet-flex {
    display: flex;
  }

  .tablet-padding-6-16 {
    padding: 6px 16px;
  }

  .keyword-search {
    padding: 40px 0;
    gap: 16px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .tablet-flex-row {
    flex-direction: row;
  }
  .tablet-w-260 {
    width: 260px;
  }
  .tablet-w-350 {
    width: 350px;
  }
  .tablet-w-360 {
    width: 360px;
  }
  .tablet-h-181 {
    height: 181px;
  }
  .tablet-f-size-16 {
    font-size: 16px;
  }
  .tablet-f-size-18 {
    font-size: 18px;
  }
  .tablet-w-100per {
    width: 100%;
  }
  .tablet-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
  }
  .tablet-max-w-688 {
    max-width: 688px;
  }
  .tablet-max-w-336 {
    max-width: 336px;
  }
  .tablet-padding-y-32 {
    padding: 32px 0;
  }
}
.browser-display-1023 {
  display: none;
}
@media (min-width: 1023px) {
  .tablet-max-w-904 {
    max-width: 904px;
  }
  .tablet-padding-24-32 {
    padding: 24px 32px;
  }

  .tablet-max-w-312 {
    max-width: 312px;
    height: 320px;
  }

  .tablet-gap-16 {
    gap: 16px;
  }

  .tablet-policy-title {
    font-size: 18px;
    line-height: 30px;
  }
  .browser-display-1023 {
    display: flex;
  }

  .tablet-wide-none {
    display: none;
  }
}
@media (min-width: 1920px) {
  .browser-max-w-1200 {
    max-width: 1200px;
  }
}

@media (max-width: 1023px) {
  .tablet {
    display: flex;
  }

  .browser {
    display: none;
  }

  .tablet-flex-column {
    display: flex;
    flex-direction: column;
  }

  .tablet-flex-row {
    display: flex;
    flex-direction: row;
  }

  .tablet-h-55 {
    height: 55px;
  }

  .tablet-h-130 {
    height: 130px;
  }

  .tablet-w-55 {
    width: 55px;
  }
  .tablet-w-688 {
    width: 688px;
  }
  .tablet-w-125 {
    width: 125px;
  }

  .tablet-w-650 {
    width: 650px;
  }

  .tablet-w-100per {
    width: 100%;
  }

  .tablet-max-w-none {
    max-width: none;
  }

  .tablet-padding-none {
    padding: 0;
  }

  .tablet-padding-y-20 {
    padding: 20px 0;
  }

  .tablet-padding-x-20 {
    padding: 0 20px;
  }

  .tablet-mt-0 {
    margin-top: 0;
  }

  .tablet-mb-0 {
    margin-bottom: 0;
  }

  .tablet-mt-30 {
    margin-top: 30px;
  }

  .tablet-mt-48 {
    margin-top: 48px;
  }

  .tablet-mt-52 {
    margin-top: 52px;
  }

  .tablet-gap-0 {
    gap: 0px;
  }

  .tablet-gap-8 {
    gap: 8px;
  }

  .tablet-gap-10 {
    gap: 10px;
  }

  .tablet-gap-20 {
    gap: 20px;
  }

  .tablet-gap-22 {
    gap: 22px;
  }

  .tablet-gap-42 {
    gap: 42px;
  }

  .tablet-gap-48 {
    gap: 48px;
  }

  .tablet-overflow-auto {
    overflow: auto;
  }

  .tablet-overflow-hidden {
    overflow: hidden;
  }

  .tablet-flex-nowrap {
    flex-wrap: nowrap;
  }

  .tablet-start-x {
    justify-content: start;
  }

  .tablet-h-100vh {
    height: 100vh;
  }

  .tablet-h-100per {
    height: 100%;
  }
  .tablet-x-none {
    justify-content: none;
  }

  .house-big {
    display: none;
  }

  .house {
    display: block;
    width: 60px;
    height: 60px;
  }

  .banner {
    padding: 28px 22px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px #18181b6e;
  }

  .ticket-box {
    padding: 32px 20px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    box-shadow: none;
    background-color: #e3f6eb;
  }

  .top-bar {
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 10px 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px #00000020;
  }

  .bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 10;
  }

  .analyze-box {
    width: 100%;
    padding: 28px 22px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 36px;
    align-items: center;
    box-shadow: 0px 2px 5px #18181b6e;
    background-color: #f0f8fa;
  }

  .main-background {
    background: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.9)),
      url('./asset/images/main/backgroundImage.png');
    background-size: cover;
    background-position: center;
  }
  .findland-report-background {
    background: url('./asset/images/findLand/BackImage.png');
    background-size: cover;
  }

  .main-icon {
    width: 60px;
    height: 60px;
  }

  .main-color-box {
    border-radius: 20px;
    padding: 28px 28px 28px 28px;
  }

  /* font */

  .ai-farming-section-title {
    font-weight: 500;
    font-size: 17px;
    color: #000000;
    line-height: 21px;
    font-family: SpoqaHanSansNeoMedium;
    white-space: normal;
  }

  .ai-farming-facility-tablet-big {
    font-weight: 700;
    font-size: 20px;
    color: #18181b;
    line-height: 30px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
  }

  .ai-farming-facility-tablet-small {
    font-weight: 400;
    font-size: 14px;
    color: #71717a;
    line-height: 20px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
  }

  .ai-farming-support-tablet-bold {
    font-size: 16px;
    color: #18181b;
    line-height: 24px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
  }

  .ai-farming-support-tablet {
    font-size: 16px;
    color: #18181b;
    line-height: 24px;
    font-family: SpoQaHanSansNeoMedium;
    white-space: normal;
  }

  .main-description-regular {
    font-size: 18px;
    color: #ffffff;
    line-height: 24px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
    text-align: center;
  }

  .main-description-bold {
    font-size: 21px;
    color: #ffffff;
    line-height: 24px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
  }

  .main-count-regular {
    font-size: 15px;
    color: #ffffff;
    line-height: 15px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
    text-align: center;
  }

  .main-count-bold {
    font-size: 40px;
    color: #ffffff;
    line-height: 36px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
  }

  .main-description2-regular {
    font-size: 16px;
    color: #ffffff70;
    line-height: 22px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
  }

  .main2-part-description-medium-green {
    font-size: 13px;
    color: #009673;
    line-height: 17px;
    font-family: SpoQaHanSansNeoMedium;
    white-space: normal;
  }

  .main2-part-description-medium-blue {
    font-size: 13px;
    color: #008aa9;
    line-height: 17px;
    font-family: SpoQaHanSansNeoMedium;
    white-space: normal;
  }

  .main2-part-description-bold {
    font-size: 20px;
    color: #18181b;
    line-height: 32px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
  }

  .main2-part-description-regular {
    font-size: 15px;
    color: #18181b;
    line-height: 21px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
    text-align: start;
  }

  .main-button-regular {
    font-size: 14px;
    color: #ffffff90;
    line-height: 19px;
    font-family: SpoQaHanSansNeoRegular;
    white-space: normal;
    text-align: center;
  }

  .main-button-bold {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    font-family: SpoQaHanSansNeoBold;
    white-space: normal;
    text-align: center;
  }
  .to-top-btn {
    position: fixed;
    right: 16px;
    z-index: 10;
  }
  .to-top-btn img {
    width: 48px;
  }

  /* font */
}
.max-w-448 {
  max-width: 448px;
}
.max-w-681 {
  max-width: 681px;
}
.max-w-210 {
  max-width: 210px;
}
.max-w-1200 {
  max-width: 1200px;
}
.max-w-327 {
  max-width: 327px;
}

@media (min-width: 1024px) {
  .mobile-footer-wrap {
    display: none;
  }
  .browser {
    display: flex;
  }
  .w-1024-center-y {
    align-items: center;
  }
  .w-1024-gap-64 {
    gap: 64px;
  }

  .w-1024-mt-24 {
    margin-top: 24px;
  }
  .w-1024-gap-32 {
    gap: 32px;
  }
  .browser2 {
    display: none;
  }
  .browser-flex-1 {
    flex: 1;
  }
  .browser-flex-half {
    flex: 0.5;
  }

  .browser-z-1 {
    z-index: 1;
  }

  .browser-z-10 {
    z-index: 10;
  }
  .browser-z-20 {
    z-index: 20;
  }
  .browser-h-100vh {
    height: 100vh;
  }
  .browser-fixed {
    position: fixed;
  }

  .browser-relative {
    position: relative;
  }
  .browser-row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .browser-absolute {
    position: absolute;
  }
  .browser-1920-gap-32 {
    gap: 32px;
  }

  .browser-border-radius-10 {
    border-radius: 10px;
  }

  .browser-inline-flex {
    display: inline-flex;
  }

  .browser-bottom-0 {
    bottom: 0;
  }
  .browser-w-100per {
    width: 100%;
  }

  .browser-border-b-f0f0f095 {
    border-bottom: 1px solid #f0f0f095;
  }
  .browser-of-scroll-horizontal {
    overflow: scroll;
  }

  .browser-border-t-f0f0f0 {
    border-top: 1px solid #f0f0f0;
  }
  .browser-border-t-radius-8 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .browser-border-r-e2e2e2 {
    border-right: 1px solid #e2e2e2;
  }

  .browser-of-scroll {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    /* background-color: #f0f0f0; */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  .browser-top-118 {
    top: 118px;
  }
  .browser-of-visible {
    overflow: visible;
  }

  .browser-of-hidden {
    overflow: hidden;
  }

  .browser-of-x-auto {
    overflow-x: auto;
  }

  .browser-of-x-scroll {
    overflow-x: scroll;
  }

  .browser-of-y-auto {
    overflow-y: auto;
  }

  .browser-top-69 {
    top: 69px;
  }

  .browser-top-69-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: fixed;
    top: 69px;
    z-index: 10;
    flex-direction: column;
    height: 100%;
    background-color: #ffffff;
  }
  .browser-background {
    background: url('./asset/images/new/mainBackground.svg') no-repeat right top;
    background-size: contain;
    background-position: bottom right;
    background-color: #1e9b6b;
  }
  .browser-overflow-auto {
    overflow: auto;
  }

  .browser-margin-bottom-71 {
    margin-bottom: 71px;
  }
  .browser-max-w-681 {
    max-width: 681px;
  }
  .browser-flex-row {
    display: flex;
    flex-direction: row;
  }

  .browser-flex-column {
    display: flex;
    flex-direction: column;
  }
  .browser-flex-start {
    display: flex;
    justify-content: start;
  }
  .browser-flex-wrap {
    flex-wrap: wrap;
  }

  .browser-greybox {
    background-color: #f2f5f5;
    border-radius: 17px;
  }

  .browser-flex-center {
    justify-content: center;
    align-items: center;
  }

  .browser-space-between {
    justify-content: space-between;
  }

  .browser-center-y {
    align-items: center;
  }

  .browser-center-x {
    justify-content: center;
  }

  .browser-start-y {
    align-items: flex-start;
  }

  .browser-start-x {
    justify-content: flex-start;
  }

  .browser-end-y {
    align-items: flex-end;
  }

  .browser-end-x {
    justify-content: flex-end;
  }

  .browser-flex-1 {
    flex: 1;
  }

  .browser-area-tag {
    padding: 8px 15px;
    border: 1px solid #7e7e7e;
    border-radius: 7px;
    gap: 5px;
  }

  .browser-border-1 {
    border: 1px solid #7e7e7e;
  }
  .browser-border-none {
    border: none;
  }
  .browser-border-radius {
    border-radius: 12px;
  }

  .browser-border-radius-4 {
    border-radius: 4px;
  }

  .browser-border-radius-8 {
    border-radius: 8px;
  }

  .browser-border-radius-10 {
    border-radius: 10px;
  }

  .browser-border-radius-13 {
    border-radius: 13px;
  }
  .browser-border-radius-16 {
    border-radius: 16px;
  }
  .browser-border-radius-17 {
    border-radius: 17px;
  }

  .browser-border-radius-20 {
    border-radius: 20px;
  }

  .browser-border-radius-22 {
    border-radius: 22px;
  }

  .browser-border-radius-27 {
    border-radius: 27px;
  }

  .browser-border-radius-38 {
    border-radius: 38px;
  }

  .browser-overflow-hidden {
    overflow: hidden;
  }

  .browser-border-1-0 {
    border-top: 1px #e6e6e6 solid;
    border-bottom: 1px #e6e6e6 solid;
  }

  .browser-border-1-e2e2e2 {
    border: 1px solid #e2e2e2;
  }

  .browser-slider {
    position: absolute;
    bottom: 0;
    height: 2px;
    border-radius: 4px;
    background: #009673;
    transition: left 0.3s ease;
    width: 30px;
  }

  .browser-relative {
    position: relative;
  }

  .browser-circle-dummy {
    background-color: #e6e6e6;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  .browser-max-w-448 {
    max-width: 448px;
  }
  .browser-max-w-681 {
    max-width: 681px;
  }
  .browser-max-w-210 {
    max-width: 210px;
  }
  .browser-max-w-327 {
    max-width: 327px;
  }
  #browser-diamond {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: pink;
    position: relative;
    top: -50px;
  }

  #browser-diamond:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: pink;
  }

  .browser-green-box-1 {
    background-color: #00967331;
  }

  .browser-green-box-2 {
    background-color: #00967362;
  }

  .browser-green-box-3 {
    background-color: #0096739a;
  }

  .browser-green-box-4 {
    background-color: #009673e1;
  }

  .browser-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
  }

  .browser-dollor {
    background-color: gold;
    padding: 20px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
  }

  .broswer-flex-1 {
    flex: 1;
  }
  .gradient-1E9B6B-B0C7D4 {
    background: linear-gradient(#1e9b6b, #b0c7d4);
  }
  .browser-main-background {
    background: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.9)),
      url('./asset/images/main/background.png');
    display: flex;
    width: 100%;
    height: 960px;
    flex-direction: column;
    background-size: cover;
    background-position: center center;
  }

  .background-banner1 {
    background: url('./asset/images/main/banner1_web.png') no-repeat;
    background-size: cover;
    width: 290px;
    height: 290px;
    border-radius: 8px;
  }
  .background-banner2 {
    background:
      linear-gradient(to right, rgb(38, 22, 1), rgba(255, 255, 255, 0)),
      url('./asset/images/main/banner2_web.png') no-repeat;
    background-size: cover;
    width: 290px;
    height: 290px;
    border-radius: 8px;
  }
  .background-banner3 {
    background:
      linear-gradient(to right, rgba(16, 38, 1, 0.756), rgba(255, 255, 255, 0)),
      url('./asset/images/main/banner3_web.png') no-repeat;
    background-size: cover;
    width: 290px;
    height: 290px;
    border-radius: 8px;
  }
  .background-banner4 {
    background: url('./asset/images/report/banner.png') no-repeat;
    background-size: cover;
  }

  .browser-border-0d8-b {
    border-bottom: 0.8px solid #efefef;
  }

  .browser-main-color-box {
    border-radius: 20px;
    padding: 36px 36px 36px 36px;
  }

  .browser-circle {
    width: 103px;
    height: 103px;
    border-radius: 112px;
    background-color: #f2f5f5;
    border: 1px solid #eaeded;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px;
  }

  .browser-static {
    position: static;
  }

  .browser-square {
    min-width: 110px;
    min-height: 110px;
    border-radius: 20px;
    background-color: #f2f5f5;
    border: 1px solid #eaeded;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: nowrap;
  }

  .browser-facility-box {
    box-shadow: 0px 0px 5px #18181b6e;
    border-radius: 20px;
  }
  .browser-z-999 {
    z-index: 999;
  }
  .browser-facility-box-devide {
    width: 1px;
    height: 44px;
    background-color: #f2f2f2;
  }
  .browser-top-0 {
    top: 0px;
  }
  .browser-left-0 {
    left: 0px;
  }
  .browser-border-radius-0 {
    border-radius: 0px;
  }

  .browser-analyze-box {
    width: 100%;
    padding: 36px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 36px;
    align-items: center;
    box-shadow: 0px 2px 5px #18181b6e;
    background-color: #f0f8fa;
    cursor: pointer;
  }
  .browser-border-b-171E26 {
    border-bottom: 1px solid #171e2610;
  }
  .browser-house-big {
    width: 121px;
    height: 149px;
  }
  .browser-border-b-20-f7f8f9 {
    border-bottom: 20px solid #f7f8f9;
  }

  .browser-house {
    display: none;
  }

  .browser-box-shadow-0-2-10-00000020 {
    box-shadow: 0 2px 10px #00000020;
  }

  .browser-place-center {
    place-content: center;
  }

  .browser-top-auto {
    top: auto;
  }

  .browser-top-132 {
    top: 132px;
  }

  .browser-border-radius-38 {
    border-radius: 38px;
  }

  .browser-ticket-box {
    width: 100%;
    padding: 36px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    box-shadow: 0px 2px 5px #18181b6e;
    background-color: #e3f6eb;
  }

  .browser-border-t-1-f0f0f0 {
    border-top: 1px solid #f0f0f0;
  }
  .browser-border-t-2-171E26 {
    border-top: 2px solid #171e26;
  }

  .browser-pointer {
    cursor: pointer;
  }

  .browser-border-1-e6e6e6 {
    border: 1px solid #e6e6e6;
  }

  .browser-border-r-1-e2e2e2 {
    border-right: 1px solid #e2e2e2;
  }

  .browser-border-1-f0f0f0 {
    border: #f0f0f0 1px solid;
  }

  .browser-banner {
    width: 100%;
    padding: 64px;
    background-color: #f2f5f5;
    margin-bottom: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .browser-banner-inner {
    max-width: 1224px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .browser-introduce {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(0, 175, 135, 1) 50%,
      rgba(56, 239, 125, 1)
    );
    /* background-color: rgba(56, 239, 125, 1); */
    height: 293px;
    padding: 70px 0px 0px 0px;
  }

  .browser-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    height: 267px;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  .browser-mobile-footer {
    background-color: #fafafa;
    box-shadow: inset 0px 1px 0px #e2e2e2;
  }

  .browser-mobile-footer-line {
    width: 10px;
    max-width: 100%;
    height: 0;
    max-height: 100%;
    border-bottom: solid 1px #e2e2e2;
    margin-bottom: -1px;
    transform: matrix(0, 1, -1, 0, 0, 0);
  }

  .browser-main-icon {
    width: 74px;
    height: 74px;
  }

  /*font*/

  .browser-bold {
    font-family: SpoQaHanSansNeoBold;
  }

  .browser-regular {
    font-family: SpoQaHanSansNeoRegular;
  }

  .browser-medium {
    font-family: SpoqaHanSansNeoMedium;
  }

  .browser-alert-font {
    font-family: 'Pretendard-Bold';
    font-size: 20px;
  }
  .browser-alert-btn-font {
    font-family: 'Pretendard-Bold';
    font-size: 20px;
  }
  .to-top-btn {
    position: fixed;
    right: 30px;
    z-index: 10;
    width: 40px;
    cursor: pointer;
  }
  .to-top-btn img {
    width: 48px;
  }
  .side-nav-inner::-webkit-scrollbar {
    width: 0;
  }
  .auction-filter-tab-box,
  .hidden-policy-filter-tab-box {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .auction-filter-tab {
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    gap: 8px;
    overflow: auto;
    overflow-y: hidden;
  }
  .hidden-policy-filter-tab {
    overflow: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
  }
  .auction-filter-tab::-webkit-scrollbar {
    height: 10px;
  }
  .hidden-policy-filter-tab::-webkit-scrollbar {
    height: 10px;
  }
}
@media (min-width: 1920px) {
  .browser-1920-w-1200 {
    width: 1200px;
  }

  .browser-1920-gap-104 {
    gap: 104px;
  }

  .browser-1920-gap-80 {
    gap: 80px;
  }

  .browser-1920-w-440 {
    max-width: 440px;
  }

  .browser-1920-gap-32 {
    gap: 32px;
  }

  .browser-1920-h-320 {
    height: 320px;
  }
}
.joinbubble {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 20px;
  animation: joinbubble 0.5s linear infinite alternate;
}
.joinbubble div {
  height: 50px;
  width: 265px;
  border-radius: 100px;
}
.joinbubble::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -12px;
  content: '';
  display: block;
  border: 7px solid transparent;
  border-top: 14px solid #000;
  border-bottom: 0;
  transform: translateX(-50%);
}
@keyframes joinbubble {
  0% {
    top: 0;
  }
  100% {
    top: -4px;
  }
}
.free {
  color: #f8d857;
}

.page {
  height: 21cm;
  width: 29.7cm;
  overflow: hidden;
}

@page {
  size: A4 landscape;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    min-height: 21cm;
    width: 29.7cm;
  }

  .noprint {
    display: none;
  }
}

.break {
  page-break-after: always;
}
