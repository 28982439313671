.root {
  position: relative;
  width: 375px;
  height: 716px;
  align-items: center;
  overflow-y: auto;
}
.frame43151 {
  position: absolute;
  left: 20px;
  top: 109px;
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 96px;
}
.frame43150 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 42px;
}
.frame43149 {
  width: 65px;
  height: 65px;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
.frame42995 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.unnamed {
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.textBlock {
  color: #18181b;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  font-family:
    Spoqa Han Sans Neo,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif;
  letter-spacing: -0.04em;
}
.textBlock2 {
  color: #18181b;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  font-family:
    Spoqa Han Sans Neo,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif;
  letter-spacing: -0.04em;
}
.unnamed2 {
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.textBlock3 {
  color: #71717a;
  font-size: 15px;
  line-height: 23px;
  font-family:
    Spoqa Han Sans Neo,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif;
  letter-spacing: -0.02em;
}
.textBlock4 {
  margin-top: 0;
  color: #71717a;
  font-size: 15px;
  line-height: 23px;
  font-family:
    Spoqa Han Sans Neo,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif;
  letter-spacing: -0.02em;
}
.btn {
  width: 335px;
  height: 56px;
  place-content: center;
  align-items: center;
  padding: 17px 28px;
  border-radius: 13px;
  background-color: #00af87;
}
.unnamed3 {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  font-family:
    Spoqa Han Sans Neo,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame1237 {
  position: absolute;
  left: 0;
  top: 0;
  width: 375px;
  height: min-content;
  flex-direction: column;
  padding: 0 20px;
  background-color: #fff;
}
.top {
  height: 62px;
  align-self: stretch;
  place-content: flex-end;
  align-items: center;
}
.closeArea {
  width: min-content;
  height: min-content;
}
.close {
  width: 15px;
  height: 15px;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
