.inquiry-content-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inquiry-content-box textarea {
  height: 274px;
  padding: 16px;
}
.inquiry-content-box-title {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
  display: flex;
  justify-content: space-between;
}
.select-purpose-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.select-purpose-item {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.select-purpose-item span {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: rgba(23, 30, 38, 0.4);
}
.auto-fill-email {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.link-to-consulting-box {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #eaf8f2;
  border-radius: 8px;
}
.link-to-consulting-text {
  font-family: 'GmarketSansMedium';
  font-size: 13px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 2px;
}
.link-text-bold {
  font-family: 'GmarketSansBold';
  font-size: 16px;
  display: flex;
  align-items: center;
}
.select-purpose-item input[type='radio'] {
  display: none;
}
.fixed-btn {
  width: 100%;
  /* position: fixed !important; */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: white;
  padding: 16px;
  border-radius: 0 0 16px 16px;
}
.inquiry-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
}
.limit-2000-text {
  color: rgba(23, 30, 38, 0.4);
}
.inquiry-content-descript {
  font-family: Pretendard-Bold;
  font-size: 12px;
  line-height: 20px;
  color: red;
}
.inquiry-content-descript span {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 20px;
  color: black;
}
.inquiry-content-descript ul li {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 20px;
  color: black;
}
.custom-list {
  list-style-type: none; /* 기본 불릿 제거 */
  padding-left: 0; /* 왼쪽 여백 제거 */
}

.custom-list li::before {
  content: '- '; /* 각 항목 앞에 대시 추가 */
  margin-right: 5px; /* 대시와 텍스트 사이의 간격 */
}
