.land-use-report-block {
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 16px solid #f7f8f9;
}
.land-use-report-block-title {
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: #222;
}
.level-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.level-box-text {
  width: 141px;
  height: 160px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.level-box-text span {
  z-index: 3;
}
.land-use-level {
  font-family: Pretendard-Bold;
  font-size: 32px;
  line-height: 145%;
  letter-spacing: -0.32px;
  color: #fff;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.chart-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-box-inner {
  width: 320px;
  height: 300px;
}
.land-use-gray-box {
  width: 100%;
  padding: 32px 16px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow:
    0px 2px 8px 0px rgba(87, 87, 90, 0.08),
    0px 1px 4px 0px rgba(87, 87, 90, 0.08),
    0px 0px 1px 0px rgba(87, 87, 90, 0.08);
}
.land-use-gray-box-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}
.land-use-level-box {
  width: 148px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.land-use-gray-box-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.land-use-gray-box-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222;
  gap: 8px;
}
.level-color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #000;
}
.level-color-text {
  font-family: Pretendard-Medium;
  font-size: 14px;
}
.land-use-tab-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
}
.land-use-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #70737c;
  cursor: pointer;
  border-radius: 8px;
}
.land-use-tab-active {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #1e9b6b;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #1e9b6b;
}
.land-use-tab-point {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222;
}
.land-use-tab-point span {
  color: #1e9b6b;
  margin-left: 4px;
}
.land-use-prograssbar-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.land-use-prograssbar-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
}
.land-use-prograssbar-title {
  width: 52px;
}
.land-use-prograssbar {
  flex: 1;
  height: 8px;
  border-radius: 999px;
  background-color: rgba(112, 115, 124, 0.16);
  position: relative;
}
.land-use-prograssbar-per {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 999px;
  background-color: #1e9b6b;
}
.land-use-prograssbar-desc {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(112, 115, 124, 0.05);
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
  text-align: center;
  line-height: 20px;
}
.land-use-prograssbar-desc > span {
  color: #1e9b6b;
}

.nearby-land-example {
  width: 100%;
}
.nearby-land-example-title-box {
  width: 100%;
  padding: 32px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: #222;
}
.nearby-land-example-tab-box {
  width: 100%;
  overflow: hidden;
}
.nearby-land-example-tab-box-inner {
  display: flex;
  flex-direction: row;
  gap: 6px;
  overflow-x: auto;
}

.nearby-land-tab-item {
  height: 32px;
  padding: 0 16px;
  border-radius: 999px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #989ba2;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}
.nearby-land-tab-item-active {
  height: 32px;
  padding: 0 16px;
  border-radius: 999px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  background-color: #222;
  white-space: nowrap;
  cursor: pointer;
}
.nearby-land-example-totalcount {
  width: 100%;
  padding: 12px 16px;
  background-color: #f7f8f9;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
  border-top: 1px solid rgba(112, 115, 124, 0.16);
}
.nearby-land-example-totalcount-text {
  font-family: Pretendard-Bold;
  margin-left: 2px;
}
.nearby-land-example-list-box {
  width: 100%;
  padding: 16px 24px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 345px;
  overflow-y: auto;
}
.nearby-land-example-list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(112, 115, 124, 0.16);
}
.nearby-land-example-list-item-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 12px;
  color: #222;
}
.nearby-land-example-list-item-top-left {
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: rgba(55, 56, 60, 0.61);
}
.nearby-land-example-list-item-middle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 2px;
}
.nearby-land-example-list-item-middle-text {
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.nearby-land-item-doro {
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #222;
}
.nearby-land-example-list-item-middle > img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
}
.nearby-land-example-list-item-bottom {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.nearby-land-example-list-item-bottom > span {
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: rgba(55, 56, 60, 0.61);
  border: 1px solid #e1e2e4;
  padding: 2px 6px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data-box {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
}
