.signup-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 100;
}
.signup-inner-box {
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 0 16px;
  margin: 80px 0 138px 0;
  gap: 48px;
  z-index: 10;
}
.signup-inner-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.signup-inner-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.signup-inner-contents a {
  text-decoration: none;
}
.signup-inner-contents a img {
  height: 35px;
}
.comment-slider {
  display: flex;
  flex-direction: row;
}
.recommend-comment {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0 16px 8px;
}
.recommend-text {
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: rgba(23, 30, 38, 0.78);
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
}
.recommend-writer {
  width: 100%;
  font-family: Pretendard-Regular;
  font-size: 12px;
  color: rgba(23, 30, 38, 0.56);
  line-height: 16px;
  text-align: right;
}
.signup-btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.signup-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: none;
  padding: 16px 20px;
  border-radius: 12px;
  height: 56px;
  font-size: 16px;
  font-family: Pretendard-Medium;
}
.signup-btn-k {
  background-color: #fde40b;
}
.signup-btn-n {
  background-color: #00c300;
  color: #fff;
}
.signup-btn-f {
  background-color: #1877f2;
  color: #fff;
}
.signup-btn-g {
  background-color: #ffffff;
  border: 1px solid #e8e9ea;
  color: #171e26;
}
.signup-btn-e {
  background-color: #eaf8f2;
  text-decoration: none;
  color: #1e9b6b;
}
.sns-signup-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}
.sns-signup-inner-box,
.email-signup-inner-box {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 16px;
  width: 360px;
  gap: 32px;
  margin-top: 24px;
  z-index: 10;
}
.sns-signup-title,
.email-signup-title {
  width: 100%;
  text-align: start;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 34px;
  color: #000;
}
.sns-input-box,
.email-input-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #171e2610;
  padding-bottom: 32px;
}
.sns-input,
.email-input {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 16px;
  background-color: #fafafa;
  border-radius: 13px;
  width: 100%;
  gap: 2px;
}
.sns-input-title,
.email-input-title {
  text-align: start;
  line-height: 18px;
  font-size: 12px;
  font-family: Pretendard-Regular;
  color: #888b90;
}
.sns-input-text {
  text-align: start;
  line-height: 26px;
  font-size: 17px;
  font-family: Pretendard-Regular;
  color: #888b90;
}
.sns-tel-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sns-tel-box p {
  color: red;
  font-size: 14px;
  text-align: start;
  padding: 0 16px;
}
.warning-font {
  color: red;
  font-size: 14px;
  text-align: start;
}
.signup-tems {
  width: 100%;
}
.sns-signup-btn {
  width: 100%;
  padding: 16px 20px 48px 20px;
  border-top: 1px solid #171e2610;
}
.email-signup-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 100;
}
.email-input-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.email-input-category-text {
  border: none;
  background-color: #fafafa;
  font-size: 17px;
  line-height: 26px;
  padding-left: 10px;
}
.signup-input-img {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .signup-wrap,
  .email-signup-wrap {
    justify-content: space-between;
  }
  .signup-inner-box {
    width: 468px;
    padding: 0 20px;
  }
  .sns-signup-inner-box,
  .email-signup-inner-box {
    width: 468px;
    padding: 0 20px;
    margin-top: 80px;
  }
  .sns-signup-title,
  .email-signup-title {
    font-family: Pretendard-Bold;
    font-size: 26px;
  }
}
