.new-main-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.new-main-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f0f0f0;
}
