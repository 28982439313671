:where(.storybrainResets),
:where(.storybrainResets) * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  flex-shrink: 0;
  text-decoration: none;
  min-width: 0;
  overflow-wrap: anywhere;
}

:where(.storybrainResets),
:where(.storybrainResets) :not(span, a, ul, ol, li, p) {
  display: flex;
}

:where(.storybrainResets) ul,
:where(.storybrainResets) ol {
  text-indent: 0;
  padding-inline-start: 1.5em;
}

:where(.storybrainResets) p:empty::before {
  content: '';
  display: inline-block;
}

:where(.storybrainResets) img {
  object-fit: contain;
}

:where(.storybrainResets) svg {
  overflow: visible;
}
