.padding-bottom-71 {
  padding-bottom: 71px;
}

.padding-16-16-8 {
  padding: 16px 16px 8px;
}

.padding-5-16 {
  padding: 7px 16px 8px;
}
.padding-t-64 {
  padding-top: 64px;
}
.padding-12-0 {
  padding: 12px 0px;
}
.padding-2-11 {
  padding: 2px 11px;
}
.padding-0-16-48-16 {
  padding: 0 16px 48px 16px;
}

.padding-0-0-48-0 {
  padding: 0 0px 48px 0px;
}
.padding-0-16-24-16 {
  padding: 0 16px 24px 16px;
}
.padding-42-16-12-16 {
  padding: 42px 16px 12px 16px;
}
.padding-16-0-48-0 {
  padding: 16px 0 48px 0;
}
.padding-42-16 {
  padding: 42px 16px;
}
.padding-16-16-16-70 {
  padding: 16px 16px 70px 16px;
}
.padding-0-16-16 {
  padding: 0 16px 16px;
}
.padding-15-25 {
  padding: 15px 25px;
}

.padding-0-16-24 {
  padding: 0 16px 24px;
}

.padding-8-24 {
  padding: 8px 24px;
}
.padding-56-16 {
  padding: 56px 16px;
}

.padding-y-8 {
  padding: 8px 0;
}
.padding-y-32 {
  padding: 32px 0;
}

.padding-24-0-6 {
  padding: 24px 0 6px;
}

.padding-5-5 {
  padding: 5px 5px;
}

.padding-12-20-0 {
  padding: 12px 20px 0;
}

.padding-14-18 {
  padding: 14px 18px;
}

.padding-14-20-24 {
  padding: 14px 20px 24px;
}

.padding-24-0-40 {
  padding: 24px 0 40px;
}
.padding-24-8-56 {
  padding: 24px 8px 56px;
}

.padding-24-46 {
  padding: 24px 46px;
}

.padding-24-22 {
  padding: 24px 22px;
}

.padding-12-34 {
  padding: 12px 34px;
}

.padding-24-20-28 {
  padding: 24px 20px 28px;
}

.padding-24-20-250 {
  padding: 24px 20px 250px;
}

.padding-5-8 {
  padding: 5px 8px;
}

.padding-28-24 {
  padding: 28px 24px;
}

.padding-24 {
  padding: 24px;
}

.padding-0-5 {
  padding: 0 5px;
}
.padding-16-60 {
  padding: 16px 60px;
}
.padding-x-18 {
  padding: 0 18px;
}
.padding-x-54 {
  padding: 0px 54px;
}

.padding-22-12 {
  padding: 22px 12px;
}
.padding-20 {
  padding: 20px;
}
.padding-20-12 {
  padding: 20px 12px;
}
.padding-10-10-0-10 {
  padding: 10px 10px 0px 10px;
}
.padding-x-20 {
  padding: 0px 20px;
}
.padding-x-60 {
  padding: 0px 60px;
}

.padding-24-0-32 {
  padding: 24px 0 32px;
}
.padding-10-30 {
  padding: 10px 30px;
}
.padding-8-16 {
  padding: 8px 16px;
}
.padding-8-46 {
  padding: 8px 46px;
}
.padding-15-46 {
  padding: 15px 46px;
}
.padding-130-16 {
  padding: 130px 16px;
}
.padding-8-16-0 {
  padding: 8px 16px 0;
}
.padding-8-0-16 {
  padding: 8px 0 16px;
}
.padding-8-16-12 {
  padding: 8px 16px 12px;
}
.padding-8-16-24 {
  padding: 8px 16px 24px;
}
.padding-11 {
  padding: 11px;
}
.padding-24-24-32 {
  padding: 24px 24px 32px;
}
.paddign-6-12 {
  padding: 6px 12px;
}
.padding-20-16-40-16 {
  padding: 20px 16px 40px 16px;
}
.padding-20-20-40-20 {
  padding: 20px 20px 40px 20px;
}
.padding-32-24-17-24 {
  padding: 32px 24px 17px 24px;
}
.padding-12 {
  padding: 12px;
}
.padding-12-12-12-8 {
  padding: 12px 12px 12px 8px;
}
.padding-12-12-12-16 {
  padding: 12px 12px 12px 16px;
}
.padding-7-12 {
  padding: 7px 12px;
}
.padding-61-24-0-24 {
  padding: 61px 24px 0px 24px;
}
.padding-32-16-0-16 {
  padding: 32px 16px 0px 16px;
}
.padding-32-16-100-16 {
  padding: 32px 16px 100px 16px;
}
.padding-b-13 {
  padding-bottom: 13px;
}
.padding-x-22 {
  padding: 0 22px;
}
.padding-x-24 {
  padding: 0 24px;
}
.padding-0-3 {
  padding: 3px 0;
}
.padding-y-5 {
  padding: 5px 0;
}
.padding-y-112 {
  padding: 112px 0;
}

.padding-y-56 {
  padding: 56px 0;
}
.padding-24-32 {
  padding: 24px 32px;
}
.padding-0-important {
  padding: 0 !important;
}
.padding-y-9 {
  padding: 9px 0;
}

.padding-y-10 {
  padding: 10px 0;
}

.padding-y-17 {
  padding: 17px 0;
}
.padding-39-0-40-16 {
  padding: 39px 0px 40px 16px;
}
.padding-y-20 {
  padding: 20px 0;
}

.padding-40-16 {
  padding: 40px 16px;
}

.padding-32-16 {
  padding: 32px 16px;
}

.padding-7-16 {
  padding: 7px 16px;
}
.padding-y-6 {
  padding: 6px 0;
}
.padding-6-16 {
  padding: 6px 16px;
}
.padding-9-16 {
  padding: 9px 16px;
}
.padding-24-16-76-16 {
  padding: 24px 16px 76px 16px;
}
.padding-8 {
  padding: 8px;
}
.padding-8-10 {
  padding: 8px 10px;
}
.padding-8-13 {
  padding: 8px 13px;
}

.padding-16-12 {
  padding: 16px 12px;
}

.padding-32-16-22 {
  padding: 32px 16px 22px;
}
.padding-16-16 {
  padding: 16px;
}

.padding-16 {
  padding: 16px;
}

.padding-16-16-10 {
  padding: 16px 16px 10px;
}

.padding-16-16-0 {
  padding: 16px 16px 0px;
}

.padding-8-16-16 {
  padding: 8px 16px 16px;
}

.padding-y-22 {
  padding: 22px 0;
}

.padding-y-24 {
  padding: 24px 0;
}

.padding-0-20 {
  padding: 0px 20px;
}
.padding-4-6 {
  padding: 4px 6px;
}

.padding-4-12 {
  padding: 4px 12px;
}

.padding-12-14 {
  padding: 12px 14px;
}
.padding-12-4 {
  padding: 12px 4px;
}
.padding-x-40 {
  padding: 0px 40px;
}
.padding-32-12 {
  padding: 32px 12px;
}
.padding-32 {
  padding: 32px;
}
.padding-16-24 {
  padding: 16px 24px;
}
.padding-x-16 {
  padding: 0px 16px;
}
.padding-30-20 {
  padding: 30px 20px;
}

.padding-14-20 {
  padding: 14px 20px;
}
.padding-20-24 {
  padding: 20px 24px;
}

.padding-x-16 {
  padding: 0 16px;
}
.padding-x-32 {
  padding: 0 32px;
}

.padding-18-8 {
  padding: 18px 8px;
}
.padding-18-16 {
  padding: 18px 16px;
}
.padding-5-10 {
  padding: 5px 10px;
}
.padding-0-80 {
  padding: 0px 80px;
}
.padding-x-8 {
  padding: 0 8px;
}

.padding-16-32 {
  padding: 16px 32px;
}
.padding-16-8 {
  padding: 16px 8px;
}
.padding-10-20 {
  padding: 10px 20px;
}

.padding-39-0-40-16 {
  padding: 39px 0 40px 16px;
}
.padding-32-24-80-24 {
  padding: 32px 24px 80px 24px;
}
.padding-32-40-80 {
  padding: 32px 40px 80px;
}
.padding-16-16-48-16 {
  padding: 16px 16px 48px 16px;
}
.padding-16-16-16-12 {
  padding: 16px 16px 16px 12px;
}
.padding-y-14 {
  padding: 14px 0;
}
.padding-y-16-4 {
  padding: 16px 0 4px 0;
}
.padding-10-16 {
  padding: 10px 16px;
}
.padding-24-16-0 {
  padding: 24px 16px 0;
}
.padding-4-8 {
  padding: 4px 8px;
}
.padding-12-24 {
  padding: 12px 24px;
}
.padding-0-10 {
  padding: 0px 10px;
}
.padding-y-13-20 {
  padding: 13px 0 20px 0;
}
.padding-10-0 {
  padding: 10px 0px;
}
.padding-20-0 {
  padding: 20px 0px;
}
.padding-20-20 {
  padding: 20px 20px;
}
.padding-2-16 {
  padding: 2px 16px;
}
.padding-20-16 {
  padding: 20px 16px;
}

.padding-12-14 {
  padding: 12px 14px;
}

.padding-x-10 {
  padding: 0 10px;
}

.padding-14-5 {
  padding: 14px 5px;
}
.padding-4-0 {
  padding: 4px 0px;
}
.padding-4-18 {
  padding: 4px 18px;
}
.padding-0-8 {
  padding: 0px 8px;
}
.padding-6 {
  padding: 6px;
}
.padding-6-8 {
  padding: 6px 8px;
}

.padding-33-16-33-24 {
  padding: 33px 16px 33px 24px;
}
.padding-32-16-100-16 {
  padding: 32px 16px 100px 16px;
}

.padding-0-16-32 {
  padding: 0 16px 32px;
}

.padding-24-16 {
  padding: 24px 16px;
}

.padding-24-16-8 {
  padding: 24px 16px 8px;
}
.padding-24-16-4 {
  padding: 24px 16px 4px;
}
.padding-2-8 {
  padding: 2px 8px;
}

.padding-6-10 {
  padding: 6px 10px;
}

.padding-6-14 {
  padding: 6px 14px;
}

.padding-x-14 {
  padding: 0 14px;
}
.padding-r-10 {
  padding-right: 10px;
}
.padding-6-0 {
  padding: 6px 0px;
}
.padding-t-24 {
  padding-top: 24px;
}
.padding-12-23 {
  padding: 12px 23px;
}
.padding-16-16-8-16 {
  padding: 16px 16px 8px 16px;
}
.padding-0-16-8-16 {
  padding: 0px 16px 8px 16px;
}
.padding-8-16-16-16 {
  padding: 8px 16px 16px 16px;
}
.padding-y-12 {
  padding: 12px 0;
}
.padding-24-36 {
  padding: 24px 36px;
}
.padding-12-16 {
  padding: 12px 16px;
}
.padding-24-16-8-16 {
  padding: 24px 16px 8px 16px;
}
.padding-12-20 {
  padding: 12px 20px;
}
.padding-x-4 {
  padding: 0px 4px;
}
.padding-4 {
  padding: 4px;
}
.padding-y-4 {
  padding: 4px 0px;
}

.padding-y-16 {
  padding: 16px 0;
}

.padding-4-16 {
  padding: 4px 16px;
}
.padding-24-24-48-24 {
  padding: 24px 24px 48px 24px;
}
.padding-14-16 {
  padding: 14px 16px;
}
.padding-14 {
  padding: 14px;
}
.padding-8-14 {
  padding: 8px 14px;
}
.padding-8-20-79 {
  padding: 8px 20px 79px;
}

.padding-8-20-29 {
  padding: 8px 20px 29px;
}

.padding-8-20-0 {
  padding: 8px 20px 0;
}

.padding-16-20-0 {
  padding: 16px 20px 0;
}

.padding-20-20-0 {
  padding: 20px 20px 0;
}
.padding-14-24 {
  padding: 14px 24px;
}

.padding-16-16-24 {
  padding: 16px 16px 24px;
}
.padding-22-16 {
  padding: 22px 16px;
}
.padding-22-16-0 {
  padding: 22px 16px 0;
}
.padding-10 {
  padding: 10px;
}
.padding-6-8 {
  padding: 6px 8px;
}
.padding-14-16 {
  padding: 14px 16px;
}
.padding-16-16-16-30 {
  padding: 16px 16px 16px 30px;
}
.padding-32-16-60-16 {
  padding: 32px 16px 60px 16px;
}
.padding-4-10 {
  padding: 4px 10px;
}
.padding-24-24-16-24 {
  padding: 24px 24px 16px 24px;
}
.padding-5-15 {
  padding: 5px 15px;
}
.padding-0-16-80 {
  padding: 0 16px 80px;
}

.padding-8-20-14 {
  padding: 8px 20px 14px;
}

.padding-12-20-12 {
  padding: 12px 20px 12px;
}

.padding-14-18-14 {
  padding: 14px 18px 14px;
}
.padding-5-10-10-10 {
  padding: 5px 10px 10px 10px;
}

.padding-48-16-32-16 {
  padding: 48px 16px 32px 16px;
}
.padding-8-12 {
  padding: 8px 12px;
}
.padding-15-12 {
  padding: 15px 12px;
}
.padding-y-2 {
  padding: 2px 0;
}
.padding-16-16-62 {
  padding: 16px 16px 62px;
}
.padding-16-16-48 {
  padding: 16px 16px 48px;
}
.padding-8-16-48 {
  padding: 8px 16px 48px;
}
.padding-y-13 {
  padding: 13px 0;
}
.padding-15-20-21 {
  padding: 15px 20px 21px;
}
.padding-24-24-0-24 {
  padding: 24px 24px 0px 24px;
}
.padding-32-16-16-16 {
  padding: 32px 16px 16px 16px;
}
.padding-4-5 {
  padding: 4px 5px;
}
.padding-2 {
  padding: 2px;
}
.padding-2-4 {
  padding: 2px 4px;
}
.padding-20-24-79 {
  padding: 20px 24px 79px;
}
.padding-l-10 {
  padding-left: 10px;
}

.padding-l-15 {
  padding-left: 15px;
}
.padding-30 {
  padding: 30px;
}

.padding-l-34 {
  padding-left: 34px;
}
.padding-l-48 {
  padding-left: 48px;
}
.padding-r-10 {
  padding-right: 10px;
}
.padding-r-48 {
  padding-right: 48px;
}
.padding-b-14 {
  padding-bottom: 14px;
}
.padding-b-15 {
  padding-bottom: 15px;
}
.padding-b-16 {
  padding-bottom: 16px;
}
.padding-b-24 {
  padding-bottom: 24px;
}
.padding-b-32 {
  padding-bottom: 32px;
}
.padding-b-40 {
  padding-bottom: 40px;
}
.padding-56-16 {
  padding: 56px 16px;
}
.padding-b-4 {
  padding-bottom: 4px;
}
.padding-12-8 {
  padding: 12px 8px;
}
.padding-b-50 {
  padding-bottom: 50px;
}
.padding-b-60 {
  padding-bottom: 60px;
}
.padding-b-100 {
  padding-bottom: 100px;
}
.padding-b-180 {
  padding-bottom: 180px;
}
.padding-24-16-48-16 {
  padding: 24px 16px 48px 16px;
}
.padding-6-16 {
  padding: 6px 16px;
}
.padding-32-16-24-16 {
  padding: 32px 16px 24px 16px;
}
.padding-b-10 {
  padding-bottom: 10px;
}
.padding-8-40 {
  padding: 8px 40px;
}
.padding-b-8 {
  padding-bottom: 8px;
}
.padding-b-20 {
  padding-bottom: 20px;
}
.padding-24-16-0-16 {
  padding: 24px 16px 0px 16px;
}
.padding-6-4 {
  padding: 6px 4px;
}
.padding-13-16 {
  padding: 13px 16px;
}
.padding-t-20 {
  padding-top: 20px;
}
.padding-t-10 {
  padding-top: 10px;
}
.padding-t-16 {
  padding-top: 16px;
}
.padding-24-16-16-16 {
  padding: 24px 16px 16px 16px;
}
.padding-24-16-32-16 {
  padding: 24px 16px 32px 16px;
}
.padding-16-18 {
  padding: 16px 18px;
}
.padding-16-20 {
  padding: 16px 20px;
}
.padding-16-84 {
  padding: 16px 84px;
}
.padding-14-40 {
  padding: 14px 40px;
}
.padding-x-25 {
  padding: 0 25px;
}
.padding-42-16-32-16 {
  padding: 42px 16px 32px 16px;
}
.padding-t-40 {
  padding-top: 40px;
}
.padding-t-48 {
  padding-top: 48px;
}
.padding-t-50 {
  padding-top: 50px;
}
.padding-t-72 {
  padding-top: 72px;
}
.padding-t-80 {
  padding-top: 80px;
}
.padding-b-12 {
  padding-bottom: 12px;
}
.padding-b-25 {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .tablet-padding-32-40 {
    padding: 32px 40px;
  }
  .tablet-padding-12-40 {
    padding: 12px 40px;
  }
  .tablet-padding-24-40-48-40 {
    padding: 24px 40px 48px 40px;
  }
  .tablet-padding-32-40-24-40 {
    padding: 32px 40px 24px 40px;
  }
  .tablet-padding-32-40-24-40 {
    padding: 32px 40px 24px 40px;
  }
  .tablet-padding-10-40 {
    padding: 10px 40px;
  }
  .tablet-padding-8-40 {
    padding: 8px 40px;
  }
  .tablet-padding-x-40 {
    padding: 0 40px;
  }
  .tablet-padding-240-40 {
    padding: 240px 40px;
  }
  .tablet-padding-56-40 {
    padding: 56px 40px;
  }
}

@media (min-width: 1024px) {
  .w-1024-padding-t-42 {
    padding-top: 42px;
  }
  .w-1024-padding-176-60 {
    padding: 176px 60px;
  }
  .w-1024-padding-176-360 {
    padding: 176px 360px;
  }
  .w-1024-padding-t-80 {
    padding-top: 80px;
  }
  .w-1024-padding-80-60 {
    padding: 80px 60px;
  }
  .w-1024-padding-60-80 {
    padding: 60px 80px;
  }
  .w-1024-padding-32-16 {
    padding: 32px 16px;
  }
  .w-1024-padding-16-40 {
    padding: 16px 40px;
  }
  .w-1024-padding-13-40 {
    padding: 13px 40px;
  }
  .w-1024-padding-0 {
    padding: 0;
  }
  .w-1024-padding-14-40 {
    padding: 14px 40px;
  }
  .w-1024-padding-24-40 {
    padding: 24px 40px;
  }
  .w-1024-padding-16-84 {
    padding: 16px 84px;
  }
  .w-1024-padding-16-40-48-40 {
    padding: 16px 40px 48px 40px;
  }
  .w-1024-padding-0-40-16-40 {
    padding: 0 40px 16px 40px;
  }
  .w-1024-16-40-48-40 {
    padding: 16px 40px 48px 40px;
  }
  .w-1024-0-40-24-40 {
    padding: 0px 40px 24px 40px;
  }
  .w-1024-16-40-48-40 {
    padding: 16px 40px 48px 40px;
  }
  .w-1024-padding-x-40 {
    padding: 0px 40px;
  }
  .browser-padding-0 {
    padding: 0;
  }
  .browser-1024-padding-32-60-80-60 {
    padding: 32px 60px 80px 60px;
  }
  .browser-1024-padding-80-128-0-128 {
    padding: 80px 128px 0px 128px;
  }
  .browser-padding-80-60-0-60 {
    padding: 80px 60px 0 60px;
  }
  .browser-padding-t-48 {
    padding-top: 48px;
  }
  .browser-padding-b-71 {
    padding-bottom: 71px;
  }
  .browser-padding-b-80 {
    padding-bottom: 80px;
  }
  .browser-padding-x-60 {
    padding: 0 60px;
  }
  .browser-padding-b-10 {
    padding-bottom: 10px;
  }
  .browser-padding-12-20-0 {
    padding: 12px 20px 0;
  }

  .browser-padding-14-18 {
    padding: 14px 18px;
  }

  .browser-padding-14-20-24 {
    padding: 14px 20px 24px;
  }

  .browser-padding-24-0-40 {
    padding: 24px 0 40px;
  }

  .browser-padding-24-20-28 {
    padding: 24px 20px 28px;
  }

  .browser-padding-24-20-250 {
    padding: 24px 20px 250px;
  }

  .browser-padding-5-8 {
    padding: 5px 8px;
  }
  .browser-padding-0-80 {
    padding: 0px 80px;
  }
  .browser-padding-28-24 {
    padding: 28px 24px;
  }

  .browser-padding-24 {
    padding: 24px;
  }

  .browser-padding-0-5 {
    padding: 0 5px;
  }

  .browser-padding-x-18 {
    padding: 0 18px;
  }

  .browser-padding-x-20 {
    padding: 0 20px;
  }

  .browser-padding-x-24 {
    padding: 0 24px;
  }

  .browser-padding-y-5 {
    padding: 5px 0;
  }

  .browser-padding-y-9 {
    padding: 9px 0;
  }

  .browser-padding-y-80 {
    padding: 80px 0;
  }

  .browser-padding-y-10 {
    padding: 10px 0;
  }

  .browser-padding-y-17 {
    padding: 17px 0;
  }

  .browser-padding-y-20 {
    padding: 20px 0;
  }

  .browser-padding-y-22 {
    padding: 22px 0;
  }

  .browser-padding-y-24 {
    padding: 24px 0;
  }

  .browser-padding-4-6 {
    padding: 4px 6px;
  }
  .browser-padding-80-60 {
    padding: 80px 60px;
  }
  .browser-padding-4-12 {
    padding: 4px 12px;
  }
  .browser-padding-t-60 {
    padding-top: 60px;
  }
  .browser-padding-12-14 {
    padding: 12px 14px;
  }

  .browser-padding-32-12 {
    padding: 32px 12px;
  }

  .browser-padding-30-20 {
    padding: 30px 20px;
  }

  .browser-padding-14-20 {
    padding: 14px 20px;
  }

  .browser-padding-12-14 {
    padding: 12px 14px;
  }
  .browser-padding-16-16-48-16 {
    padding: 16px 16px 48px 16px;
  }
  .browser-padding-x-10 {
    padding: 0 10px;
  }

  .browser-padding-14-5 {
    padding: 14px 5px;
  }

  .browser-padding-6 {
    padding: 6px;
  }

  .browser-padding-x-14 {
    padding: 0 14px;
  }

  .browser-padding-y-12 {
    padding: 12px 0;
  }

  .browser-padding-12-20 {
    padding: 12px 20px;
  }
  .browser-padding-60-16-60-16 {
    padding: 60px 16px 60px 16px;
  }
  .browser-padding-y-16 {
    padding: 16px 0;
  }
  .browser-padding-39-0-0-16 {
    padding: 39px 0px 0px 16px;
  }

  .browser-padding-8-20-79 {
    padding: 8px 20px 79px;
  }
  .browser-padding-b-20 {
    padding-bottom: 20px;
  }
  .browser-padding-8-20-14 {
    padding: 8px 20px 14px;
  }
  .browser-padding-x-16 {
    padding: 0px 16px;
  }
  .browser-padding-12-20-12 {
    padding: 12px 20px 12px;
  }

  .browser-padding-14-18-14 {
    padding: 14px 18px 14px;
  }
  .browser-padding-b-48 {
    padding-bottom: 48px;
  }

  .browser-padding-15-20-21 {
    padding: 15px 20px 21px;
  }

  .browser-padding-20-24-79 {
    padding: 20px 24px 79px;
  }
  .browser-padding-30 {
    padding: 30px;
  }
  .browser-padding-l-15 {
    padding-left: 15px;
  }

  .browser-padding-l-34 {
    padding-left: 34px;
  }
  .browser-padding-l-48 {
    padding-left: 48px;
  }
  .browser-padding-b-32 {
    padding-bottom: 32px;
  }
  .browser-padding-b-15 {
    padding-bottom: 15px;
  }
  .browser-padding-b-180 {
    padding-bottom: 180px;
  }
  .browser-padding-b-120 {
    padding-bottom: 120px;
  }
  .browser-padding-t-72 {
    padding-top: 72px;
  }
  .browser-padding-t-80 {
    padding-top: 80px;
  }
  .browser-padding-t-40 {
    padding-top: 40px;
  }
  .browser-padding-80-16 {
    padding: 80px 16px;
  }
  .browser-padding-80-60 {
    padding: 80px 60px;
  }
}
@media (min-width: 1920px) {
  .browser-padding-y-176 {
    padding: 176px 0px;
  }
  .w-1920-padding-80-0 {
    padding: 80px 0px;
  }
  .browser-1920-padding-0 {
    padding: 0;
  }
  .w-1920-padding-176-360 {
    padding: 176px 360px;
  }
}
