.bg-white {
  background-color: white;
}
.bg-white-primary-100 {
  background-color: #eaf8f2;
}
.bg-00000050 {
  background-color: #00000050;
}
.bg-EAF3F8 {
  background-color: #eaf3f8;
}
.bg-18181b10 {
  background-color: #18181b10;
}
.bg-DCF4E9 {
  background-color: #dcf4e9;
}
.bg-EA6A6A {
  background-color: #ea6a6a;
}
.bg-FBEDEB {
  background-color: #fbedeb;
}
.bg-F2F4FA {
  background-color: #f2f4fa;
}
.bg-increase {
  background-color: #ec4f4f;
}
.bg-decrease {
  background-color: #4b47fc;
}
.bg-00AF87 {
  background-color: #00af87;
}

.bg-f2f2f2 {
  background-color: #f2f2f2;
}

.bg-F7F8F9 {
  background-color: #f7f8f9;
}

.bg-E3F6EB {
  background-color: #e3f6eb;
}
.bg-E5EAF0 {
  background-color: rgba(229, 234, 240, 1);
}
.bg-967D56 {
  background-color: #967d56;
}
.bg-FFFFFF {
  background-color: #ffffff;
}
.bg-FFF6F6 {
  background-color: #fff6f6;
}
.bg-F6F6F6 {
  background-color: #f6f6f6;
}
.bg-F1F1FE {
  background-color: #f1f1fe;
}
.bg-F6F6F6 {
  background-color: #f6f6f6;
}
.bg-F1F4F7 {
  background-color: #f1f4f7;
}
.bg-E47373 {
  background-color: #e47373;
}
.bg-F0F0F0 {
  background-color: #f0f0f0;
}
.bg-5377A1 {
  background-color: #5377a1;
}
.bg-3EB1D6 {
  background-color: #3eb1d6;
}
.bg-F8B566 {
  background-color: #f8b566;
}
.bg-82669D {
  background-color: #82669d;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-000000 {
  background-color: #000000;
}
.bg-0CA96D {
  background-color: #0ca96d;
}

.bg-A0A0A0 {
  background-color: #a0a0a0;
}
.bg-EDEDEF {
  background-color: #ededef;
}
.bg-FAFAFA {
  background-color: rgba(250, 250, 250, 1);
}

.bg-DEF8EB {
  background-color: #def8eb;
}
.bg-FFFFFF10 {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-F2F3F6 {
  background-color: #f2f3f6;
}
.bg-EFF2F5 {
  background-color: #eff2f5;
}
.bg-DDEFE9 {
  background-color: #ddefe9;
}
.bg-DDF4FF {
  background-color: #ddf4ff;
}
.bg-66666690 {
  background-color: #66666690;
}
.bg-E4F1EB {
  background-color: #e4f1eb;
}
.bg-notice {
  background-color: #e78b3e;
}
.bg-D8E1ED {
  background-color: #d8e1ed;
}
.bg-caution {
  background-color: #d84836;
}
.bg-1E986B {
  background-color: #1e986b;
}
.bg-disabled {
  background-color: rgba(23, 30, 38, 0.1);
}
.bg-171E2610 {
  background-color: #171e2610;
}
.bg-171E26 {
  background-color: #171e26;
}
.bg-EDF6FF {
  background-color: #edf6ff;
}
.bg-FFE9E9 {
  background-color: #ffe9e9;
}
.bg-FFFAE9 {
  background-color: #fffae9;
}
/*new*/
.bg-primary {
  background-color: #1e9b6b;
}
.bg-primary-100 {
  background-color: #eaf8f2;
}
.bg-primary-300 {
  background-color: #09ce7d;
}
.bg-primary-200 {
  background-color: #ddefe9;
}

.bg-primary-400 {
  background-color: #09ce7d;
}

.bg-primary-500 {
  background-color: #1e9b6b;
}

.bg-primary-600 {
  background-color: #166f4d;
}

.bg-primary-700 {
  background-color: #0d442f;
}
.bg-primary-800 {
  background-color: #0d442f;
}

.bg-secondary-400 {
  background-color: #0965ce;
}

.bg-secondary-500 {
  background-color: #1e589b;
}

.bg-secondary-600 {
  background-color: #ff8c1a;
}

.bg-secondary-800 {
  background-color: #e57300;
}

.bg-blue-gray-100 {
  background-color: #e5eaf0;
}

.bg-blue-gray-200 {
  background-color: #c6d0dd;
}
.bg-blue-gray-300 {
  background-color: #c6d0dd;
}
.bg-blue-gray-500 {
  background-color: #869cb6;
}
.bg-90B686 {
  background-color: #90b686;
}

.bg-blue-gray-600 {
  background-color: #5e7188;
}

.bg-blue-gray-700 {
  background-color: #3d5066;
}

.bg-blue-gray-800 {
  background-color: #2a3746;
}
.bg-light-gray {
  background-color: #f7f8f9;
}
.bg-light-gray-100 {
  background-color: #f7f8f9;
}
.bg-light-gray-200 {
  background-color: #f0f0f0;
}
.bg-black-10 {
  background-color: rgba(23, 30, 38, 0.1);
}
.bg-gradient-1E9B6B-B0C7D4 {
  background: linear-gradient(to right, #1e986b, #b0c7d4);
}
@media (min-width: 768px) {
  .tablet-bg-FFFFFF {
    background-color: #ffffff;
  }
}
@media (min-width: 1024px) {
  .browser-bg-00000050 {
    background-color: #00000050;
  }
  .browser-bg-light-gray-100 {
    background-color: #f7f8f9;
  }

  .browser-bg-18181b10 {
    background-color: #18181b10;
  }

  .browser-bg-00AF87 {
    background-color: #00af87;
  }

  .browser-bg-f2f2f2 {
    background-color: #f2f2f2;
  }

  .browser-bg-E3F6EB {
    background-color: #e3f6eb;
  }

  .browser-bg-FFFFFF {
    background-color: #ffffff;
  }

  .browser-bg-F1F4F7 {
    background-color: #f1f4f7;
  }

  .browser-bg-000000 {
    background-color: #000000;
  }
  .browser-bg-F7F8F9 {
    background-color: #f7f8f9;
  }
}
