.step-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.step-title {
  color: #146647;
  text-align: center;
  margin-bottom: 16px;
  width: 260px;
}
.step-img {
  width: 260px;
  margin-bottom: 32px;
}
.step-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 260px;
}
.step-text {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}
.step-text p {
  line-height: 22px;
  text-align: left;
}

.carousel {
  width: 328px;
  height: 635px;
  position: relative;
  background: none;
}
.slick-track {
  display: flex;
  width: 100%;
}
.slick-list {
  margin-bottom: 32px;
}

.slick-dots {
  position: relative;
  height: 28px;
  bottom: 0;
  margin-bottom: 64px;
}
.slick-slide {
  width: 260px;
}

/* 슬라이드 네비게이션 도트 */
.dots_custom {
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
  padding: 0;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 6px;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: #d1d1d1;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background-color: #1d9b6b;
}

@media (min-width: 1024px) {
  .section08.section08-1 .section08-content-box .carousel {
    width: 1200px;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    width: 1200px !important;
  }
  .step-box {
    margin: 0;
  }
  .slick-slide {
    margin: 0 70px;
    width: 260px !important;
  }
}
