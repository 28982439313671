.greybox {
  display: flex;
  background-color: #d6d4ce;
  border-radius: 10px;
}

.white-box-shadow {
  display: flex;
  box-shadow: 0 2px 10px #00000020;
  background-color: white;
  padding: 20px 20px;
  border-radius: 10px;
  width: 50%;
}
table {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid black;
  text-align: center;
}
.space-around {
  justify-content: space-around;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-y {
  display: flex;
  align-items: center;
}
.center-x {
  display: flex;
  justify-content: center;
}
.start-y {
  display: flex;
  align-items: start;
}
.start-x {
  display: flex;
  justify-content: start;
}
.end-y {
  display: flex;
  align-items: flex-end;
}
.end-x {
  display: flex;
  justify-content: flex-end;
}

.h-100per {
  height: 100%;
}
.w-100per {
  width: 100%;
}
.w-60per {
  width: 60%;
}

.w-50per {
  width: 50%;
}
.w-33per {
  width: 33%;
}
.w-90per {
  width: 90%;
}
.h-300 {
  height: 300px;
}
.h-50per {
  height: 50%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.border-1px-171E26 {
  border: 1px solid rgba(23, 30, 38, 0.1);
}
.border-171E26 {
  border: 1px solid #171e2610;
}
.border-00AF87 {
  border: 1px solid #00af87;
}

.border-right-1px {
  border-right: 1px solid rgba(217, 217, 217, 1);
}
.borderbottom-1px-e8ebe6 {
  border-bottom: 1px solid #e8ebe6;
}
.borderbottom-1px-171E26 {
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.bordertop-1px-e8ebe6 {
  border-top: 1px solid #e8ebe6;
}
.borderbottom-1px {
  border-bottom: 1px solid black;
}

.border-bottom-10px {
  border-bottom: 10px solid #f2f3f6;
}

.border-5E7188 {
  border: 1px solid rgba(94, 113, 136, 0.1);
}
.border-1E9B77 {
  border: 1px solid rgba(30, 155, 119, 0.1);
}
.fade-in-box {
  display: inline-block;
  background: white;
  padding: 10px;
  width: 100%;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
.inline-block {
  display: inline-block;
}
.opacity-78 {
  opacity: 78%;
}
.card-background {
  background: url('./../../asset/images/main/card.png');
  background-color: #def8eb;
  background-size: cover;
  background-position: right;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.light-background-primary {
  color: rgba(30, 155, 107, 1);
}
.light-background-primary-back {
  background-color: rgba(30, 155, 107, 1);
}
.backcolor-primary-200 {
  background-color: rgba(221, 239, 233, 1);
}
.color-primary-600 {
  color: #166f4d;
}
.color-white {
  color: white;
}
.backcolor-bluegrey-100 {
  background-color: rgba(239, 242, 245, 1);
}
.color-bluegrey-700 {
  color: rgba(61, 80, 102, 1);
}

.backcolor-lightgrey {
  background-color: rgba(247, 248, 249, 1);
}
.backcolor-white {
  background-color: white;
}
.backcolor-notice {
  background-color: rgba(231, 139, 62, 1);
}
.backcolor-caution {
  background-color: rgba(216, 72, 54, 1);
}
.color-error {
  color: rgba(216, 72, 54, 1);
}
