.nowrap {
  white-space: nowrap;
}
.text-deco-under {
  text-decoration: underline;
}
.color-white {
  color: white;
}
.color-F66433 {
  color: #f66433;
}
.color-163F6F {
  color: #163f6f;
}
.color-ffffffc7 {
  color: #ffffffc7;
}
.color-notice {
  color: #e78b3e;
}

.color-FFFFFF {
  color: #ffffff;
}
.color-18181B {
  color: #18181b;
}
.color-71717A {
  color: #71717a;
}
.color-1B1B1B {
  color: #1b1b1b;
}
.color-009673 {
  color: #009673;
}
.color-3f3f46 {
  color: #3f3f46;
}
.color-A1A1AA {
  color: #a1a1aa;
}
.color-3F3F46 {
  color: #3f3f46;
}
.color-222222 {
  color: #222222;
}

.color-099776 {
  color: #099776;
}

.color-00AF87 {
  color: #00af87;
}

.color-999C9F {
  color: #999c9f;
}

.color-A0A0A0 {
  color: #a0a0a0;
}

.color-428AF7 {
  color: #428af7;
}

.color-E2E2E2 {
  color: #e2e2e2;
}
.color-0CA96D {
  color: #0ca96d;
}
.color-888B90 {
  color: #888b90;
}
.color-5377A1 {
  color: #5377a1;
}
.color-F8B566 {
  color: #f8b566;
}
/*new*/
.color-1E9B6B {
  color: #1e9b6b;
}
.color-info {
  color: #0c64ce;
}
.color-166F4D {
  color: #166f4d;
}
.color-000000 {
  color: #000000;
}
.color-D62554 {
  color: #d62554;
}
.color-3D5066 {
  color: #3d5066;
}

.color-primary-100 {
  color: #d4f0e6;
}

.color-primary-200 {
  color: #8de2c2;
}

.color-primary-300 {
  color: #09ce7d;
}

.color-primary-400 {
  color: #1e9b6b;
}

.color-primary-500 {
  color: #1e9b6b;
}

.color-primary-600 {
  color: #166f4d;
}

.color-secondary-400 {
  color: #0965ce;
}

.color-secondary-500 {
  color: #1e589b;
}

.color-secondary-600 {
  color: #ff8c1a;
}

.color-secondary-800 {
  color: #e57300;
}

.color-blue-gray-100 {
  color: #e5eaf0;
}

.color-blue-gray-200 {
  color: #c6d0dd;
}

.color-blue-gray-500 {
  color: #869cb6;
}

.color-blue-gray-600 {
  color: #5e7188;
}

.color-blue-gray-700 {
  color: #3d5066;
}

.color-blue-gray-800 {
  color: #2a3746;
}
.color-disabled {
  color: rgba(23, 30, 38, 0.4);
}
.color-text-increase {
  color: #ec4f4f;
}
.color-text-decrease {
  color: #4b47fc;
}
.color-error {
  color: #d84836;
}
.color-text-171E2656 {
  color: rgba(23, 30, 38, 0.56);
}
.color-171E2620 {
  color: #171e2620;
}
.color-00000056 {
  color: #00000056;
}
.color-text-primary {
  color: #171e26;
}
.color-EFF0F6 {
  color: #eff0f6;
}
.color-171E2640 {
  color: #171e2640;
}
.color-text-secondary {
  color: rgba(23, 30, 38, 0.78);
  /* color: #171e26c7; */
  /* color: #171e2678; */
}
.color-white-primary {
  color: #ffffff;
}
.color-white-primary-100 {
  color: #eaf8f2;
}
.color-white-secondary {
  color: #ffffff78;
}
.color-dark-secondary {
  color: #171e26c7;
}
.color-dark-primary {
  color: #171e26;
}
.color-dark-secondary {
  color: #171e26c7;
}
.color-text-tertiary {
  /* color: #171e2656; */
  color: #171e268f;
}
.color-D62554 {
  color: #d62554;
}
.color-primary-500 {
  color: #1e9b6b;
}
.color-positive {
  color: #0e815b;
}
.color-text-171E2656 .text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.font-black {
  font-family: Pretendard-Black;
}
.font-extra-bold {
  font-family: Pretendard-ExtraBold;
}
.font-bold {
  font-family: Pretendard-Bold;
}
.font-semi-bold {
  font-family: Pretendard-SemilBold;
}
.font-medium {
  font-family: Pretendard-Medium;
}
.font-regular {
  font-family: Pretendard-Regular;
}

.f-size-1 {
  font-size: 0.0625rem;
}

.f-size-2 {
  font-size: 0.125rem;
}

.f-size-3 {
  font-size: 0.1875rem;
}

.f-size-4 {
  font-size: 0.25rem;
}

.f-size-5 {
  font-size: 0.3125rem;
}

.f-size-6 {
  font-size: 0.375rem;
}

.f-size-7 {
  font-size: 0.4375rem;
}

.f-size-8 {
  font-size: 0.5rem;
}

.f-size-9 {
  font-size: 0.5625rem;
}

.f-size-10 {
  font-size: 0.625rem;
}

.f-size-11 {
  font-size: 0.6875rem;
}

.f-size-12 {
  font-size: 0.75rem;
}

.f-size-13 {
  font-size: 0.8125rem;
}

.f-size-14 {
  font-size: 0.875rem;
}

.f-size-15 {
  font-size: 0.9375rem;
}

.f-size-16 {
  font-size: 1rem;
}

.f-size-17 {
  font-size: 1.0625rem;
}

.f-size-18 {
  font-size: 1.125rem;
}

.f-size-19 {
  font-size: 1.1875rem;
}

.f-size-20 {
  font-size: 1.25rem;
}

.f-size-21 {
  font-size: 1.3125rem;
}

.f-size-22 {
  font-size: 1.375rem;
}

.f-size-23 {
  font-size: 1.4375rem;
}

.f-size-24 {
  font-size: 1.5rem;
}

.f-size-25 {
  font-size: 1.5625rem;
}

.f-size-26 {
  font-size: 1.625rem;
}

.f-size-27 {
  font-size: 1.6875rem;
}

.f-size-28 {
  font-size: 1.75rem;
}

.f-size-29 {
  font-size: 1.8125rem;
}

.f-size-30 {
  font-size: 1.875rem;
}

.f-size-31 {
  font-size: 1.9375rem;
}

.f-size-32 {
  font-size: 2rem;
}

.f-size-33 {
  font-size: 2.0625rem;
}

.f-size-34 {
  font-size: 2.125rem;
}

.f-size-35 {
  font-size: 2.1875rem;
}

.f-size-36 {
  font-size: 2.25rem;
}

.f-size-37 {
  font-size: 2.3125rem;
}

.f-size-38 {
  font-size: 2.375rem;
}

.f-size-39 {
  font-size: 2.4375rem;
}

.f-size-40 {
  font-size: 2.5rem;
}

.f-size-41 {
  font-size: 2.5625rem;
}

.f-size-42 {
  font-size: 2.625rem;
}

.f-size-43 {
  font-size: 2.6875rem;
}

.f-size-44 {
  font-size: 2.75rem;
}

.f-size-45 {
  font-size: 2.8125rem;
}

.f-size-46 {
  font-size: 2.875rem;
}

.f-size-47 {
  font-size: 2.9375rem;
}

.f-size-48 {
  font-size: 3rem;
}

.f-size-49 {
  font-size: 3.0625rem;
}

.f-size-50 {
  font-size: 3.125rem;
}

.f-size-51 {
  font-size: 3.1875rem;
}

.f-size-52 {
  font-size: 3.25rem;
}

.f-size-53 {
  font-size: 3.3125rem;
}

.f-size-54 {
  font-size: 3.375rem;
}

.f-size-55 {
  font-size: 3.4375rem;
}

.f-size-56 {
  font-size: 3.5rem;
}

.f-size-57 {
  font-size: 3.5625rem;
}

.f-size-58 {
  font-size: 3.625rem;
}

.f-size-59 {
  font-size: 3.6875rem;
}

.f-size-60 {
  font-size: 3.75rem;
}

.f-size-61 {
  font-size: 3.8125rem;
}

.f-size-62 {
  font-size: 3.875rem;
}

.f-size-63 {
  font-size: 3.9375rem;
}

.f-size-64 {
  font-size: 4rem;
}

.f-size-65 {
  font-size: 4.0625rem;
}

.f-size-66 {
  font-size: 4.125rem;
}

.f-size-67 {
  font-size: 4.1875rem;
}

.f-size-68 {
  font-size: 4.25rem;
}

.f-size-69 {
  font-size: 4.3125rem;
}

.f-size-70 {
  font-size: 4.375rem;
}

.f-size-71 {
  font-size: 4.4375rem;
}

.f-size-72 {
  font-size: 4.5rem;
}

.f-size-73 {
  font-size: 4.5625rem;
}

.f-size-74 {
  font-size: 4.625rem;
}

.f-size-75 {
  font-size: 4.6875rem;
}

.f-size-76 {
  font-size: 4.75rem;
}

.f-size-77 {
  font-size: 4.8125rem;
}

.f-size-78 {
  font-size: 4.875rem;
}

.f-size-79 {
  font-size: 4.9375rem;
}

.f-size-80 {
  font-size: 5rem;
}

.f-size-81 {
  font-size: 5.0625rem;
}

.f-size-82 {
  font-size: 5.125rem;
}

.f-size-83 {
  font-size: 5.1875rem;
}

.f-size-84 {
  font-size: 5.25rem;
}

.f-size-85 {
  font-size: 5.3125rem;
}

.f-size-86 {
  font-size: 5.375rem;
}

.f-size-87 {
  font-size: 5.4375rem;
}

.f-size-88 {
  font-size: 5.5rem;
}

.f-size-89 {
  font-size: 5.5625rem;
}

.f-size-90 {
  font-size: 5.625rem;
}

.f-size-91 {
  font-size: 5.6875rem;
}

.f-size-92 {
  font-size: 5.75rem;
}

.f-size-93 {
  font-size: 5.8125rem;
}

.f-size-94 {
  font-size: 5.875rem;
}

.f-size-95 {
  font-size: 5.9375rem;
}

.f-size-96 {
  font-size: 6rem;
}

.f-size-97 {
  font-size: 6.0625rem;
}

.f-size-98 {
  font-size: 6.125rem;
}

.f-size-99 {
  font-size: 6.1875rem;
}

.f-size-100 {
  font-size: 6.25 rem;
}

/* ---------------------------------- */

.line-h-01-em {
  line-height: 0.1em;
}

.line-h-10 {
  line-height: 10px;
}
.line-h-11 {
  line-height: 11px;
}
.line-h-12 {
  line-height: 12px;
}
.line-h-13 {
  line-height: 13px;
}
.line-h-14 {
  line-height: 14px;
}
.line-h-15 {
  line-height: 15px;
}
.line-h-16 {
  line-height: 16px;
}
.line-h-17 {
  line-height: 17px;
}
.line-h-18 {
  line-height: 18px;
}
.line-h-19 {
  line-height: 19px;
}
.line-h-20 {
  line-height: 20px;
}

.line-h-21 {
  line-height: 21px;
}

.line-h-22 {
  line-height: 22px;
}

.line-h-23 {
  line-height: 23px;
}

.line-h-24 {
  line-height: 24px;
}

.line-h-25 {
  line-height: 25px;
}

.line-h-26 {
  line-height: 26px;
}

.line-h-27 {
  line-height: 27px;
}

.line-h-28 {
  line-height: 28px;
}

.line-h-29 {
  line-height: 29px;
}

.line-h-30 {
  line-height: 30px;
}

.line-h-31 {
  line-height: 31px;
}

.line-h-32 {
  line-height: 32px;
}

.line-h-33 {
  line-height: 33px;
}

.line-h-34 {
  line-height: 34px;
}

.line-h-35 {
  line-height: 35px;
}

.line-h-36 {
  line-height: 36px;
}

.line-h-37 {
  line-height: 37px;
}

.line-h-38 {
  line-height: 38px;
}

.line-h-39 {
  line-height: 39px;
}

.line-h-40 {
  line-height: 40px;
}

.line-h-41 {
  line-height: 41px;
}

.line-h-42 {
  line-height: 42px;
}

.line-h-43 {
  line-height: 43px;
}

.line-h-44 {
  line-height: 44px;
}

.line-h-45 {
  line-height: 45px;
}

.line-h-46 {
  line-height: 46px;
}

.line-h-47 {
  line-height: 47px;
}

.line-h-48 {
  line-height: 48px;
}

.line-h-49 {
  line-height: 49px;
}

.line-h-50 {
  line-height: 50px;
}

.line-h-51 {
  line-height: 51px;
}

.line-h-52 {
  line-height: 52px;
}

.line-h-53 {
  line-height: 53px;
}

.line-h-54 {
  line-height: 54px;
}

.line-h-55 {
  line-height: 55px;
}

.line-h-56 {
  line-height: 56px;
}

.line-h-57 {
  line-height: 57px;
}

.line-h-58 {
  line-height: 58px;
}

.line-h-59 {
  line-height: 59px;
}

.line-h-60 {
  line-height: 60px;
}

.line-h-61 {
  line-height: 61px;
}

.line-h-62 {
  line-height: 62px;
}

.line-h-63 {
  line-height: 63px;
}

.line-h-64 {
  line-height: 64px;
}

.line-h-65 {
  line-height: 65px;
}

.line-h-66 {
  line-height: 66px;
}

.line-h-67 {
  line-height: 67px;
}

.line-h-68 {
  line-height: 68px;
}

.line-h-69 {
  line-height: 69px;
}

.line-h-70 {
  line-height: 70px;
}

.line-h-71 {
  line-height: 71px;
}

.line-h-72 {
  line-height: 72px;
}

.line-h-73 {
  line-height: 73px;
}

.line-h-74 {
  line-height: 74px;
}

.line-h-75 {
  line-height: 75px;
}

.line-h-76 {
  line-height: 76px;
}

.line-h-77 {
  line-height: 77px;
}

.line-h-78 {
  line-height: 78px;
}

.line-h-79 {
  line-height: 79px;
}

.line-h-80 {
  line-height: 80px;
}

.line-h-81 {
  line-height: 81px;
}

.line-h-82 {
  line-height: 82px;
}

.line-h-83 {
  line-height: 83px;
}

.line-h-84 {
  line-height: 84px;
}

.line-h-85 {
  line-height: 85px;
}

.line-h-86 {
  line-height: 86px;
}

.line-h-87 {
  line-height: 87px;
}

.line-h-88 {
  line-height: 88px;
}

.line-h-89 {
  line-height: 89px;
}

.line-h-90 {
  line-height: 90px;
}

.line-h-91 {
  line-height: 91px;
}

.line-h-92 {
  line-height: 92px;
}

.line-h-93 {
  line-height: 93px;
}

.line-h-94 {
  line-height: 94px;
}

.line-h-95 {
  line-height: 95px;
}

.line-h-96 {
  line-height: 96px;
}

.line-h-97 {
  line-height: 97px;
}

.line-h-98 {
  line-height: 98px;
}

.line-h-99 {
  line-height: 99px;
}

.line-h-100 {
  line-height: 100px;
}
.mobile-headline-1-medium {
  font-family: Pretendard-Medium;
  font-size: 32px;
  line-height: 46px;
}
.mobile-headline-1-bold {
  font-family: Pretendard-Bold;
  font-size: 32px;
  line-height: 46px;
}
.mobile-headline-2-medium {
  font-family: Pretendard-Medium;
  font-size: 24px;
  line-height: 34px;
}
.mobile-headline-2-bold {
  font-family: Pretendard-Bold;
  font-size: 24px;
  line-height: 34px;
}
.mobile-title-1-medium {
  font-family: Pretendard-Medium;
  font-size: 20px;
  line-height: 29px;
}
.mobile-title-1-bold {
  font-family: Pretendard-Bold;
  font-size: 20px;
  line-height: 29px;
}
.mobile-title-2-medium {
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 26px;
}
.mobile-title-2-bold {
  font-family: Pretendard-Bold;
  font-size: 18px;
  line-height: 26px;
}
.mobile-body-1-medium {
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
}
.mobile-body-1-bold {
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 24px;
}
.mobile-body-2-medium {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
}
.mobile-body-2-bold {
  font-family: Pretendard-Bold;
  font-size: 14px;
  line-height: 20px;
}
.mobile-caption-1-medium {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
}
.mobile-caption-1-bold {
  font-family: Pretendard-Bold;
  font-size: 12px;
  line-height: 14px;
}
.mobile-caption-2-regular {
  font-family: Pretendard-Regular;
  font-size: 11px;
  line-height: 12px;
}
.mobile-caption-2-medium {
  font-family: Pretendard-Medium;
  font-size: 11px;
  line-height: 12px;
}
.mobile-caption-2-bold {
  font-family: Pretendard-Bold;
  font-size: 11px;
  line-height: 12px;
}
.m-h1-32-m {
  font-family: Pretendard-Medium;
  font-size: 32px;
  line-height: 46px;
}
.m-h1-32-b {
  font-family: Pretendard-Bold;
  font-size: 32px;
  line-height: 46px;
}
.m-h2-24-m {
  font-family: Pretendard-Medium;
  font-size: 24px;
  line-height: 34.8px;
}
.m-h2-24-b {
  font-family: Pretendard-Bold;
  font-size: 24px;
  line-height: 34.8px;
}
.m-t1-20-m {
  font-family: Pretendard-Medium;
  font-size: 20px;
  line-height: 29px;
}
.m-t1-20-b {
  font-family: Pretendard-Bold;
  font-size: 20px;
  line-height: 29px;
}
.m-t2-18-m {
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 26px;
}
.m-t2-18-b {
  font-family: Pretendard-Bold;
  font-size: 18px;
  line-height: 26px;
}
.m-b1-16-m {
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
}
.m-b1-16-b {
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 24px;
}
.m-b2-14-m {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
}
.m-b2-14-b {
  font-family: Pretendard-Bold;
  font-size: 14px;
  line-height: 20px;
}
.m-c1-12-m {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
}
.m-c1-12-b {
  font-family: Pretendard-Bold;
  font-size: 12px;
  line-height: 14px;
}
.m-c2-11-r {
  font-family: Pretendard-Regular;
  font-size: 11px;
  line-height: 12px;
}
.m-c2-11-m {
  font-family: Pretendard-medium;
  font-size: 11px;
  line-height: 12px;
}
.m-c2-11-b {
  font-family: Pretendard-Bold;
  font-size: 11px;
  line-height: 12px;
}
@media (min-width: 768px) {
  .tablet-f-size-18 {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  .w-1024-display {
    display: flex;
  }

  .web-headline-1-medium {
    font-family: Pretendard-Medium;
    font-size: 48px;
    line-height: 69px;
  }

  .web-headline-1-bold {
    font-family: Pretendard-bold;
    font-size: 48px;
    line-height: 69px;
  }
  .web-headline-2-medium {
    font-family: Pretendard-Medium;
    font-size: 40px;
    line-height: 58px;
  }
  .web-headline-2-bold {
    font-family: Pretendard-bold;
    font-size: 40px;
    line-height: 58px;
  }
  .web-title-1-medium {
    font-family: Pretendard-Medium;
    font-size: 32px;
    line-height: 46px;
  }
  .web-title-1-bold {
    font-family: Pretendard-Bold;
    font-size: 32px;
    line-height: 46px;
  }
  .web-title-2-medium {
    font-family: Pretendard-Medium;
    font-size: 24px;
    line-height: 34px;
  }
  .web-title-2-bold {
    font-family: Pretendard-Bold;
    font-size: 24px;
    line-height: 34px;
  }
  .web-body-1-medium {
    font-family: Pretendard-Medium;
    font-size: 18px;
    line-height: 29px;
  }
  .web-body-1-bold {
    font-family: Pretendard-Bold;
    font-size: 18px;
    line-height: 29px;
  }
  .web-body-2-medium {
    font-family: Pretendard-Medium;
    font-size: 16px;
    line-height: 26px;
  }
  .web-body-2-bold {
    font-family: Pretendard-Bold;
    font-size: 16px;
    line-height: 26px;
  }
  .web-caption-1-medium {
    font-family: Pretendard-Medium;
    font-size: 14px;
    line-height: 20px;
  }
  .web-caption-1-bold {
    font-family: Pretendard-Bold;
    font-size: 14px;
    line-height: 20px;
  }
  .web-caption-2-medium {
    font-family: Pretendard-Medium;
    font-size: 12px;
    line-height: 16px;
  }
  .web-caption-2-bold {
    font-family: Pretendard-Bold;
    font-size: 12px;
    line-height: 16px;
  }
  .web-caption-2-regular {
    font-family: Pretendard-Regular;
    font-size: 11px;
    line-height: 12px;
  }
  .w-h1-48-m {
    font-family: Pretendard-Medium;
    font-size: 48px;
    line-height: 69px;
  }
  .w-h1-48-b {
    font-family: Pretendard-Bold;
    font-size: 48px;
    line-height: 69px;
  }
  .w-h2-40-m {
    font-family: Pretendard-Medium;
    font-size: 40px;
    line-height: 58px;
  }
  .w-h2-40-b {
    font-family: Pretendard-Medium;
    font-size: 40px;
    line-height: 58px;
  }
  .w-t1-32-m {
    font-family: Pretendard-Medium;
    font-size: 32px;
    line-height: 46px;
  }
  .w-t1-32-b {
    font-family: Pretendard-Bold;
    font-size: 32px;
    line-height: 46px;
  }
  .w-t2-24-m {
    font-family: Pretendard-Medium;
    font-size: 24px;
    line-height: 34px;
  }
  .w-t2-24-b {
    font-family: Pretendard-Bold;
    font-size: 24px;
    line-height: 34px;
  }
  .w-t3-20-m {
    font-family: Pretendard-Medium;
    font-size: 20px;
    line-height: 33px;
  }
  .w-t3-20-b {
    font-family: Pretendard-Bold;
    font-size: 20px;
    line-height: 33px;
  }
  .w-b1-18-m {
    font-family: Pretendard-Medium;
    font-size: 18px;
    line-height: 29px;
  }
  .w-b1-18-b {
    font-family: Pretendard-Bold;
    font-size: 18px;
    line-height: 29px;
  }
  .w-b2-16-m {
    font-family: Pretendard-Medium;
    font-size: 16px;
    line-height: 26px;
  }
  .w-b2-16-b {
    font-family: Pretendard-Bold;
    font-size: 16px;
    line-height: 26px;
  }
  .w-c1-14-m {
    font-family: Pretendard-Medium;
    font-size: 14px;
    line-height: 20px;
  }
  .w-c1-14-b {
    font-family: Pretendard-Bold;
    font-size: 14px;
    line-height: 20px;
  }
  .w-c2-12-r {
    font-family: Pretendard-Regular;
    font-size: 12px;
    line-height: 16px;
  }
  .w-c2-12-m {
    font-family: Pretendard-Medium;
    font-size: 12px;
    line-height: 16px;
  }
  .w-c2-12-b {
    font-family: Pretendard-Bold;
    font-size: 12px;
    line-height: 16px;
  }
  .w-1024-f-size-24 {
    font-size: 24px;
  }

  .w-1024-line-h-34 {
    line-height: 34px;
  }
  .w-1024-color-FFFFFF {
    color: #ffffff;
  }
  .w-1024-f-size-18 {
    font-size: 18px;
  }
  .browser-nowrap {
    white-space: nowrap;
  }

  .browser-color-FFFFFF {
    color: #ffffff;
  }
  .browser-color-18181B {
    color: #18181b;
  }
  .browser-color-71717A {
    color: #71717a;
  }
  .browser-color-009673 {
    color: #009673;
  }
  .browser-color-3f3f46 {
    color: #3f3f46;
  }
  .browser-color-A1A1AA {
    color: #a1a1aa;
  }
  .browser-color-3F3F46 {
    color: #3f3f46;
  }
  .browser-color-222222 {
    color: #222222;
  }

  .browser-color-099776 {
    color: #099776;
  }

  .browser-color-999C9F {
    color: #999c9f;
  }

  .browser-color-428AF7 {
    color: #428af7;
  }

  .browser-color-E2E2E2 {
    color: #e2e2e2;
  }
  .browser-text-start {
    text-align: start;
  }
  .browser-text-end {
    text-align: end;
  }

  .browser-text-left {
    text-align: left;
  }

  .browser-text-right {
    text-align: right;
  }

  .browser-text-center {
    text-align: center;
  }

  .browser-font-bold {
    font-family: Pretendard-Bold;
  }
  .browser-font-medium {
    font-family: Pretendard-Medium;
  }
  .browser-font-regular {
    font-family: Pretendard-Regular;
  }

  .browser-f-size-1 {
    font-size: 1px;
  }

  .browser-f-size-2 {
    font-size: 2px;
  }

  .browser-f-size-3 {
    font-size: 3px;
  }

  .browser-f-size-4 {
    font-size: 4px;
  }

  .browser-f-size-5 {
    font-size: 5px;
  }

  .browser-f-size-6 {
    font-size: 6px;
  }

  .browser-f-size-7 {
    font-size: 7px;
  }

  .browser-f-size-8 {
    font-size: 8px;
  }

  .browser-f-size-9 {
    font-size: 9px;
  }

  .browser-f-size-10 {
    font-size: 10px;
  }

  .browser-f-size-11 {
    font-size: 11px;
  }

  .browser-f-size-12 {
    font-size: 12px;
  }

  .browser-f-size-13 {
    font-size: 13px;
  }

  .browser-f-size-14 {
    font-size: 14px;
  }

  .browser-f-size-15 {
    font-size: 15px;
  }

  .browser-f-size-16 {
    font-size: 16px;
  }

  .browser-f-size-17 {
    font-size: 17px;
  }

  .browser-f-size-18 {
    font-size: 18px;
  }

  .browser-f-size-19 {
    font-size: 19px;
  }

  .browser-f-size-20 {
    font-size: 20px;
  }

  .browser-f-size-21 {
    font-size: 21px;
  }

  .browser-f-size-22 {
    font-size: 22px;
  }

  .browser-f-size-23 {
    font-size: 23px;
  }

  .browser-f-size-24 {
    font-size: 24px;
  }

  .browser-f-size-25 {
    font-size: 25px;
  }

  .browser-f-size-26 {
    font-size: 26px;
  }

  .browser-f-size-27 {
    font-size: 27px;
  }

  .browser-f-size-28 {
    font-size: 28px;
  }

  .browser-f-size-29 {
    font-size: 29px;
  }

  .browser-f-size-30 {
    font-size: 30px;
  }

  .browser-f-size-31 {
    font-size: 31px;
  }

  .browser-f-size-32 {
    font-size: 32px;
  }

  .browser-f-size-33 {
    font-size: 33px;
  }

  .browser-f-size-34 {
    font-size: 34px;
  }

  .browser-f-size-35 {
    font-size: 35px;
  }
  .browser-f-size-36 {
    font-size: 36px;
  }

  .browser-f-size-37 {
    font-size: 37px;
  }

  .browser-f-size-38 {
    font-size: 38px;
  }

  .browser-f-size-39 {
    font-size: 39px;
  }

  .browser-f-size-40 {
    font-size: 40px;
  }

  .browser-f-size-41 {
    font-size: 41px;
  }

  .browser-f-size-42 {
    font-size: 42px;
  }

  .browser-f-size-43 {
    font-size: 43px;
  }

  .browser-f-size-44 {
    font-size: 44px;
  }

  .browser-f-size-45 {
    font-size: 45px;
  }

  .browser-f-size-46 {
    font-size: 46px;
  }

  .browser-f-size-47 {
    font-size: 47px;
  }

  .browser-f-size-48 {
    font-size: 48px;
  }

  .browser-f-size-49 {
    font-size: 49px;
  }

  .browser-f-size-50 {
    font-size: 50px;
  }

  .browser-f-size-51 {
    font-size: 51px;
  }

  .browser-f-size-52 {
    font-size: 52px;
  }

  .browser-f-size-53 {
    font-size: 53px;
  }

  .browser-f-size-54 {
    font-size: 54px;
  }

  .browser-f-size-55 {
    font-size: 55px;
  }

  .browser-f-size-56 {
    font-size: 56px;
  }

  .browser-f-size-57 {
    font-size: 57px;
  }

  .browser-f-size-58 {
    font-size: 58px;
  }

  .browser-f-size-59 {
    font-size: 59px;
  }

  .browser-f-size-60 {
    font-size: 60px;
  }

  .browser-f-size-61 {
    font-size: 61px;
  }

  .browser-f-size-62 {
    font-size: 62px;
  }

  .browser-f-size-63 {
    font-size: 63px;
  }

  .browser-f-size-64 {
    font-size: 64px;
  }

  .browser-f-size-65 {
    font-size: 65px;
  }

  .browser-f-size-66 {
    font-size: 66px;
  }

  .browser-f-size-67 {
    font-size: 67px;
  }

  .browser-f-size-68 {
    font-size: 68px;
  }

  .browser-f-size-69 {
    font-size: 69px;
  }

  .browser-f-size-70 {
    font-size: 70px;
  }

  .browser-f-size-71 {
    font-size: 71px;
  }

  .browser-f-size-72 {
    font-size: 72px;
  }

  .browser-f-size-73 {
    font-size: 73px;
  }

  .browser-f-size-74 {
    font-size: 74px;
  }

  .browser-f-size-75 {
    font-size: 75px;
  }

  .browser-f-size-76 {
    font-size: 76px;
  }

  .browser-f-size-77 {
    font-size: 77px;
  }

  .browser-f-size-78 {
    font-size: 78px;
  }

  .browser-f-size-79 {
    font-size: 79px;
  }

  .browser-f-size-80 {
    font-size: 80px;
  }

  .browser-f-size-81 {
    font-size: 81px;
  }

  .browser-f-size-82 {
    font-size: 82px;
  }

  .browser-f-size-83 {
    font-size: 83px;
  }

  .browser-f-size-84 {
    font-size: 84px;
  }

  .browser-f-size-85 {
    font-size: 85px;
  }

  .browser-f-size-86 {
    font-size: 86px;
  }

  .browser-f-size-87 {
    font-size: 87px;
  }

  .browser-f-size-88 {
    font-size: 88px;
  }

  .browser-f-size-89 {
    font-size: 89px;
  }

  .browser-f-size-90 {
    font-size: 90px;
  }

  .browser-f-size-91 {
    font-size: 91px;
  }

  .browser-f-size-92 {
    font-size: 92px;
  }

  .browser-f-size-93 {
    font-size: 93px;
  }

  .browser-f-size-94 {
    font-size: 94px;
  }

  .browser-f-size-95 {
    font-size: 95px;
  }

  .browser-f-size-96 {
    font-size: 96px;
  }

  .browser-f-size-97 {
    font-size: 97px;
  }

  .browser-f-size-98 {
    font-size: 98px;
  }

  .browser-f-size-99 {
    font-size: 99px;
  }

  .browser-f-size-100 {
    font-size: 100px;
  }

  /* ---------------------------------- */

  .browser-line-h-10 {
    line-height: 10px;
  }
  .browser-line-h-11 {
    line-height: 11px;
  }
  .browser-line-h-12 {
    line-height: 12px;
  }
  .browser-line-h-13 {
    line-height: 13px;
  }
  .browser-line-h-14 {
    line-height: 14px;
  }
  .browser-line-h-15 {
    line-height: 15px;
  }
  .browser-line-h-16 {
    line-height: 16px;
  }
  .browser-line-h-17 {
    line-height: 17px;
  }
  .browser-line-h-18 {
    line-height: 18px;
  }
  .browser-line-h-19 {
    line-height: 19px;
  }
  .browser-line-h-20 {
    line-height: 20px;
  }

  .browser-line-h-21 {
    line-height: 21px;
  }

  .browser-line-h-22 {
    line-height: 22px;
  }

  .browser-line-h-23 {
    line-height: 23px;
  }

  .browser-line-h-24 {
    line-height: 24px;
  }

  .browser-line-h-25 {
    line-height: 25px;
  }

  .browser-line-h-26 {
    line-height: 26px;
  }

  .browser-line-h-27 {
    line-height: 27px;
  }

  .browser-line-h-28 {
    line-height: 28px;
  }

  .browser-line-h-29 {
    line-height: 29px;
  }

  .browser-line-h-30 {
    line-height: 30px;
  }

  .browser-line-h-31 {
    line-height: 31px;
  }

  .browser-line-h-32 {
    line-height: 32px;
  }

  .browser-line-h-33 {
    line-height: 33px;
  }

  .browser-line-h-34 {
    line-height: 34px;
  }

  .browser-line-h-35 {
    line-height: 35px;
  }

  .browser-line-h-36 {
    line-height: 36px;
  }

  .browser-line-h-37 {
    line-height: 37px;
  }

  .browser-line-h-38 {
    line-height: 38px;
  }

  .browser-line-h-39 {
    line-height: 39px;
  }

  .browser-line-h-40 {
    line-height: 40px;
  }

  .browser-line-h-41 {
    line-height: 41px;
  }

  .browser-line-h-42 {
    line-height: 42px;
  }

  .browser-line-h-43 {
    line-height: 43px;
  }

  .browser-line-h-44 {
    line-height: 44px;
  }

  .browser-line-h-45 {
    line-height: 45px;
  }

  .browser-line-h-46 {
    line-height: 46px;
  }

  .browser-line-h-47 {
    line-height: 47px;
  }

  .browser-line-h-48 {
    line-height: 48px;
  }

  .browser-line-h-49 {
    line-height: 49px;
  }

  .browser-line-h-50 {
    line-height: 50px;
  }

  .browser-line-h-51 {
    line-height: 51px;
  }

  .browser-line-h-52 {
    line-height: 52px;
  }

  .browser-line-h-53 {
    line-height: 53px;
  }

  .browser-line-h-54 {
    line-height: 54px;
  }

  .browser-line-h-55 {
    line-height: 55px;
  }

  .browser-line-h-56 {
    line-height: 56px;
  }

  .browser-line-h-57 {
    line-height: 57px;
  }

  .browser-line-h-58 {
    line-height: 58px;
  }

  .browser-line-h-59 {
    line-height: 59px;
  }

  .browser-line-h-60 {
    line-height: 60px;
  }

  .browser-line-h-61 {
    line-height: 61px;
  }

  .browser-line-h-62 {
    line-height: 62px;
  }

  .browser-line-h-63 {
    line-height: 63px;
  }

  .browser-line-h-64 {
    line-height: 64px;
  }

  .browser-line-h-65 {
    line-height: 65px;
  }

  .browser-line-h-66 {
    line-height: 66px;
  }

  .browser-line-h-67 {
    line-height: 67px;
  }

  .browser-line-h-68 {
    line-height: 68px;
  }

  .browser-line-h-69 {
    line-height: 69px;
  }

  .browser-line-h-70 {
    line-height: 70px;
  }

  .browser-line-h-71 {
    line-height: 71px;
  }

  .browser-line-h-72 {
    line-height: 72px;
  }

  .browser-line-h-73 {
    line-height: 73px;
  }

  .browser-line-h-74 {
    line-height: 74px;
  }

  .browser-line-h-75 {
    line-height: 75px;
  }

  .browser-line-h-76 {
    line-height: 76px;
  }

  .browser-line-h-77 {
    line-height: 77px;
  }

  .browser-line-h-78 {
    line-height: 78px;
  }

  .browser-line-h-79 {
    line-height: 79px;
  }

  .browser-line-h-80 {
    line-height: 80px;
  }

  .browser-line-h-81 {
    line-height: 81px;
  }

  .browser-line-h-82 {
    line-height: 82px;
  }

  .browser-line-h-83 {
    line-height: 83px;
  }

  .browser-line-h-84 {
    line-height: 84px;
  }

  .browser-line-h-85 {
    line-height: 85px;
  }

  .browser-line-h-86 {
    line-height: 86px;
  }

  .browser-line-h-87 {
    line-height: 87px;
  }

  .browser-line-h-88 {
    line-height: 88px;
  }

  .browser-line-h-89 {
    line-height: 89px;
  }

  .browser-line-h-90 {
    line-height: 90px;
  }

  .browser-line-h-91 {
    line-height: 91px;
  }

  .browser-line-h-92 {
    line-height: 92px;
  }

  .browser-line-h-93 {
    line-height: 93px;
  }

  .browser-line-h-94 {
    line-height: 94px;
  }

  .browser-line-h-95 {
    line-height: 95px;
  }

  .browser-line-h-96 {
    line-height: 96px;
  }

  .browser-line-h-97 {
    line-height: 97px;
  }

  .browser-line-h-98 {
    line-height: 98px;
  }

  .browser-line-h-99 {
    line-height: 99px;
  }

  .browser-line-h-100 {
    line-height: 100px;
  }
  .browser-color-primary-600 {
    color: #166f4d;
  }
  .tablet-f-size-32 {
    font-size: 32px;
  }
  .tablet-line-h-46px {
    line-height: 46px;
  }
}

@media (min-width: 1920px) {
  /* .browser-; */
}
