.banner-wrap {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-inner {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.banner-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: white;
}
.banner-close-btn {
  margin-left: auto;
  cursor: pointer;
}
.to-signup {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.to-signup-button {
  height: 32px;
  background-color: #ffff;
  color: black;
  text-decoration: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  margin-left: 16px;
}
.font-yellow {
  color: #f8d857;
}

@media (max-width: 1919px) {
  .banner-inner {
    width: 100%;
    padding: 0 60px;
  }
}
@media (max-width: 1023px) {
  .banner-inner {
    width: 100%;
    padding: 0 40px;
  }
}
@media (max-width: 767px) {
  .banner-inner {
    width: 100%;
    padding: 0 16px;
  }
}
