#viewer-host {
  width: 100%;
  height: 100vh;
}

:root {
  --vh: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
}

ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}

ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: rgba(68, 204, 155, 1);
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.menu-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.menu-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.menu-exit {
  opacity: 1;
  transform: translateY(0);
}
.menu-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 300ms,
    transform 300ms;
}

.sliderWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.input-w100 {
  display: flex;
  width: 100px;
}

.annual-cost-container {
  display: flex;
  flex-direction: column;
  border-bottom: 10px solid rgba(242, 243, 246, 1);
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container-w90per {
  width: 90%;
  display: flex;
  flex-direction: column;
}
