.wrap {
  width: 100%;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

  .tablet {
    background-color: white;
    padding: 32px 16px;
    border-top: 1px solid var(--Alias-Color-Line-Border, rgba(23, 30, 38, 0.1));
  }
  .tablet.w-100per.center-y {
    height: 60px;
    border-top: none;
    padding: 16px;
  }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.section {
  text-align: center;
}

/* font */

.text40 {
  font-family: 'NanumSquareNeo-Heavy';
  font-size: 40px;
  line-height: 51px;
  letter-spacing: -0.8px;
  z-index: 1;
  color: #0b0b0b;
}
.text34 {
  font-family: 'NanumSquareNeo-Heavy';
  font-size: 34px;
  line-height: 45px;
  letter-spacing: -0.8px;
  color: #0b0b0b;
}
.text-light-grey {
  color: #dbdbdb;
}
.text-light-green {
  color: #00ea00;
  font-family: 'NanumSquareNeo-Heavy';
}
.text-grey {
  color: #aaa;
  font-family: 'NanumSquareNeo-Bold';
}
.text32 {
  font-family: 'NanumSquareNeo-ExtraBold';
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.64px;
  color: #0b0b0b;
}
.text27 {
  font-size: 27px;
  font-family: 'NanumSquareNeo-Bold';
  line-height: 37px;
  letter-spacing: -0.54px;
}
.text27-white {
  font-size: 27px;
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 37px;
  letter-spacing: -0.54px;
  color: #fff;
}
.text27-white .text-green {
  font-size: 27px;
  color: #00ea00;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.text-bright-green {
  font-size: 27px;
  color: #00ea00;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.text27 .strong {
  font-family: 'NanumSquareNeo-Heavy';
}
.text27 span {
  color: #1e9b6b;
}
.text-27-heavy {
  font-family: 'NanumSquareNeo-Heavy';
}
.text-27-extra-bold {
  font-family: 'NanumSquareNeo-ExtraBold';
}
.text24 {
  font-size: 24px;
  line-height: 32px;
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #fff;
}
.text24-green {
  font-size: 24px;
  color: #00ea00;
  font-family: 'NanumSquareNeo-Heavy';
}
.text20 {
  color: #565656;
  text-align: center;
  font-size: 20px;
  font-family: 'NanumSquareNeo-Regular';
  line-height: 29px;
  letter-spacing: -0.4px;

  span {
    font-family: 'NanumSquareNeo-ExtraBold';
  }
}
.text-20-bold {
  font-family: 'NanumSquareNeo-Bold';
  line-height: 29px;
  letter-spacing: -0.4px;
  color: #fff;
}
.text-20-extra-bold {
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 29px;
  letter-spacing: -0.4px;
}
.text17 {
  color: #6e877f;
  text-align: center;
  font-size: 17px;
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 26px;
  letter-spacing: -0.34px;
}
.text17-white {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 26px;
  letter-spacing: -0.34px;
}
.text17-green {
  color: #1e9b6b;
  text-align: center;
  font-size: 17px;
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 26px;
  letter-spacing: -0.34px;
}
.text17-grey {
  color: #565656;
  text-align: center;
  font-size: 17px;
  font-family: 'NanumSquareNeo-ExtraBold';
  line-height: 26px;
  letter-spacing: -0.34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text16 {
  color: #0b0b0b;
  font-family: 'NanumSquareNeo-Bold';
  font-size: 16px;
  line-height: 29px;
}
.text14 {
  font-size: 14px;
  font-family: 'NanumSquareNeo-Regular';
  color: #000;
}
.text-light {
  font-family: 'NanumSquareNeo-Bold';
}
.text-gradient {
  background: linear-gradient(90deg, #2d2d2d 0.71%, rgba(45, 45, 45, 0.4) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'NanumSquareNeo-Bold';
}
.text-green {
  color: #1e9b6b;
  text-align: center;
  font-family: 'NanumSquareNeo-Heavy';
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.64px;
}
.green {
  color: #1e9b6b;
}
.light-green {
  color: #00ea00;
}
.white {
  color: #fff;
  line-height: 22px;
}

.text-semi-light {
  font-size: 32px;
  text-align: center;
  font-family: 'NanumSquareNeo-Bold';
  line-height: 45px;
  letter-spacing: -0.64px;
  display: inline;
}

/* section01 */

.section-price {
  height: 790px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 40px 0;
  background-image: url(../../../../public/asset/images/promotion/01-B-bg.png);
  background-size: cover;
  background-position: center;
}
.section-house {
  height: 632px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  width: 100%;
  background: #f8f8f8;
  padding: 40px 0 80px;
  background-image: url(../../../../public/asset/images/promotion/house-main-bg.png);
  background-size: cover;
  background-position: center;
}
.section.section-price .section01-title-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.section.section-house .section-description {
  color: #fff;
}
.section.section-price .section-description {
  color: #e2e2e2;
  margin: 0;
}
.section.section-price .menu-tag {
  margin: 0;
}
.section.section-price .section01-title-box .section-title .text34 {
  color: #fff;
}
.section.section-house .section01-title-box .section-title .text40 {
  color: #fff;
}
.section.section-price .section01-content-box {
  height: 481px;
}
.section.section01 {
  height: 632px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  width: 100%;
  background: #f8f8f8;
  padding: 40px 0 80px;
}
h1.menu-tag {
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: #0b0b0b;
  color: white;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: 'NanumSquareNeo-Bold';
}
.section01-title-box {
  width: 328px;
}
.section-description {
  width: 100%;
  color: #565656;
  font-size: 20px;
  font-family: 'NanumSquareNeo-Regular';
  line-height: 29px;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
}
.section-title-box {
  position: relative;
}
.section-title {
  position: relative;
  z-index: 1;
}
.highlight {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 124px;
  height: 14px;
  flex-shrink: 0;
  background-color: #00ea00;
  z-index: 0;
}
.section01-content-box {
  width: 360px;
  height: 230px;
  position: relative;
}
.section01-content-box-2 {
  width: 100%;
  text-align: center;
}
.section01-content-box-2 img {
  margin-top: 18px;
}
.section01-money-tree {
  height: 100%;
  position: absolute;
  top: 0;
  right: -8px;
}

/* section02 */

.section02 {
  width: 100%;
  height: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  background: #0b0b0b;
}
.section02-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 80px;
  align-items: center;
  background: #0b0b0b;
  padding: 596px 0 160px;
}
.aifarming-content {
  padding: 889px 0 160px;
}
.house-content {
  padding: 361px 0 160px;
}
.section02-title-box {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.section02-content-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.section02-content-box-house {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}
.section02-content-box div {
  width: 328px;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px;
  border: 1px solid #00ea00;
  border-radius: 8px;
}

/* section03 */
.section03 {
  width: 100%;
  display: flex;
  width: 360px;
  height: 780px;
  padding: 0px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.text-with-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
/* section04 */

.section04 {
  width: 100%;
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.white-line {
  width: 80px;
  height: 1px;
  background-color: #fff;
}

.section04-title-box {
  position: relative;
  width: 100%;
  background-color: #1e9b6b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
  color: #fff;
  padding: 100px 0 40px;
  height: 360px;
  margin-bottom: 100px;
}
.section04-title-box .text32 {
  color: #fff;
}
.text32.text-color-box {
  font-family: 'NanumSquareNeo-Heavy';
  color: #1e9b6b;
  width: 157px;
  height: 45px;
  background-color: white;
  margin-right: 8px;
  line-height: 45px;
  padding: 4px 4px;
}
.section04-titles {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.section04-title-box::after {
  content: '';
  position: absolute;
  bottom: -69px;
  left: 50%;
  border-width: 35px;
  border-style: solid;
  border-color: transparent transparent #1e9b6b transparent;
  transform: translateX(-50%) rotate(180deg);
}
.section04-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  margin-bottom: 80px;
  width: 100%;
}
.section04-card {
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 328px;
  padding: 40px 0 52px;
}
.card-text-number {
  width: 46px;
  height: 46px;
  background-color: #1e9b6b;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'NanumSquareNeo-Heavy';
  font-size: 27px;
  line-height: 37px;
  letter-spacing: -0.54px;
}
.card-text {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

/* section05 */
.section05 {
  width: 100%;
}
.section05-title-box {
  padding: 100px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.section05-title-box .text32 {
  color: #1e9b6b;
}
.section05-title-box .text-color-box {
  width: fit-content;
  height: 53px;
  padding: 4px 8px;
  color: #ffffff;
  background-color: #1e9b6b;
  margin-bottom: 12px;
}
.section05-title--box-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line-column {
  width: 1px;
  height: 100px;
  background-color: #1e9b6b;
}
.section05-content-box {
  padding: 40px 0 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 120px;
}
.section05-content-box .text20 {
  color: #171e26;
  z-index: 1;
  position: relative;
  width: 300px;
  margin-bottom: 28px;
}
.section05-speech-bubble-box,
section05-speech-bubble-box-right {
  width: 328px;
}
.section05-speech-bubble-box-right .section05-speech-bubble {
  display: flex;
  justify-content: right;
}
.section05-speech-bubble {
  position: relative;
}
.section05-speech-bubble .color-bubble {
  position: absolute;
  top: -12px;
  left: 0;
  z-index: 0;
}
.section05-speech-bubble .color-bubble-big {
  position: absolute;
  top: -12px;
  left: 12px;
  z-index: 0;
}
.section05-speech-bubble .line-bubble {
  position: absolute;
  top: -12px;
  right: 0;
  z-index: 0;
}
.section05-speech-bubble .line-bubble.line-bubble-big {
  top: -19px;
}
.people-img {
  width: 100%;
  padding-left: 54px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.people-img.people-img-right {
  padding-right: 54px;
  justify-content: right;
}
/* section06 */

.section06 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}
.section06-title-box {
  padding: 80px 0 40px;
  width: 360px;
}
.section06-title-1 {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section06-title-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section06-title-box div:nth-child(2) {
  margin-bottom: 12px;
}
.section06-title-box .text27 {
  color: #0b0b0b;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.section06-title-box .text27.text27-green {
  color: #1e9b6b;
  margin-bottom: 8px;
}
.text27.text-round-box {
  position: relative;
  width: 147px;
  height: 49px;
  line-height: 49px;
  color: #fff;
  background: url('../../../../public/asset/images/promotion/round-bg.svg')
    center/cover;
}
.text27.text-round-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('../../../../public/asset/images/promotion/round-bg.svg')
    center/cover;
}
.text27.text-dot {
  position: relative;
  width: fit-content;
}
.text24.text-dot-right,
.text24.text-dot-right-1 {
  position: relative;
  width: fit-content;
  margin-bottom: 14px;
}
.text27.text-dot::before {
  content: '';
  position: absolute;
  top: -20%;
  left: 8%;
  width: 87px;
  height: 6px;
  background: url('../../../../public/asset/images/promotion/text-dot.svg')
    center/cover;
  z-index: 1;
}
.text24.text-dot-right-1::before {
  content: '';
  position: absolute;
  top: -20%;
  left: 50%;
  width: 72px;
  height: 6px;
  background: url('../../../../public/asset/images/promotion/text-dot-16.svg')
    center/cover;
  z-index: 1;
}
.text24.text-dot-right::before {
  content: '';
  position: absolute;
  top: -20%;
  left: 56%;
  width: 71px;
  height: 6px;
  background: url('../../../../public/asset/images/promotion/text-dot-16.svg')
    center/cover;
  z-index: 1;
}
.section06-content-box {
  padding: 40px 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.section06-content-01 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.section06-text-block {
  display: flex;
  position: relative;
  gap: 32px;
}
.section06-block-box-left {
  width: 148px;
  border-radius: 16px;
  background-color: #ddefe9;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.section06-block-box-right {
  width: 148px;
  border-radius: 16px;
  background-color: #1e9b6b;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.section06-block-box-right.text17 {
  color: #fff;
}
.section06-block-box-middle {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.section06-block-box-middle .text20 {
  padding: 4px;
  background-color: #fff;
  font-family: 'NanumSquareNeo-ExtraBold';
  border-radius: 8px;
  color: #000;
}
.white-box {
  padding: 4px 10px;
  background-color: #fff;
  color: #1e9b6b;
  border-radius: 8px;
}
.block-box-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.section06-content-02 .text27 {
  color: #1e9b6b;
  margin-bottom: 8px;
}
.section06-content-02 .text20 {
  color: #000;
  font-family: 'NanumSquareNeo-ExtraBold';
  margin-bottom: 12px;
}
.box-with-img {
  background: url('../../../../public/asset/images/promotion/block-box-bg.svg');
  background-size: cover;
  background-position: center;
  justify-content: flex-start;
}
.section06-content-04.text20 {
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #0b0b0b;
}
.section06-content-04.text20 span {
  color: #1e9b6b;
}
.section06-content-01-imgs {
  width: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.section06-content-01-imgs img {
  margin-bottom: 16px;
}
.section06-content-01-imgs .text-20-extra-bold.light-green {
  margin-bottom: 8px;
}

.section06-1 {
  background-color: #000;
}
.section06-1 .section06-title-box .text27 {
  color: #fff;
}
.section06-1 .section06-title-box .text27.text27-green {
  color: #00ea00;
}
.section06-1 .section06-title-box .text27.text-round-box {
  color: #0b0b0b;
  background: url('../../../../public/asset/images/promotion/round-bg-green.svg');
}
.section06-1 .section06-title-box .text27.text-dot::before {
  background: url('../../../../public/asset/images/promotion/text-dot-green.svg');
}
.section06-1 .section06-content-box {
  gap: 40px;
  width: 360px;
}
.section06-content-box .text24-green {
  line-height: 40px;
  border-bottom: 2px solid #00ea00;
}
.section06-1 .section06-content-01 .section06-text-block {
  width: 328px;
  display: flex;
  gap: 8px;
}

.section06-1 .section06-content-01 .section06-text-block .section06-block-box {
  width: 104px;
  background-color: #00ea00;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 26px 4px;
  border-radius: 16px;
  cursor: pointer;
}
.section06-1
  .section06-content-01
  .section06-text-block
  .section06-block-box
  .text17 {
  color: #0d7815;
  font-family: NanumSquareNeo-ExtraBold;
}

.section06-1 .section06-content-01 .content-01-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section06-1 .section06-content-01 .content-01-title .text20 {
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #fff;
}
.section06-1 .section06-content-01 .content-01-title .text-20-bold {
  font-size: 20px;
}
.section06-1
  .section06-content-box
  .section06-content-01
  .content-01-title
  .text27.text-27-heavy {
  color: #00ea00;
}
.section06-1
  .section06-content-box
  .section06-content-01
  .content-01-title
  .content-01-grey-line {
  width: 80px;
  height: 1px;
  background-color: #aaa;
  margin-bottom: 24px;
}
.section06-1 .section06-content-box .section06-content-02 {
  color: #fff;
}
.section06-1 .section06-content-box .section06-content-02.text20 {
  font-family: 'NanumSquareNeo-ExtraBold';
}
.section06-1 .section06-content-box .section06-content-02.text20 span {
  font-family: 'NanumSquareNeo-Heavy';
  color: #00ea00;
  font-size: 27px;
}
.content-01-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.content-01-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.content-01-content-r {
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.content-01-content-r .text14 {
  color: #aaa;
}
.content-01-content .text27 {
  padding: 8px 24px;
  background-color: #00ea00;
  border-radius: 100px;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.content-white-box {
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 328px;
}

.content-white-box-round {
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 328px;
}
.content-white-box-round .text17 {
  color: #1e9b6b;
}
.white-box-content1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.white-box-content1-1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.white-box-content2 {
  font-size: 20px;
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #0b0b0b;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 8px;
}
/* .content-white-box div {
  display: flex;
  flex-direction: column;
  gap: 8px;
} */
.content-white-box div .text14 {
  color: #aaa;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.content-01-content .text17 {
  color: #0b0b0b;
}
.content-01-content div:not(:last-of-type) {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}

.section06-1 .section06-content-box .section06-content-01 .data-slider {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.section06-1
  .section06-content-box
  .section06-content-01
  .data-slider
  .data-slide {
  width: 328px;
  height: 154px;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}
.section06-1
  .section06-content-box
  .section06-content-01
  .data-slider
  .data-slide
  .text20 {
  width: 100%;
  border-bottom: 1px solid #e8e8e9;
  font-family: 'NanumSquareNeo-Bold';
  text-align: left;
  color: #0b0b0b;
}
.section06-1
  .section06-content-box
  .section06-content-01
  .data-slider
  .data-slide
  span {
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #0b0b0b;
}
.indicator {
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
}
.indicator-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.indicator-dot.active {
  background-color: #565656;
}
.sliderTab {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* section07 */

.section07 {
  width: 100%;
  background: url('../../../../public/asset/images/promotion/sec07-bg.png');
  background-size: cover;
  background-position: center;
}
.section07-title-box {
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.text27.text27-white {
  color: #fff;
  font-family: 'NanumSquareNeo-ExtraBold';
}
.section07-content-box {
  padding: 0 0 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.section07-content-01.text14 {
  color: rgba(255, 255, 255, 0.5);
  width: 328px;
  text-align: left;
}
.section07-content-02 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.section08 {
  width: 100%;
}
.section08-title-box {
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .text27 {
    font-family: 'NanumSquareNeo-ExtraBold';
  }
  .text20 {
    font-family: 'NanumSquareNeo-Bold';
  }
  .text20 span {
    font-family: 'NanumSquareNeo-Bold';
    color: #1e9b6b;
  }
}
.section08-content-box {
  padding: 40px 0 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 120px;

  .text20 {
    font-family: 'NanumSquareNeo-ExtraBold';
    color: #1e9b6b;
  }
}
.section08-01-content-box {
  margin-bottom: 80px;
}
.usage-step-title {
  margin-bottom: 24px;
}
.usage-step-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.usage-step-content2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 700px;
  overflow: hidden;
}

.usage-gif {
  border-radius: 16px;
  height: 515px;
}
.usage-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.usage-text {
  display: flex;
  gap: 10px;
  width: 328px;
  text-align: left;
  align-items: flex-start;
}
.section09 {
  width: 100%;
}
.section09-title-box {
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .text27 {
    font-family: 'NanumSquareNeo-ExtraBold';
  }
  .text20 {
    font-family: 'NanumSquareNeo-Bold';
  }
}

.findland-add1 {
  padding: 100px 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.findland-add1-title-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.findland-add1-title-box .text32 {
  color: #1e9b6b;
}
.findland-add1-content-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  text-align: left;
}
.findland-add2 {
  background-color: #dcf1d5;
  width: 100%;
}
.findland-add2-title-box {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .text-color-box {
    width: 100%;
    height: 53px;
    padding: 4px 8px;
    color: #ffffff;
    background-color: #1e9b6b;
    margin-bottom: 12px;
  }
}
.findland-add2-content-box {
  width: 100%;
  padding: 0 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  position: relative;
}
.findland-add2-content-01 {
  display: flex;
  flex-direction: column;
  gap: 60px;
  z-index: 1;
}
.findland-add2-content-02 {
  z-index: 1;
}
.findland-add2-content-block-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}
.findland-add2-content-block-title .text27 {
  font-family: 'NanumSquareNeo-ExtraBold';
  margin-bottom: 4px;
}
.checkbox-block {
  width: 328px;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  box-shadow: 0px 2px 8px 0px rgba(29, 54, 21, 0.1);
}
.checkbox-text {
  display: flex;
  gap: 4px;
}
.checkbox-text .text17 {
  color: #0b0b0b;
}
.grey-line {
  width: 100%;
  height: 1px;
  background-color: #e8e8e9;
}
.green-line {
  width: 100%;
  height: 1px;
  background-color: #b7d4ad;
}
.content-02-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-02-title .text20 {
  font-family: 'NanumSquareNeo-Bold';
  color: #0b0b0b;
  margin-bottom: 16px;
}
.section08-1 .usage-step-title {
  margin-bottom: 0;
}
.section08.section08-1 {
  border-bottom: 16px solid #f0f0f0;
}
.section08.section08-1 .section08-content-box {
  gap: 40px;
}
.findland-gif,
.aiFarming-gif {
  width: 360px;
}

.findland-table,
.findland-table2 {
  margin-top: 18px;
}

.findland-table img {
  height: 482px;
}
.table-hidden-text {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.polygon-bg {
  position: absolute;
  left: calc(50% - -1006px);
  top: 2640px;
  transform: translate(-1877.22px, -819px);
  overflow: visible;
  z-index: 0;
}
.polygon-bg2 {
  position: absolute;
  left: calc(50% - -1006px);
  top: 2088px;
  transform: translate(-1877.22px, -819px);
  overflow: visible;
  z-index: 0;
}
.landing-banner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  gap: 24px;
  max-width: 1200px;
}
.banner-title {
  font-size: 18px;
  font-family: 'NanumSquareNeo-Bold';
  width: 100%;
  text-align: left;
  padding-left: 16px;
}

.banner-card {
  width: 312px;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .section09-content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .section04-title-box {
    padding: 310px 0 250px;
  }
  .section04-card {
    img {
      height: 166px;
    }
  }
  .usage-gif {
    height: 728px;
  }
  .usage-step-content {
    gap: 40px;
  }
  .findland-add2-content-01 {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    width: 1200px;
  }
  .findland-add2-content-01 .green-line {
    display: none;
  }
  .polygon-bg {
    top: 1120px;
  }
  .polygon-bg2 {
    top: 963px;
  }
  .section08-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section08 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section08-01-content-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .landing-banner-wrap {
    padding: 80px 0;
    gap: 24px;
  }
  .landing-banner-box {
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    margin: 0;
    gap: 16px;
  }
  .banner-link {
    width: 100%;
  }
  .banner-title {
    font-size: 32px;
    font-family: 'NanumSquareNeo-ExtraBold';
    width: 100%;
    text-align: center;
    padding-left: 16px;
  }
  .section02-content-box-house {
    flex-direction: row;
    gap: 96px;
  }
  .section06-content-01-imgs {
    width: 1200px;
    gap: 108px;
  }
}
