.margin-y-10 {
  margin: 10px 0;
}

.margin-y-24 {
  margin: 24px 0;
}
.margin-x-54 {
  margin: 0px 54px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-64 {
  margin-top: 64px;
}
.mt-1 {
  margin-top: 1px;
}
.ml-4 {
  margin-left: 4px;
}
.mt-130 {
  margin-top: 130px;
}
.margin-50-0-20 {
  margin: 50px 0 20px;
}
.mt-l-10 {
  margin-left: 10px;
}
.mt-m-6 {
  margin-top: -6px;
}

.mt-42 {
  margin-top: 42px;
}

.margin-x-m-16 {
  margin: 0 -16px;
}

.mt-62 {
  margin-top: 62px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-m-10 {
  margin-top: -10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-230 {
  margin-top: 230px;
}
.mt-300 {
  margin-top: 300px;
}
.mt-250 {
  margin-top: 250px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-200 {
  margin-top: 200px;
}

.mt-52 {
  margin-top: 52px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-76 {
  margin-top: 76px;
}
.mt-70 {
  margin-top: 70px;
}

.mt-92 {
  margin-top: 92px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}
.mt-24 {
  margin-top: 24px;
}

.mt-124 {
  margin-top: 124px;
}

.mt-144 {
  margin-top: 144px;
}

.mt-5 {
  margin-top: 5px;
}
.mt-m-20 {
  margin-top: -20px;
}
.mb-m-5 {
  margin-bottom: -5px;
}
.mb-80 {
  margin-bottom: 80px;
}

.mb-92 {
  margin-bottom: 92px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-48 {
  margin-bottom: 48px;
}

@media (min-width: 1024px) {
  .browser-mt-0 {
    margin-top: 0;
  }

  .browser-margin-y-10 {
    margin: 10px 0;
  }

  .browser-margin-y-24 {
    margin: 24px 0;
  }

  .browser-mt-m-6 {
    margin-top: -6px;
  }

  .browser-mt-42 {
    margin-top: 42px;
  }

  .browser-mt-62 {
    margin-top: 62px;
  }

  .browser-mt-12 {
    margin-top: 12px;
  }

  .browser-mt-m-10 {
    margin-top: -10px;
  }

  .browser-mt-10 {
    margin-top: 10px;
  }

  .browser-mt-16 {
    margin-top: 16px;
  }
  .browser-mt-20 {
    margin-top: 20px;
  }
  .browser-mt-30 {
    margin-top: 30px;
  }
  .browser-mt-32 {
    margin-top: 32px;
  }
  .browser-mt-42 {
    margin-top: 42px;
  }

  .browser-mt-48 {
    margin-top: 48px;
  }

  .browser-mt-52 {
    margin-top: 52px;
  }

  .browser-mt-50 {
    margin-top: 50px;
  }

  .browser-mt-76 {
    margin-top: 76px;
  }
  .browser-mt-80 {
    margin-top: 80px;
  }
  .browser-mt-92 {
    margin-top: 92px;
  }

  .browser-mt-100 {
    margin-top: 100px;
  }

  .browser-mt-110 {
    margin-top: 110px;
  }

  .browser-mt-124 {
    margin-top: 124px;
  }

  .browser-mt-144 {
    margin-top: 144px;
  }

  .browser-mt-5 {
    margin-top: 5px;
  }

  .browser-mb-80 {
    margin-bottom: 80px;
  }

  .browser-mb-92 {
    margin-bottom: 92px;
  }

  .browser-mb-20 {
    margin-bottom: 20px;
  }
}
