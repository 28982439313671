.editText {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  font-family: Pretendard;
}

.label {
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
}

.asterisk {
  color: #09ce7d;
  margin-left: 5px;
}

.editTexts {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #171e268f;
  width: Fixed (328px) px;
  height: Hug (20px) px;
  padding: 0px 16px 0px 16px;
  gap: 2px;
  opacity: 0px;
}

.button {
  border: 1px solid #171e261a;
  padding: 16px;
  gap: 0px;
  border-radius: 8px;
  white-space: nowrap;
  object-fit: contain;
  background-color: #fff;
  position: relative;
}

.arrow1 {
  width: 8px;
  height: 14px;
  display: inline-block;
  align-self: center;
  position: absolute;
  right: 2%;
  object-fit: contain;
}

.calender {
  display: inline-block;
  align-self: center;
  position: absolute;
  right: 1%;
  object-fit: contain;
}

.editBox {
  width: Fill (688px) px;
  padding: 16px 16px 16px 10px;
  border-radius: 8px;
  border: 1px solid #171e261a;
  margin-top: -10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.customzeah-input {
  width: Fill (688px) px;
  padding: 16px 16px 16px 10px;
  border-radius: 8px;
  border: 1px solid #171e261a;
  margin-top: -10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background: var(--Alias-Color-Text-White-Gray-LightGray-100, #f7f8f9);
}
.customzeah-input:hover {
  border-color: #171e261a; /* 기본 테두리 색으로 복원 */
}
.customzeah-input2 {
  width: Fill (688px) px;
  padding: 16px 16px 16px 10px;
  border-radius: 8px;
  border: 1px solid #171e261a;
  margin-top: -10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transition: border-color 0.2s;
}

input.focused {
  border-color: #09ce7d;
}

input:hover {
  border-color: #09ce7d;
}

.editButton {
  width: Hug (84px) px;
  height: Hug (36px) px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  border: 1px solid #171e261a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  transition: 0.2s;
}

.editButton:hover {
  opacity: 0.6;
}

.editSuccessText_1 {
  width: 280px;
  height: 40px;
  gap: 0px;
  opacity: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #171e2666;
}

/* 태블릿 크기에 대한 스타일 */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .content {
    width: 688px;
    margin: 0 auto;
  }
  .tablet-only {
    display: block;
  }
  .editbtn {
    width: 688px;
    height: 54px;
    padding: 14px 16px 14px 16px;
    gap: 2px;
    border-radius: 8px;
  }
}
