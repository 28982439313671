.notice-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notice-list ul {
  margin-bottom: 40px;
  width: 100%;
}
.notice-list ul li {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #e8e9ea;
  cursor: pointer;
}

.notice-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  padding: 16px 16px 80px 16px;
}
.notice-wrap-title {
  display: none;
}
.notice-content-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notice-title-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.notice-tag {
  display: none;
}
.notice-detail-content {
  text-align: left;
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  padding: 32px 16px 0;
  color: #171e26;
}
.notice-date {
  color: #7d8185;
  width: 100px;
  font-family: Pretendard-Medium;
  font-size: 14px;
}
.notice-title {
  line-height: 150%;
  max-width: 296px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #171e26;
}
.notice-detail-title {
  line-height: 150%;
  max-width: 296px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;
}
.notice-detail-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.notice-detail {
  border: none;
  width: 100%;
}
.notice-detail-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #e8e9ea;
}
.to-the-list-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.to-the-list-btn button {
  width: 328px;
  height: 54px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  cursor: pointer;
  font-family: Pretendard-Bold;
  font-size: 16px;
}
.top-line {
  width: 100%;
  height: 1px;
  background-color: #171e26;
  display: none;
}

@media (min-width: 768px) {
  .notice-title {
    line-height: 150%;
    max-width: 640px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    align-items: center;
    font-family: Pretendard-Medium;
    font-size: 16px;
    color: #171e26;
  }
  .notice-detail-title {
    max-width: 640px;
  }
  .notice-content-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .notice-tag {
    display: block;
    width: 45px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Pretendard-SemilBold;
    font-size: 14px;
    color: #171e26;
    border: 1px solid black;
    border-radius: 4px;
  }
  .notice-inner {
    padding: 80px 60px;
    max-width: 1320px;
    gap: 24px;
  }
  .notice-wrap-title {
    width: 100%;
    font-family: Pretendard-bold;
    font-size: 26px;
    display: flex;
  }
  .notice-list {
    max-height: 730px;
  }
  .notice-list ul li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e6e6e6;
  }
  .top-line {
    display: block;
  }
  .notice-detail-box {
    border-top: 1px solid #171e26;
  }
  .notice-detail-item {
    flex-direction: row;
    align-items: center;
  }
}
