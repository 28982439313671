.body-1-image {
  background-image: url(../../../../public/asset/images/promotion/consulting_main.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-to-form-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 0 16px;
}
.pay-script-box {
  width: 328px;
  margin-top: 32px;
}
.pay-script {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.text-dot {
  width: 2px;
  height: 2px;
  border-radius: 50px;
  background-color: rgba(23, 30, 38, 0.4);
  box-sizing: border-box;
  margin-top: 6px;
}
.pay-script p {
  margin: 0;
  flex: 1;
}
.amount-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}

@media (min-width: 1024px) {
  .link-to-form-box {
    width: 720px;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    padding: 0;
    margin-top: 64px;
  }
  .pay-script-box {
    width: 720px;
    margin-top: 64px;
  }
}
