.land-option-container {
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 16px solid rgb(247, 248, 249);
}
.land-option-title {
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: #171e26;
  letter-spacing: -0.18px;
}
.land-option-content-box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background-color: #f7f8fc;
}
.land-option-content-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.land-option-content-item-text-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.land-option-content-item-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #222;
}
.land-option-content-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.ai-recommend-tag {
  width: 48px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eaf8f2;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: #1e9b6b;
}
.land-option-percatage {
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.28);
}
.land-option-content-item-bar {
  width: 100%;
  height: 10px;
  border-radius: 999px;
  background-color: rgba(112, 115, 124, 0.16);
  position: relative;
}
.per-bar {
  position: absolute;
  height: 100%;
  border-radius: 999px;
  background-color: rgba(55, 56, 60, 0.16);
}
.no-data-message {
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: rgba(55, 56, 60, 0.61);
  text-align: center;
}
