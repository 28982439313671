.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px;
}

.gap-9 {
  gap: 9px;
}

.gap-10 {
  gap: 10px;
}

.gap-11 {
  gap: 11px;
}

.gap-12 {
  gap: 12px;
}

.gap-13 {
  gap: 13px;
}

.gap-14 {
  gap: 14px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-17 {
  gap: 17px;
}

.gap-18 {
  gap: 18px;
}

.gap-19 {
  gap: 19px;
}

.gap-20 {
  gap: 20px;
}

.gap-21 {
  gap: 21px;
}

.gap-22 {
  gap: 22px;
}

.gap-23 {
  gap: 23px;
}

.gap-24 {
  gap: 24px;
}

.gap-25 {
  gap: 25px;
}

.gap-26 {
  gap: 26px;
}

.gap-27 {
  gap: 27px;
}

.gap-28 {
  gap: 28px;
}

.gap-29 {
  gap: 29px;
}

.gap-30 {
  gap: 30px;
}

.gap-31 {
  gap: 31px;
}

.gap-32 {
  gap: 32px;
}

.gap-33 {
  gap: 33px;
}

.gap-34 {
  gap: 34px;
}

.gap-35 {
  gap: 35px;
}

.gap-36 {
  gap: 36px;
}

.gap-37 {
  gap: 37px;
}

.gap-38 {
  gap: 38px;
}

.gap-39 {
  gap: 39px;
}

.gap-40 {
  gap: 40px;
}

.gap-41 {
  gap: 41px;
}

.gap-42 {
  gap: 42px;
}

.gap-43 {
  gap: 43px;
}

.gap-44 {
  gap: 44px;
}

.gap-45 {
  gap: 45px;
}

.gap-46 {
  gap: 46px;
}

.gap-47 {
  gap: 47px;
}

.gap-48 {
  gap: 48px;
}

.gap-49 {
  gap: 49px;
}

.gap-50 {
  gap: 50px;
}

.gap-51 {
  gap: 51px;
}

.gap-52 {
  gap: 52px;
}

.gap-53 {
  gap: 53px;
}

.gap-54 {
  gap: 54px;
}

.gap-55 {
  gap: 55px;
}

.gap-56 {
  gap: 56px;
}

.gap-57 {
  gap: 57px;
}

.gap-58 {
  gap: 58px;
}

.gap-59 {
  gap: 59px;
}

.gap-60 {
  gap: 60px;
}

.gap-61 {
  gap: 61px;
}

.gap-62 {
  gap: 62px;
}

.gap-63 {
  gap: 63px;
}

.gap-64 {
  gap: 64px;
}

.gap-65 {
  gap: 65px;
}

.gap-66 {
  gap: 66px;
}

.gap-67 {
  gap: 67px;
}

.gap-68 {
  gap: 68px;
}

.gap-69 {
  gap: 69px;
}

.gap-70 {
  gap: 70px;
}

.gap-71 {
  gap: 71px;
}

.gap-72 {
  gap: 72px;
}

.gap-73 {
  gap: 73px;
}

.gap-74 {
  gap: 74px;
}

.gap-75 {
  gap: 75px;
}

.gap-76 {
  gap: 76px;
}

.gap-77 {
  gap: 77px;
}

.gap-78 {
  gap: 78px;
}

.gap-79 {
  gap: 79px;
}

.gap-80 {
  gap: 80px;
}

.gap-81 {
  gap: 81px;
}

.gap-82 {
  gap: 82px;
}

.gap-83 {
  gap: 83px;
}

.gap-84 {
  gap: 84px;
}

.gap-85 {
  gap: 85px;
}

.gap-86 {
  gap: 86px;
}

.gap-87 {
  gap: 87px;
}

.gap-88 {
  gap: 88px;
}

.gap-89 {
  gap: 89px;
}

.gap-90 {
  gap: 90px;
}

.gap-91 {
  gap: 91px;
}

.gap-92 {
  gap: 92px;
}

.gap-93 {
  gap: 93px;
}

.gap-94 {
  gap: 94px;
}

.gap-95 {
  gap: 95px;
}

.gap-96 {
  gap: 96px;
}

.gap-97 {
  gap: 97px;
}

.gap-98 {
  gap: 98px;
}

.gap-99 {
  gap: 99px;
}

.gap-100 {
  gap: 100px;
}

.gap-101 {
  gap: 101px;
}

.gap-102 {
  gap: 102px;
}

.gap-103 {
  gap: 103px;
}

.gap-104 {
  gap: 104px;
}

.gap-105 {
  gap: 105px;
}

.gap-106 {
  gap: 106px;
}

.gap-107 {
  gap: 107px;
}

.gap-108 {
  gap: 108px;
}

.gap-109 {
  gap: 109px;
}

.gap-110 {
  gap: 110px;
}

.gap-111 {
  gap: 111px;
}

.gap-112 {
  gap: 112px;
}

.gap-113 {
  gap: 113px;
}

.gap-114 {
  gap: 114px;
}

.gap-115 {
  gap: 115px;
}

.gap-116 {
  gap: 116px;
}

.gap-117 {
  gap: 117px;
}

.gap-118 {
  gap: 118px;
}

.gap-119 {
  gap: 119px;
}

.gap-120 {
  gap: 120px;
}

@media (min-width: 1024px) {
  .browser-gap-0 {
    gap: 0px;
  }
  .w-1024-gap-40 {
    gap: 40px;
  }
  .browser-gap-1 {
    gap: 1px;
  }

  .browser-gap-2 {
    gap: 2px;
  }

  .browser-gap-3 {
    gap: 3px;
  }

  .browser-gap-4 {
    gap: 4px;
  }

  .browser-gap-5 {
    gap: 5px;
  }

  .browser-gap-6 {
    gap: 6px;
  }

  .browser-gap-7 {
    gap: 7px;
  }

  .browser-gap-8 {
    gap: 8px;
  }

  .browser-gap-9 {
    gap: 9px;
  }

  .browser-gap-10 {
    gap: 10px;
  }

  .browser-gap-11 {
    gap: 11px;
  }

  .browser-gap-12 {
    gap: 12px;
  }

  .browser-gap-13 {
    gap: 13px;
  }

  .browser-gap-14 {
    gap: 14px;
  }

  .browser-gap-15 {
    gap: 15px;
  }

  .browser-gap-16 {
    gap: 16px;
  }

  .browser-gap-17 {
    gap: 17px;
  }

  .browser-gap-18 {
    gap: 18px;
  }

  .browser-gap-19 {
    gap: 19px;
  }

  .browser-gap-20 {
    gap: 20px;
  }

  .browser-gap-21 {
    gap: 21px;
  }

  .browser-gap-22 {
    gap: 22px;
  }

  .browser-gap-23 {
    gap: 23px;
  }

  .browser-gap-24 {
    gap: 24px;
  }

  .browser-gap-25 {
    gap: 25px;
  }

  .browser-gap-26 {
    gap: 26px;
  }

  .browser-gap-27 {
    gap: 27px;
  }

  .browser-gap-28 {
    gap: 28px;
  }

  .browser-gap-29 {
    gap: 29px;
  }

  .browser-gap-30 {
    gap: 30px;
  }

  .browser-gap-31 {
    gap: 31px;
  }

  .browser-gap-32 {
    gap: 32px;
  }

  .browser-gap-33 {
    gap: 33px;
  }
  .browser-gap-34 {
    gap: 34px;
  }

  .browser-gap-35 {
    gap: 35px;
  }

  .browser-gap-36 {
    gap: 36px;
  }

  .browser-gap-37 {
    gap: 37px;
  }

  .browser-gap-38 {
    gap: 38px;
  }

  .browser-gap-39 {
    gap: 39px;
  }

  .browser-gap-40 {
    gap: 40px;
  }

  .browser-gap-41 {
    gap: 41px;
  }

  .browser-gap-42 {
    gap: 42px;
  }

  .browser-gap-43 {
    gap: 43px;
  }

  .browser-gap-44 {
    gap: 44px;
  }

  .browser-gap-45 {
    gap: 45px;
  }

  .browser-gap-46 {
    gap: 46px;
  }

  .browser-gap-47 {
    gap: 47px;
  }

  .browser-gap-48 {
    gap: 48px;
  }

  .browser-gap-49 {
    gap: 49px;
  }

  .browser-gap-50 {
    gap: 50px;
  }

  .browser-gap-51 {
    gap: 51px;
  }

  .browser-gap-52 {
    gap: 52px;
  }

  .browser-gap-53 {
    gap: 53px;
  }

  .browser-gap-54 {
    gap: 54px;
  }

  .browser-gap-55 {
    gap: 55px;
  }

  .browser-gap-56 {
    gap: 56px;
  }

  .browser-gap-57 {
    gap: 57px;
  }

  .browser-gap-58 {
    gap: 58px;
  }

  .browser-gap-59 {
    gap: 59px;
  }

  .browser-gap-60 {
    gap: 60px;
  }

  .browser-gap-61 {
    gap: 61px;
  }

  .browser-gap-62 {
    gap: 62px;
  }

  .browser-gap-63 {
    gap: 63px;
  }

  .browser-gap-64 {
    gap: 64px;
  }

  .browser-gap-65 {
    gap: 65px;
  }

  .browser-gap-66 {
    gap: 66px;
  }

  .browser-gap-67 {
    gap: 67px;
  }

  .browser-gap-68 {
    gap: 68px;
  }

  .browser-gap-69 {
    gap: 69px;
  }

  .browser-gap-70 {
    gap: 70px;
  }

  .browser-gap-71 {
    gap: 71px;
  }

  .browser-gap-72 {
    gap: 72px;
  }

  .browser-gap-73 {
    gap: 73px;
  }

  .browser-gap-74 {
    gap: 74px;
  }

  .browser-gap-75 {
    gap: 75px;
  }

  .browser-gap-76 {
    gap: 76px;
  }

  .browser-gap-77 {
    gap: 77px;
  }

  .browser-gap-78 {
    gap: 78px;
  }

  .browser-gap-79 {
    gap: 79px;
  }

  .browser-gap-80 {
    gap: 80px;
  }

  .browser-gap-81 {
    gap: 81px;
  }

  .browser-gap-82 {
    gap: 82px;
  }

  .browser-gap-83 {
    gap: 83px;
  }

  .browser-gap-84 {
    gap: 84px;
  }

  .browser-gap-85 {
    gap: 85px;
  }

  .browser-gap-86 {
    gap: 86px;
  }

  .browser-gap-87 {
    gap: 87px;
  }

  .browser-gap-88 {
    gap: 88px;
  }

  .browser-gap-89 {
    gap: 89px;
  }

  .browser-gap-90 {
    gap: 90px;
  }

  .browser-gap-91 {
    gap: 91px;
  }

  .browser-gap-92 {
    gap: 92px;
  }

  .browser-gap-93 {
    gap: 93px;
  }

  .browser-gap-94 {
    gap: 94px;
  }

  .browser-gap-95 {
    gap: 95px;
  }

  .browser-gap-96 {
    gap: 96px;
  }

  .browser-gap-97 {
    gap: 97px;
  }

  .browser-gap-98 {
    gap: 98px;
  }

  .browser-gap-99 {
    gap: 99px;
  }

  .browser-gap-100 {
    gap: 100px;
  }

  .browser-gap-101 {
    gap: 101px;
  }

  .browser-gap-102 {
    gap: 102px;
  }

  .browser-gap-103 {
    gap: 103px;
  }

  .browser-gap-104 {
    gap: 104px;
  }

  .browser-gap-105 {
    gap: 105px;
  }

  .browser-gap-106 {
    gap: 106px;
  }

  .browser-gap-107 {
    gap: 107px;
  }

  .browser-gap-108 {
    gap: 108px;
  }

  .browser-gap-109 {
    gap: 109px;
  }

  .browser-gap-110 {
    gap: 110px;
  }

  .browser-gap-111 {
    gap: 111px;
  }

  .browser-gap-112 {
    gap: 112px;
  }

  .browser-gap-113 {
    gap: 113px;
  }

  .browser-gap-114 {
    gap: 114px;
  }

  .browser-gap-115 {
    gap: 115px;
  }

  .browser-gap-116 {
    gap: 116px;
  }

  .browser-gap-117 {
    gap: 117px;
  }

  .browser-gap-118 {
    gap: 118px;
  }

  .browser-gap-119 {
    gap: 119px;
  }

  .browser-gap-120 {
    gap: 120px;
  }
}

@media (min-width: 1920px) {
  .browser-1920-gap-140 {
    gap: 140px;
  }
  .browser-1920-gap-80 {
    gap: 80px;
  }
  .browser-1920-gap-80 {
    gap: 80px;
  }
}
