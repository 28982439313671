.box {
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: #f7f8f9;
  display: grid;
}
.box2 {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #f7f8f9;
  display: inline-grid;
}
.box2Text {
  font-size: '16px';
  color: '#888B90';
  font-weight: '500px';
  line-height: '24px';
  cursor: pointer;
}

.customdelete-input {
  width: Fill (688px) px;
  padding: 16px 16px 16px 10px;
  border-radius: 8px;
  border: 1px solid #171e261a;
  margin-top: -10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transition: border-color 0.2s;
}

input.focused {
  border-color: #09ce7d;
}

input:hover {
  border-color: #09ce7d;
}
.withdrawalDate {
  width: 199px;
  height: 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.withdrawalId {
  width: 199px;
  height: 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.deleteText_1 {
  width: 280px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #171e2666;
}
.withdrawal-notice {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
  color: #888b90;
}
.withdrawal-notice-dot {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.withdrawal-notice-dot img {
  width: 3px;
}
