@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Pretendard', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Pretendard-Thin';
  font-weight: '100';
  src: url('./asset/fonts/Pretendard-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard-ExtraLight';
  font-weight: '200';
  src: url('./asset/fonts/Pretendard-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-Light';
  font-weight: '300';
  src: url('./asset/fonts/Pretendard-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-Regular';
  font-weight: '400';
  src: url('./asset/fonts/Pretendard-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-Medium';
  font-weight: '500';
  src: url('./asset/fonts/Pretendard-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-SemilBold';
  font-weight: '600';
  src: url('./asset/fonts/Pretendard-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-Bold';
  font-weight: '700';
  src: url('./asset/fonts/Pretendard-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Pretendard-ExtraBold';
  font-weight: '800';
  src: url('./asset/fonts/Pretendard-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard-Black';
  font-weight: '900';
  src: url('./asset/fonts/Pretendard-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansLight';
  font-weight: '300';
  src: url('./asset/fonts/GmarketSansTTFLight.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansMedium';
  font-weight: '500';
  src: url('./asset/fonts/GmarketSansTTFMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansBold';
  font-weight: '700';
  src: url('./asset/fonts/GmarketSansTTFBold.ttf') format('truetype');
}
