.terms-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.terms-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 32px;
  padding: 32px 16px;
}
.terms-title {
  width: 100%;
  font-family: Pretendard-bold;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.terms-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 32px;
  width: 100%;
}
.terms-privacy-script {
  width: 100%;
  font-family: Pretendard-medium;
  font-size: 18px;
  line-height: 26px;
}
.terms-anchor-list a {
  width: 100%;
  font-family: Pretendard-bold;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.terms-content-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.terms-content-item-title {
  font-family: Pretendard-bold;
  font-size: 16px;
}
.terms-content-item-text {
  font-family: Pretendard-medium;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}
.supplementary-provisions {
  font-family: Pretendard-bold;
  font-size: 16px;
}
/* .terms-content-item-text2 {
  padding-left: 32px;
} */
@media (min-width: 768px) {
  .terms-inner {
    padding: 32px 40px;
  }
  .terms-title {
    font-size: 26px;
  }
  .terms-content-item-title {
    font-size: 18px;
  }
  .terms-content-item-text {
    font-family: Pretendard-medium;
    font-size: 16px;
    line-height: 26px;
  }
}
@media (min-width: 1024px) {
  .terms-inner {
    padding: 80px 60px;
    max-width: 1320px;
  }
}
