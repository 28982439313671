.ios-style-picker {
  position: relative;
  height: 100%;
  text-align: center;
  overflow: hidden;
  font-family: 'Apple SD Gothic Neo';
}
.ios-style-picker ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ios-style-picker:before,
.ios-style-picker:after {
  position: absolute;
  z-index: 1;
  display: block;
  content: '';
  width: 100%;
  height: 50%;
}
.ios-style-picker:before {
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.ios-style-picker:after {
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.ios-style-picker__option-list {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  transform-style: preserve-3d;
  margin: 0 auto;
  display: block;
  transform: translateZ(-150px) rotateX(0deg);
  -webkit-font-smoothing: subpixel-antialiased;
  color: #666;
}
.ios-style-picker__option-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.ios-style-picker__highlight {
  position: absolute;
  top: 50%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  width: 98%;
  align-items: center;
  transform: translate(0, -50%);
  background-color: white;
  overflow: hidden;
}
.ios-style-picker__highlight-list {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  gap: 10px;
}

/* DatePicker */

.ios-style-date-picker {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  width: 100%;
  height: 210px;
}
.ios-style-date-picker > div {
  flex: 1;
}
.ios-style-date-picker > :nth-child(2) {
  margin-left: 16px;
}
.ios-style-date-picker .ios-style-picker {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  color: #7a8190;
}
.ios-style-date-picker .ios-style-picker__highlight {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;

  color: #1e9b6b;
}
