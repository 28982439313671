.w-22 {
  width: 22px;
}
.w-20 {
  width: 20px;
}
.w-20per {
  width: 20%;
}
.w-24 {
  width: 24px;
}
.w-30 {
  width: 30px;
}
.w-300 {
  width: 300px;
}
.w-32 {
  width: 32px;
}
.w-94 {
  width: 94px;
}
.w-54 {
  width: 54px;
}

.w-64 {
  width: 64px;
}
.w-56 {
  width: 56px;
}
.w-70 {
  width: 70px;
}
.w-750 {
  width: 750px;
}
.w-72 {
  width: 72px;
}
.w-244 {
  width: 244px;
}
.w-fit-content {
  width: fit-content;
}

.w-335 {
  width: 335px;
}
.w-66 {
  width: 66px;
}
.w-90 {
  width: 90px;
}
.w-74 {
  width: 74px;
}
.w-80per {
  width: 80%;
}
.w-8 {
  width: 8px;
}
.w-360 {
  width: 360px;
}
.w-326 {
  width: 326px;
}
.w-40per {
  width: 40%;
}
.w-568 {
  width: 568px;
}
.w-70per {
  width: 70%;
}
.w-130 {
  width: 130px;
}
.w-30 {
  width: 30px;
}
.w-15 {
  width: 15px;
}
.w-100 {
  width: 100px;
}
.w-10 {
  width: 10px;
}
.w-218 {
  width: 218px;
}
.w-500 {
  width: 500px;
}
.min-w-120 {
  min-width: 120px;
}
.w-120 {
  width: 120px;
}
.w-400 {
  width: 400px;
}
.w-125 {
  width: 125px;
}
.w-12 {
  width: 12px;
}
.w-180 {
  width: 180px;
}
.w-18 {
  width: 18px;
}
.w-278 {
  width: 278px;
}
.w-268 {
  width: 268px;
}
.w-359 {
  width: 359px;
}
.w-448 {
  width: 448px;
}
.w-45per {
  width: 45%;
}
.w-650 {
  width: 650px;
}
.w-920 {
  width: 920px;
}
.w-42 {
  width: 42px;
}
.w-40 {
  width: 40px;
}
.w-160 {
  width: 160px;
}

.w-32per {
  width: 32%;
}
.w-33per {
  width: 33%;
}
.w-25per {
  width: 25%;
}
.w-8h0per {
  width: 80%;
}
.w-95per {
  width: 95%;
}
.w-100per {
  width: 100%;
}
.w-93 {
  width: 93px;
}
.w-375 {
  width: 375px;
}

.w-50per {
  width: 50%;
}
.w-60per {
  width: 60%;
}
.w-75per {
  width: 75%;
}
.w-76 {
  width: 76px;
}
.w-14 {
  width: 14px;
}
.w-24per {
  width: 24per;
}
.w-315 {
  width: 315px;
}
.max-w-360 {
  max-width: 360px;
}
.max-w-314 {
  max-width: 314px;
}
.max-w-904 {
  max-width: 904px;
}
.max-w-400 {
  max-width: 400px;
}
.w-122 {
  width: 122px;
}
.max-w-640 {
  max-width: 640px;
}
.max-w-328 {
  max-width: 328px;
}
.w-312 {
  width: 312px;
}
.w-10per {
  width: 10%;
}
.w-296 {
  width: 296px;
}
.w-231 {
  width: 231px;
}
.w-468 {
  width: 468px;
}

.w-80per {
  width: 80%;
}
.w-328 {
  width: 328px;
}
.w-38 {
  width: 38px;
}
.max-w-335 {
  max-width: 335px;
}
.min-w-130 {
  min-width: 130px;
}
.max-w-100per {
  max-width: 100%;
}

@media (min-width: 768px) {
  .tablet-w-688 {
    width: 688px;
  }
  .tablet-pb-40-flex-end {
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .tablet-img-flex-end {
    align-self: flex-end;
  }
  .tablet-text-center {
    margin-left: 0;
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .tablet-pb-40-flex-end {
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .tablet-img-flex-end {
    align-self: flex-end;
  }
  .tablet-text-center {
    margin-left: 0;
    text-align: center;
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .browser-w-480 {
    width: 480px;
  }
  .browser-w-808 {
    width: 808px;
  }
  .w-1024-w-375 {
    width: 375px;
  }
  .browser-w-720 {
    width: 720px;
  }
  .w-1024-min-w-296 {
    min-width: 296px;
  }
  .w-1024-w-904 {
    width: 904px;
  }
  .w-1024-w-553 {
    width: 553px;
  }
  .w-1024-max-w-904 {
    max-width: 904px;
  }
  .w-1024-w-568 {
    width: 568px;
  }
  .w-1024-w-296 {
    width: 296px;
  }
  .w-1024-max-w-688 {
    max-width: 688px;
  }
  .w-1024-w-360 {
    width: 360px;
  }
  .w-1024-w-688 {
    width: 688px;
  }
  .w-1024-w-100per {
    width: 100%;
  }
  .w-1024-max-w-310 {
    max-width: 310px;
  }
  .w-1024-max-w-768 {
    max-width: 768px;
  }
  .w-1024-max-w-468 {
    max-width: 468px;
  }
  .tablet-w-904 {
    max-width: 904px;
  }
  .tablet-w-712 {
    width: 712px;
  }
  .browser-w-0 {
    width: 0;
  }
  .browser-w-81 {
    width: 81px;
  }
  .browser-w-256 {
    width: 256px;
  }
  .browser-w-508 {
    width: 508px;
  }
  .browser-w-290 {
    width: 290px;
  }

  .browser-w-396 {
    width: 396px;
  }
  .browser-w-374 {
    width: 374px;
  }
  .browser-w-375 {
    width: 375px;
  }
  .browser-w-360 {
    width: 360px;
  }
  .browser-w-100per {
    width: 100%;
  }
  .browser-w-494 {
    width: 494px;
  }
  .browser-w-328 {
    width: 328px;
  }
  .browser-w-454 {
    width: 454px;
  }
  .browser-w-434 {
    width: 434px;
  }
  .browser-w-449 {
    width: 449px;
  }

  .browser-w-854 {
    width: 854px;
  }
  .browser-w-340 {
    width: 340px;
  }
  .browser-w-400 {
    width: 400px;
  }
  .browser-w-350 {
    width: 350px;
  }
  .browser-w-468 {
    width: 468px;
  }
  .browser-w-794 {
    width: 794px;
  }
  .browser-w-809 {
    width: 809px;
  }
  .browser-w-824 {
    width: 824px;
  }
  .browser-w-920 {
    width: 920px;
  }
  .browser-w-500 {
    width: 550px;
  }
  .browser-w-448 {
    width: 448px;
  }
  .browser-max-none {
    max-width: none;
  }
  .browser-max-w-1920 {
    max-width: 1920px;
  }
  .browser-max-w-1440 {
    max-width: 1440px;
  }
  .browser-max .browser-max-w-1200 {
    max-width: 1200px;
  }
  .browser-max-w-1232 {
    max-width: 1232px;
  }
  .browser-max-w-1320 {
    max-width: 1320px;
  }
  .browser-w-1200 {
    width: 1200px;
  }

  .browser-max-w-500 {
    max-width: 500px;
  }

  .browser-min-w-468 {
    max-width: 468px;
  }

  .browser-w-01-em {
    width: 0.05em;
  }

  .browser-w-1 {
    width: 1px;
  }

  .browser-w-22 {
    width: 22px;
  }

  .browser-w-24 {
    width: 24px;
  }

  .browser-w-40 {
    width: 40px;
  }
  .browser-w-352 {
    width: 352px;
  }

  .browser-w-54 {
    width: 54px;
  }

  .browser-w-fit-content {
    width: fit-content;
  }

  .browser-w-335 {
    width: 335px;
  }

  .browser-w-90 {
    width: 90px;
  }

  .browser-w-100 {
    width: 100px;
  }

  .browser-w-125 {
    width: 125px;
  }

  .browser-w-359 {
    width: 359px;
  }

  .browser-w-650 {
    width: 650px;
  }

  .browser-w-95per {
    width: 95%;
  }

  .browser-w-100per {
    width: 100%;
  }

  .browser-w-50per {
    width: 50%;
  }
  .tablet-w-904-2 {
    width: 904px;
  }

  .browser-max-w-314 {
    max-width: 314px;
  }
  .pb-0 {
    padding-bottom: 0px;
  }
  .text-left {
    text-align: left;
    display: flex;
  }
}
@media (min-width: 1920px) {
  .browser-w-380 {
    width: 380px;
  }
  .w-1920-w-824 {
    width: 824px;
  }
  .browser-1920-w-1200 {
    width: 1200px;
  }
  .browser-w-1008 {
    width: 1008px;
  }
  .pb-0 {
    padding-bottom: 0px;
  }
}
