.frame-2608360,
.frame-2608360 * {
  box-sizing: border-box;
}
.frame-2608360 {
  background: #ffffff;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.title {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-body-l-16-bold-font-size, 16px);
  line-height: var(--mo-body-l-16-bold-line-height, 150%);
  font-weight: var(--mo-body-l-16-bold-font-weight, 700);
  position: relative;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
}

.text2 {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
}

.frame-2608308 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 164px;
  height: 72px;
  position: relative;
}

.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 360px;
  position: relative;
}

.frame-2608360 {
  background: #ffffff;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 360px;
  position: relative;
}

.title9 {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-body-l-16-bold-font-size, 16px);
  line-height: var(--mo-body-l-16-bold-line-height, 150%);
  font-weight: var(--mo-body-l-16-bold-font-weight, 700);
  position: relative;
}
.frame-26083552 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px 16px 16px 16px;
  display: block;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.mask-group {
  align-self: stretch;
  flex-shrink: 0;
  height: 268px;
  position: static;
}
.frame-26083542 {
  background: var(--alias-color-background-screen, #f0f0f0);
  border-radius: 8px;
  width: 328px;
  height: 268px;
  position: absolute;
  left: 16px;
  top: 16px;
}

.frame-2609057 {
  /* background: #ffffff; */
  opacity: 0.9900000095367432;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.frame-2609055 {
  background: #ffffff;
  padding: 8px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 360px;
  position: relative;
}
.frame-2609090 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2608369 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.bar2 {
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 328px;
  height: auto;
  position: relative;
  overflow: visible;
}
table {
  table-layout: fixed;
  width: 100%;
}

/* table td:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.frame-2609227 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.frame-2609226 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609224 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609205 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  height: 268px;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-caption-l-12-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-medium-font-size, 12px);
  line-height: var(--mo-caption-l-12-medium-line-height, 14px);
  font-weight: var(--mo-caption-l-12-medium-font-weight, 500);
  position: relative;
}
.text2 {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-caption-l-12-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-bold-font-size, 12px);
  line-height: var(--mo-caption-l-12-bold-line-height, 14px);
  font-weight: var(--mo-caption-l-12-bold-font-weight, 700);
  position: relative;
}
.frame-2609204 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609203 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 283px;
  position: relative;
}
.rectangle-2948 {
  background: var(--alias-color-text-white-bluegray-100, #eff2f5);
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  flex: 1;
  height: 134px;
  position: relative;
}
.rectangle-2949 {
  background: var(--alias-color-text-white-bluegray-100, #eff2f5);
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  flex: 1;
  height: 134px;
  position: relative;
}
.frame-2609237 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 283px;
  position: relative;
}
.z {
  flex-shrink: 0;
  width: 144px;
  height: 201px;
  position: absolute;
  left: 69px;
  top: 34px;
  overflow: visible;
}
.frame-2609201 {
  background: #ffffff;
  border-radius: 999px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: absolute;
  left: 69px;
  top: 120px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
._9-45 {
  color: #000000;
  text-align: center;
  font-family: var(
    --mo-caption-s-11-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-caption-s-11-bold-font-size, 11px);
  line-height: var(--mo-caption-s-11-bold-line-height, 12px);
  font-weight: var(--mo-caption-s-11-bold-font-weight, 700);
  position: relative;
}
.frame-2609225 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 281px;
  position: relative;
}
.frame-2609206 {
  display: flex;
  align-items: baseline;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 16%;
}
.frame-2609227 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609254 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609241 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

/* Detalil7 */
._3-km {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: center;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.frame-2609304,
.frame-2609304 * {
  box-sizing: border-box;
}
.frame-2609304 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 213px;
  position: relative;
}
.graph {
  flex: 1;
  width: 328px;
  position: relative;
  overflow: hidden;
  background-color: #eff2f5;
}
.tota,
.tota * {
  box-sizing: border-box;
}
.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
}
.tota .frame-2609336 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.tota .title {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-body-l-16-bold-font-size, 16px);
  line-height: var(--mo-body-l-16-bold-line-height, 150%);
  font-weight: var(--mo-body-l-16-bold-font-weight, 700);
  position: relative;
}
.tota ._3-km {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: center;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.frame-2609352 {
  background: #ffffff;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  position: relative;
}

/* Detalil8 */
.frame-2609340,
.frame-2609340 * {
  box-sizing: border-box;
}
.frame-2609340 {
  background: #fff;
  padding: 0px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: left;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609304 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 360px;
  position: relative;
}
.graph {
  flex-shrink: 0;
  width: 328px;
  height: 163px;
  position: relative;
  overflow: hidden;
}
.frame-2609335 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-2609331 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-2609332 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-2609333 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-2609334 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.group-43208 {
  height: auto;
  position: absolute;
  left: 213px;
  top: 0px;
  overflow: visible;
}
.frame-2609329 {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 115px;
  top: 32px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
._2023-12 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-caption-l-12-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-medium-font-size, 12px);
  line-height: var(--mo-caption-l-12-medium-line-height, 14px);
  font-weight: var(--mo-caption-l-12-medium-font-weight, 500);
  position: relative;
}
._0 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-caption-l-12-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-medium-font-size, 12px);
  line-height: var(--mo-caption-l-12-medium-line-height, 14px);
  font-weight: var(--mo-caption-l-12-medium-font-weight, 500);
  position: relative;
}
.frame-2609338 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 328px;
  position: relative;
}
.monthly {
  border-radius: 16px 16px 0px 0px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 96px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 328px;
  position: relative;
}
.frame-2608953 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
}
.arrow {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame-26089532 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2608953 img {
  cursor: pointer;
}
._2023-122 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.arrow2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame-2608355 {
  border-radius: 0px 0px 16px 16px;
  padding: 40px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 328px;
  position: relative;
  align-self: stretch;
}
.frame-26083555 {
  width: 367px;
  border-radius: 0px 0px 16px 16px;
  padding: 40px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  align-self: stretch;
}
.frame-2609350 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2608789 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  position: relative;
}
.ellipse-463 {
  background: var(--alias-color-text-white-black-10, rgba(23, 30, 38, 0.1));
  border-radius: 50%;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  position: relative;
}
.group-43171 {
  flex-shrink: 0;
  width: 4px;
  height: 23px;
  position: static;
}
.rectangle-3031 {
  background: var(--alias-color-text-white-gray-white, #ffffff);
  border-radius: 3px;
  width: 4px;
  height: 15px;
  position: absolute;
  left: 15px;
  top: 12px;
}
.ellipse-475 {
  background: var(--alias-color-text-white-gray-white, #ffffff);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 15px;
  top: 31px;
}
.frame-2608551 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.div {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.custom-box-table table {
  white-space: pre-line;
}

.frame-2608355,
.frame-2608355 * {
  box-sizing: border-box;
}
.frame-2608355 {
  border-radius: 0px 0px 16px 16px;
  padding: 4px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609341 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text2 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text3 {
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text4 {
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.frame-2609339 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 132px;
  position: relative;
}
.donut-graph {
  align-self: stretch;
  flex-shrink: 0;
  width: 120px;
  position: relative;
  overflow: hidden;
}
.donut-graph-1 {
  width: 120px;
  height: 120px;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  overflow: hidden;
}
.layer-1 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.legend {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.text5 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.title2 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
  flex: 1;
}
.title3 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.ellipse-451 {
  background: var(--alias-color-data-increase, #ec4f4f);
  border-radius: 50%;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  position: relative;
}
.title4 {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
  flex: 1;
}
.ellipse-4512 {
  background: var(--alias-color-data-decrease, #4b47fc);
  border-radius: 50%;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  position: relative;
}
.ellipse-4513 {
  background: var(--alias-color-text-dark-text-disabled, rgba(23, 30, 38, 0.4));
  border-radius: 50%;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  position: relative;
}
.custom-tooltip {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e8e9ea;
  background-color: #fff;
}
.lastPyeongRegion-title {
  width: 240px;
}
.tota1 {
  align-items: flex-start;
}
.no-data-message {
  width: 100%;
  padding: 40px 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.no-data-message p {
  font-size: 14px;
  color: #4a4f56;
}

/*custom tooltip */
.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
}

.tooltip-box {
  width: Hug (131px);
  height: Hug (82px);
  top: 32px;
  left: 134px;
  padding: 16px;
  border-radius: 8px;
  border: 1px;
  gap: 4px;
}
.pyeongregion-title-box {
  display: flex;
  justify-content: space-between;
}
.calander-switch {
  width: 100per;
  display: flex;
  justify-content: right;
}
