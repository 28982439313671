.map-section-wrap {
  width: 100%;
  /* div {
        width: 100%;
    } */
}
.section-tab {
  width: 100%;
  display: flex;
  padding: 8px 16px;
}
.tab-box {
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.tab-box button {
  height: 32px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #999c9f;
  flex: 1;
  cursor: pointer;
}
.separator {
  display: inline-block;
  width: 0.6px;
  height: 16px;
  background-color: #999c9f;
  margin-left: 1px;
}

.tab-box button.selected {
  color: #1e9b6b;
  background-color: #ffffff;
  border: 1px solid #1e9b6b;
  box-shadow: 2px 2px 4px 0px rgba(30, 155, 107, 0.1);
}
.tab-content-box {
  width: 100%;
  padding: 24px 16px;
}
.tab-content {
  height: 90px;
  background-color: #f7f8f9;
  border-radius: 8px;
}
.section-graph {
  width: 100%;
  height: 256px;
  background-color: #f0f0f0;
  margin-bottom: 8px;
}
.sub-tab-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
}
.sub-tab {
  width: 74px;
  height: 36px;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #7d8185;
  cursor: pointer;
}
.section-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sub-tab.selected {
  background-color: #000;
  color: #fff;
}
.list-category-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px 16px;
}
.list-category-tab div {
  width: 100%;
}
.list-tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e8e8e9;
  height: 32px;
}
.list-tab-detail {
  width: 100%;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}
.category-text {
  color: #171e26;
  font-size: 16px;
  line-height: 150%;
  font-family: Pretendard-Medium;
}
.distance-text {
  color: #7d8185;
  font-size: 14px;
  line-height: 20px;
  font-family: Pretendard-Medium;
}
.address-text {
  color: #4a5056;
  font-size: 14px;
  line-height: 20px;
  font-family: Pretendard-Medium;
}
.distance-circle-graph {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 256px;
}
.distance-circle {
  border-radius: 100%;
  background-color: #f7f8f9;
  /* background-color: rgba(247, 248, 249, 0.5); */
  border: 1px solid #e1e2e4;
  position: relative;
  position: absolute;
  cursor: pointer;
}
.small-circle {
  width: 96px;
  height: 96px;
  z-index: 3;
}
.medium-circle {
  width: 160px;
  height: 160px;
  z-index: 2;
}
.large-circle {
  width: 224px;
  height: 224px;
  z-index: 1;
}
.circle-tag {
  width: 49px;
  height: 22px;
  background-color: #c2c1c1;
  color: #fff;
  font-family: Pretendard-Medium;
  font-size: 12px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}
.distance-circle-tooltip {
  font-family: Pretendard-Medium;
  font-size: 14px;
  background-color: #171e26;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 159px;
  height: 32px;
  border-radius: 8px;
  z-index: 4;
  position: relative;
}
.distance-circle-tooltip::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #171e26 transparent;
  transform: translateX(-50%) rotate(180deg);
}

.distance-circle.selected-circle {
  background-color: #cce5dd;
  border: 1px solid #1e9b6b;
  filter: drop-shadow(0px 4px 4px rgba(30, 155, 107, 0.15));
}

.distance-circle.selected-circle .circle-tag {
  background-color: #1e9b6b;
}
